import { Box } from '@mui/material'
import React from 'react'

interface MainContentProps {
  children?: React.ReactNode
}

const defaultProps = {
  children: undefined,
}

const MainContent = ({ children }: MainContentProps) => (
  <Box component="main" id="main-content" tabIndex={-1}>
    {children}
  </Box>
)

MainContent.defaultProps = defaultProps

export default MainContent
