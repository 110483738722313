/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import { useFormikContext } from 'formik'
import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
  Box,
} from '@mui/material'
import { FormValues } from '../../models'

const FormCheckboxLabel = (
  props: FormControlLabelProps & {
    name: string
    maxLength?: number
    readonly?: boolean
    checkboxLabel: string
  },
) => {
  const { name, label, maxLength, readonly, checkboxLabel, ...rest } = props
  const { values, handleChange } = useFormikContext<FormValues>()

  return (
    <Box>
      <FormControlLabel
        {...rest}
        label={checkboxLabel}
        disabled={readonly}
        data-testid={`${name}-test-id`}
        id={name}
        name={name}
        value={values[name] || ''}
        onChange={handleChange}
        control={<Checkbox />}
      />
    </Box>
  )
}

export default FormCheckboxLabel
