/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { useParams, useNavigate } from 'react-router-dom'
import { Box, Button, styled } from '@mui/material'
import JsZip from 'jszip'
import {
  DocumentModel,
  BulkDocumentActivityType,
} from '@rsmus/ecp-documentrequestservice'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import MainContent from '../../components/layouts/MainContent'
import MainMenu from '../../components/layouts/MainMenu'
import SearchLayout from '../../components/layouts/SearchLayout'
import ConfirmModal from '../../components/ConfirmModal'
import {
  getDocumentData,
  getDistinctGroupData,
  GetDocumentDataThunk,
  setDocumentData,
  getFilterDataState,
  setFilteredStateData,
  getDocumentStatus,
} from '../../store/document/documentSlice'
import streamSaver from '../../utils/helpers/streamSaver'
import EmptyFolderIcon from '../../components/icons/EmptyFolderIcon'
import NoSearchResultIcon from '../../components/icons/NoSearchResultIcon'
import filterGridValues from '../../utils/helpers/filterGridValues'
import EngagementMenu from '../../components/Engagement/EngagementMenu'
import EngagementMenuMobile from '../../components/Engagement/EngagementMenu/EngagementMenuMobile'
import { useDeviceType } from '../../rsmCoreComponents/hooks/useDeviceType'
import FilterChips from '../../components/FilterChips'
import { FilterChipData } from '../../components/FilterChips/FilterChips'
import Spinner from '../../components/forms/Spinner/Spinner'
import DocUploadTable from '../../components/Document/Table/DocTable'
import Filters from '../../rsmCoreComponents/components/Filters/Filters'
import { FilterGroupExpandedStateDictionary } from '../../rsmCoreComponents/components/FiltersGroup/FiltersGroup'
import SearchBox from '../../rsmCoreComponents/components/Search/SearchBox'
import { customSearch } from '../../components/Document/Table/DocTable.service'
import { fetchAllEngagements } from '../../store/engagements/engagementSlice'
import { getUserInfo } from '../../store/userInfo/userInfoSlice'
import { useAITracking } from '../../rsmCoreComponents/hooks/useAITracking'
import { Styles } from '../../types'
import api from '../../api'

const styles: Styles = {
  searchContainer: (theme) => ({
    [theme.breakpoints.up('tablet')]: {
      width: '20rem',
    },
  }),
}

const Docs = () => {
  let documentsRedux = useSelector(getDocumentData)
  const filterDataState = useSelector(getFilterDataState)
  const distinctGroupData = useSelector(getDistinctGroupData)
  const loadingStatus = useSelector(getDocumentStatus)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isMobile } = useDeviceType()
  const [filteredDocuments, setFilteredDocuments] = useState<DocumentModel[]>(
    [],
  )
  const [searchString, setSearchString] = useState('')
  const [filterData, setFilterData] = useState<DocumentModel[]>([])
  const [selected, setSelected] = useState<
    { documentId: number; documentRequestId: number }[]
  >([])
  const [allSelected, setAllSelected] = useState<boolean>(false)
  const [confirmDeleteCount, setConfirmDeleteCount] = useState<number>(0)
  const [isAlertDeleteOpen, setisAlertDeleteOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [handleConfirmDelete, setHandleConfirmDelete] = useState<() => void>(
    () => undefined,
  )
  const userInfo = useSelector(getUserInfo)

  const { engagementId } = useParams()
  const [filterGroupExpanded, setFilterGroupExpanded] =
    useState<FilterGroupExpandedStateDictionary>({})

  useAITracking(t('Docs.ECPDocsTitle'), window.location.href)
  const onFetchFiles = async (engId?: string) => {
    document.title = t('Docs.ECPDocsTitle')
    dispatch(GetDocumentDataThunk({ engagementId: engId || engagementId }))
    dispatch(setFilteredStateData(new Map()))
  }

  const LoadEngagements = () => {
    dispatch(fetchAllEngagements({ isEmployee: userInfo.isEmployee }))
  }

  useEffect(() => {
    onFetchFiles()
    LoadEngagements()
  }, [])

  useEffect(() => {
    if (loadingStatus === 'loading') {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [loadingStatus])

  const updateFilteredDocuments = (
    docsData: DocumentModel[],
    searchStr: string,
  ) => {
    const result = customSearch(
      docsData,
      searchStr,
      isMobile
        ? ['fileName']
        : [
            'fileName',
            'createdBy',
            'createdDate',
            'groupLevel1',
            'groupLevel2',
            'status',
            'documentRequestName',
          ],
    )
    setFilteredDocuments(result)
  }

  useEffect(() => {
    if (documentsRedux !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const useMemoFilter = (useMemo: any) => useMemo()
      const result = filterGridValues(
        documentsRedux,
        filterDataState,
        useMemoFilter,
      )
      setFilterData(result)
      updateFilteredDocuments(result, searchString)
    }
  }, [documentsRedux, filterDataState])

  const getDocumentRequestIds = (
    docs: { documentId: number; documentRequestId: number }[],
  ) => {
    const onlyUnique = (value: number, index: number, self: number[]) =>
      self.indexOf(value) === index
    return docs.map((x) => x.documentRequestId).filter(onlyUnique)
  }

  const handleSelectAllChange = (selectAll: boolean) => {
    if (filteredDocuments !== undefined && selectAll) {
      setSelected(
        filteredDocuments.map((d) => ({
          documentId: d.documentId,
          documentRequestId: d.documentRequestId,
        })),
      )
    } else {
      setSelected([])
    }
    setAllSelected(selectAll)
  }

  const handleSelectedChange = (
    newSelected: { documentId: number; documentRequestId: number }[],
  ) => {
    setSelected(newSelected)
  }

  const handleCancel = () => {
    setisAlertDeleteOpen(false)
    setConfirmDeleteCount(0)
  }

  const handleDeleteSingleFile = async (
    document: DocumentModel | undefined,
  ) => {
    if (document) {
      setHandleConfirmDelete(() => async () => {
        // Remove the blob and update the DB.
        await api.documentRequest.document_Delete(
          engagementId || '',
          document.documentRequestId,
          [document.documentId],
        )
        if (documentsRedux !== undefined) {
          const idx = documentsRedux?.findIndex(
            (x) => x.documentId === document.documentId,
          )
          if (idx > -1) {
            documentsRedux = documentsRedux?.filter(
              (d) => d.documentId !== documentsRedux?.[idx].documentId,
            )
            dispatch(setDocumentData([...documentsRedux]))
          }
        }
        // If its checkbox was checked, removed from selected.
        const selectedIdx = selected?.findIndex(
          (x) => x.documentId === document.documentId,
        )
        if (selectedIdx > -1) {
          selected?.splice(selectedIdx, 1)
          setSelected([...selected])
        }

        setisAlertDeleteOpen(true)
      })

      setConfirmDeleteCount(1)
    }
  }

  const handleDeleteSelectedFiles = async () => {
    setHandleConfirmDelete(() => async () => {
      const requestIds = getDocumentRequestIds(selected)

      // Remove the blob and update the DB.
      await Promise.all(
        requestIds.map((requestId) =>
          api.documentRequest.document_Delete(
            engagementId || '',
            requestId,
            selected
              .filter((x) => x.documentRequestId === requestId)
              .map((x) => x.documentId),
          ),
        ),
      )

      // Remove the rows from the UI.
      if (documentsRedux !== undefined) {
        selected.forEach((doc) => {
          const idx = documentsRedux?.findIndex(
            (x) => x.documentId === doc.documentId,
          )
          if (idx !== undefined && idx > -1) {
            documentsRedux = documentsRedux?.filter(
              (d) => d.documentId !== documentsRedux?.[idx].documentId,
            )
          }
        })
        dispatch(setDocumentData([...documentsRedux]))
      }

      setAllSelected(false)
      setSelected([])
      setisAlertDeleteOpen(true)
    })

    setConfirmDeleteCount(selected.length)
  }

  const handleDownloadSingleFile = async (
    document: DocumentModel | undefined,
  ) => {
    if (document) {
      const fileData = (
        await api.documentRequest.document_GetDownloadFileUri(
          document.documentId,
          null,
        )
      )?.data
      const fileStream = streamSaver.createWriteStream(document.fileName || '')
      const response = await fetch(fileData?.downloadUri || '')
      response.body?.pipeTo(fileStream)
    }
  }

  const handleDownloadSelectedFiles = async () => {
    const documentIds = selected
    const zip = JsZip()
    const zipFileName = `download.zip`

    const requestIds = getDocumentRequestIds(documentIds)
    const bulkOperations: {
      bulkOperationId: string
      requestDocIds: number[]
    }[] = []

    const downloadPromises: Promise<{
      fileName: string
      response: Response
    }>[] = []

    requestIds.map(async (requestId) => {
      const requestDocIds = documentIds
        .filter((x) => x.documentRequestId === requestId)
        .map((x) => x.documentId)

      const bulkOperationId = (
        await api.documentRequest.activity_CreateBulkDocumentActivity(
          engagementId || '',
          requestId,
          BulkDocumentActivityType.Download,
        )
      )?.data
      bulkOperations.push({ bulkOperationId, requestDocIds })

      // Get the download urls and download them.
      downloadPromises.push(
        ...documentIds
          .filter((x) => x.documentRequestId === requestId)
          .map((doc) =>
            api.documentRequest
              .document_GetDownloadFileUri(doc.documentId, bulkOperationId)
              .then(async (response) => ({
                fileName: response?.data?.fileName || '',
                response: await fetch(response?.data?.downloadUri || ''),
              })),
          ),
      )
    })

    const files = await Promise.all(downloadPromises)

    // Only zip files if more than 1 file is being downloaded.
    if (files.length > 1) {
      const fileStream = streamSaver.createWriteStream(zipFileName)

      // Add the download files to the zip file.
      files.forEach((file: { fileName: string; response: Response }) => {
        zip.file(file.fileName, file.response.blob())
      })

      // Prompt the user to save the zip.
      zip
        .generateAsync({ type: 'blob' })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((zipFile: any) => zipFile.stream().pipeTo(fileStream))
    } else {
      const fileStream = streamSaver.createWriteStream(files[0].fileName)
      files[0].response.body?.pipeTo(fileStream)
    }

    // Clear the selected checkboxes.
    setAllSelected(false)
    setSelected([])

    await Promise.all(
      bulkOperations?.map((bulkOperation) =>
        api.documentRequest.activity_CompleteBulkDocumentActivity(
          bulkOperation.bulkOperationId,
        ),
      ),
    )
  }

  const handleFilterDeleted = (chipData: FilterChipData<DocumentModel>) => {
    const map = new Map<
      keyof DocumentModel,
      Set<DocumentModel[keyof DocumentModel]>
    >()
    const existingMapEntries = Array.from(filterDataState.entries())
    existingMapEntries.forEach((existingMapEntry) => {
      const [existingProp, existingSet] = existingMapEntry
      const existingValues = Array.from(existingSet.values())

      if (existingProp !== chipData.property) {
        map.set(existingProp, new Set([...existingValues]))
      } else {
        const existingExceptDeleted = existingValues.filter(
          (x) => `${x}` !== chipData.value,
        )
        if (existingExceptDeleted.length > 0) {
          map.set(existingProp, new Set([...existingExceptDeleted]))
        }
      }
    })

    dispatch(setFilteredStateData(map))
  }

  const handleClearAll = () => {
    dispatch(setFilteredStateData(new Map()))
  }

  const handleFilterGroupExpanded = (group: string, state: boolean) => {
    const updated = { ...filterGroupExpanded, [group]: state }
    setFilterGroupExpanded(updated)
  }

  const updateSearchString = (searchStr: string) => {
    setSearchString(searchStr)
    updateFilteredDocuments(filterData, searchStr)
    setAllSelected(false)
  }

  const NoDocumentsCard = styled('div')({
    width: '100%',
    height: '450px',
    background: '#FFFFFF',
    padding: '24px',
    paddingBottom: '45px',
    justifyContent: 'center',
  })
  const NoSearchResult = styled('div')({
    width: '100%',
    height: '450px',
    background: '#FFFFFF',
    padding: '24px',
    paddingBottom: '45px',
    justifyContent: 'center',
  })

  const filterEntries = Array.from(filterDataState.entries())
  const filterChipDatas = filterEntries
    .map((entry) => {
      const [key, set] = entry
      const valueArray = Array.from(set.values())
      return valueArray.map((v) => ({
        property: key,
        value:
          v instanceof Date ? DateTime.fromJSDate(v).toFormat('DD') : `${v}`,
      }))
    })
    .flat()

  return (
    <>
      <MainMenu currentItem="engagements" />

      {isMobile ? (
        <EngagementMenuMobile defaultSelected={t('Navigation.Documents')} />
      ) : (
        <EngagementMenu currentItem="documents" />
      )}

      <MainContent>
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        <>
          {isLoading ? (
            <Spinner open={isLoading} />
          ) : (
            <SearchLayout
              drawerDialogSlot={
                documentsRedux !== undefined && documentsRedux.length > 0 ? (
                  <Filters
                    filterSelected={filterDataState}
                    showSharedwithMe={false}
                    setFilterSelected={(payload) =>
                      dispatch(setFilteredStateData(payload))
                    }
                    filterValues={distinctGroupData}
                    filterClearTranslationKey="Filters.Clear"
                    filterTitleTranslationKeyPrefix="Docs.Headers"
                    visibleItemsCount={6}
                    filterGroupExpanded={filterGroupExpanded}
                    handleFilterGroupExpanded={handleFilterGroupExpanded}
                  />
                ) : undefined
              }
              shouldCloseDrawerByDefault
              searchSlot={
                documentsRedux !== undefined && documentsRedux.length > 0 ? (
                  <Box
                    sx={styles.searchContainer}
                    id="documents-filter-chips-clear-all-focus-target"
                    data-testid="searchwrapper">
                    <SearchBox
                      setFilter={updateSearchString}
                      placeholderText={t(
                        isMobile ? 'SearchByDocName' : 'SearchByKeyword',
                      )}
                    />
                  </Box>
                ) : undefined
              }
              chipsSlot={
                <FilterChips<DocumentModel>
                  clearAllFocusQuerySelector="#documents-filter-chips-clear-all-focus-target input"
                  filtersSelected={filterChipDatas}
                  onChipDeleted={handleFilterDeleted}
                  onClearAll={handleClearAll}
                  filterChipTranslationKeyPrefix="Docs.Headers"
                />
              }>
              <>
                {!!filteredDocuments?.length && (
                  <DocUploadTable
                    documents={filteredDocuments}
                    allSelected={allSelected}
                    selected={selected}
                    onDeleteSingleFile={handleDeleteSingleFile}
                    onDeleteSelectedFiles={handleDeleteSelectedFiles}
                    onDownloadSingleFile={handleDownloadSingleFile}
                    onDownloadSelectedFiles={handleDownloadSelectedFiles}
                    onSelectedChange={handleSelectedChange}
                    onSelectAllChange={handleSelectAllChange}
                  />
                )}

                {documentsRedux !== undefined &&
                documentsRedux.length > 0 &&
                filteredDocuments.length === 0 ? (
                  <NoSearchResult className="flex grow desktop:min-w-[350px] desktop:grow-0 items-center">
                    <div
                      className="flex-wrap font-prelo-light"
                      style={{ textAlign: 'center' }}>
                      <div
                        className="flex"
                        style={{ justifyContent: 'center' }}>
                        <NoSearchResultIcon />
                      </div>
                      <div
                        className="mb-[16px] desktop:min-w-[518px] tablet:min-w-[512px] mobile:min-w-[286px] "
                        style={{ fontSize: 'xx-large' }}>
                        {t('Docs.NoSearchResult')}
                      </div>
                      <div className="mb-[64px] desktop:w-[579px] ">
                        {t('Docs.TrySearchText')}
                      </div>
                    </div>
                  </NoSearchResult>
                ) : null}

                {documentsRedux !== undefined &&
                documentsRedux?.length === 0 ? (
                  <NoDocumentsCard className="flex grow desktop:min-w-[350px] desktop:grow-0 items-center">
                    <div
                      className="flex-wrap font-prelo-light"
                      style={{ textAlign: 'center' }}>
                      <div
                        className="flex"
                        style={{ justifyContent: 'center' }}>
                        <EmptyFolderIcon />
                      </div>
                      <div
                        className="mb-[16px] desktop:min-w-[518px] tablet:min-w-[512px] mobile:min-w-[286px] "
                        style={{ fontSize: 'xx-large' }}>
                        {t('Docs.NoDocumentsForCurrentUser')}
                      </div>
                      <div className="mb-[64px] desktop:w-[579px] ">
                        {t('Docs.NoDocumentsFound')}
                      </div>
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            navigate(`/engagements/${engagementId}/requests`)
                          }>
                          {t('Docs.ViewDocRequesButtonText')}
                        </Button>
                      </div>
                    </div>
                  </NoDocumentsCard>
                ) : undefined}
              </>
            </SearchLayout>
          )}
        </>
      </MainContent>

      <ConfirmModal
        isOpen={confirmDeleteCount > 0}
        okHandler={handleConfirmDelete}
        title={t('Docs.ConfirmDeleteTitle', {
          count: confirmDeleteCount,
        })}
        description={t('Docs.ConfirmDeleteDescription')}
        okText={t('Dialogs.OkText')}
        cancelText={t('Dialogs.CancelText')}
        showCancel
        cancelHandler={handleCancel}
      />
      <ConfirmModal
        isOpen={isAlertDeleteOpen}
        title={t('Docs.AlertDeletedTitle')}
        description={t('Docs.AlertDeletedDescription')}
        okText={t('Dialogs.OkText')}
        cancelText={t('Dialogs.CancelText')}
        showCancel={false}
        okHandler={handleCancel}
        cancelHandler={handleCancel}
      />
    </>
  )
}

export default Docs
