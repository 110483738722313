import { Box, Typography } from '@mui/material'
import React from 'react'
import { Styles } from '../../../../types'

const styles: Styles = {
  container: (theme) => ({
    display: 'flex',
    height: '3.125rem',
    [theme.breakpoints.only('tablet')]: {
      paddingRight: '1.5rem',
      height: '1.5625rem',
    },
    [theme.breakpoints.up('desktop')]: {
      paddingRight: '4.375rem',
      height: '4.6875rem',
    },
  }),
  text: (theme) => ({
    flexWrap: 'wrap',
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '1rem',
    // BEGIN cross-browser compatible line-clamp styles from Tailwind
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    // END cross-browser compatible line-clamp styles from Tailwind
    [theme.breakpoints.only('tablet')]: {
      WebkitLineClamp: 1,
    },
    [theme.breakpoints.up('desktop')]: {
      WebkitLineClamp: 2,
    },
  }),
}

interface ArticleListItemSnapshotProps {
  articleTitleId: string
  snapshot?: string
  index: number
}

const defaultProps = {
  snapshot: '',
}

const ArticleListItemSnapshot = ({
  articleTitleId,
  snapshot,
  index,
}: ArticleListItemSnapshotProps) => (
  <Box sx={styles.container}>
    <Typography
      sx={styles.text}
      aria-describedby={articleTitleId}
      data-testid={`Lbl_Article_Snapshot_${index}`}>
      {snapshot}
    </Typography>
  </Box>
)

ArticleListItemSnapshot.defaultProps = defaultProps

export default ArticleListItemSnapshot
