import { faAnglesUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Fab, Stack, Theme } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ScrollTop from '../../../rsmCoreComponents/components/ScrollTop'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import { Styles } from '../../../types'
import SectionHeader from '../SectionHeader'
import SearchLayoutDialog from './SearchLayoutDialog'
import SearchLayoutDrawer from './SearchLayoutDrawer'
import SearchLayoutDrawerDialogButton from './SearchLayoutDrawerDialogButton'

const styles: Styles = {
  container: (theme) => ({
    padding: '1rem',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.only('tablet')]: {
      padding: '2.5rem 2rem',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '2.5rem 6.5rem',
    },
  }),
  title: {
    '& > div': {
      marginBottom: '0.875rem',
    },
  },
  leftPanel: (theme) => ({
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
  }),
  drawerDialogContainer: (theme) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
  }),
  rightPanel: (theme) => ({
    marginLeft: '1rem',
    [theme.breakpoints.down('desktop')]: {
      marginLeft: 0,
    },
    flex: 1,
  }),
  rightTopPanel: (theme) => ({
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up('tablet')]: {
      paddingTop: '1rem',
    },
  }),
  rightTopPanelHeader: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    [theme.breakpoints.up('tablet')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  }),
  mobileDrawerDialogButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  contentContainer: {
    display: 'flex',
  },
  scrollTop: {
    zIndex: '1200',
  },
  fab: (theme) => ({
    backgroundColor: theme.palette.background.paper,
  }),
}

interface SearchLayoutProps {
  // Title heading
  title?: string

  // Slot for content appearing above drawer and search/results
  headerSlot?: React.ReactNode

  // Slot for content that should appear in a drawer (tablet/desktop) or dialog (mobile)
  drawerDialogSlot?: React.ReactNode

  // Drawer configuration overrides
  drawerDialogTitle?: string
  drawerDialogOpenIcon?: React.ReactNode
  drawerDialogCloseIcon?: React.ReactNode
  drawerDialogCollapseIcon?: React.ReactNode
  drawerWidth?: string
  shouldCloseDrawerByDefault?: boolean

  // Dialog configuration overrides
  dialogPrimaryButtonText?: string
  dialogSecondaryButtonText?: string
  dialogPrimaryButtonClickHandler?: () => void
  dialogSecondaryButtonClickHandler?: () => void
  dialogCloseHandler?: () => void

  // Slots for a search bar, auxiliary control, chips, and retention info
  searchSlot: React.ReactNode
  searchAuxSlot?: React.ReactNode
  chipsSlot?: React.ReactNode
  retentionInfo?: React.ReactNode

  // Content/search results
  children?: React.ReactNode

  // Slot for content appearing below drawer and search/results
  footerSlot?: React.ReactNode
}

const defaultProps = {
  title: '',
  headerSlot: null,
  drawerDialogSlot: null,
  drawerDialogTitle: '',
  drawerDialogOpenIcon: undefined,
  drawerDialogCloseIcon: undefined,
  drawerDialogCollapseIcon: undefined,
  drawerWidth: '15rem',
  shouldCloseDrawerByDefault: false,
  dialogPrimaryButtonText: undefined,
  dialogSecondaryButtonText: undefined,
  dialogPrimaryButtonClickHandler: undefined,
  dialogSecondaryButtonClickHandler: undefined,
  dialogCloseHandler: undefined,
  searchAuxSlot: null,
  chipsSlot: null,
  retentionInfo: null,
  children: undefined,
  footerSlot: null,
}

const SearchLayout = ({
  title,
  headerSlot,
  drawerDialogSlot,
  drawerDialogTitle,
  drawerDialogOpenIcon,
  drawerDialogCloseIcon,
  drawerDialogCollapseIcon,
  drawerWidth = '15rem',
  shouldCloseDrawerByDefault,
  dialogPrimaryButtonText,
  dialogSecondaryButtonText,
  dialogPrimaryButtonClickHandler,
  dialogSecondaryButtonClickHandler,
  dialogCloseHandler,
  searchSlot,
  searchAuxSlot,
  chipsSlot,
  retentionInfo,
  children,
  footerSlot,
}: SearchLayoutProps) => {
  const { isMobile } = useDeviceType()
  const { t } = useTranslation()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(
    Boolean(drawerDialogSlot && !shouldCloseDrawerByDefault),
  )

  const computedStyles = {
    chipsContainer: useCallback(
      (theme: Theme) => ({
        marginLeft:
          isDrawerOpen || !drawerDialogSlot ? '0rem' : `-${drawerWidth}`,
        paddingTop: '1rem',
        paddingLeft: isDrawerOpen || !drawerDialogSlot ? '0rem' : drawerWidth,
        [theme.breakpoints.up('tablet')]: {
          paddingBottom: '1rem',
        },
      }),
      [drawerWidth, isDrawerOpen],
    ),
    content: useCallback(
      (theme: Theme) => ({
        flexGrow: 1,
        [theme.breakpoints.up('tablet')]: {
          transition: theme.transitions.create('margin', {
            easing: isDrawerOpen
              ? theme.transitions.easing.sharp
              : theme.transitions.easing.easeOut,
            duration: isDrawerOpen
              ? theme.transitions.duration.leavingScreen
              : theme.transitions.duration.enteringScreen,
          }),
          marginLeft:
            isDrawerOpen || !drawerDialogSlot ? '0rem' : `-${drawerWidth}`,
        },
      }),
      [drawerWidth, isDrawerOpen],
    ),
  }

  return (
    <Box sx={styles.container}>
      {title && (
        <Box sx={styles.title}>
          <SectionHeader
            headingElement="h1"
            title={title}
            testId="Lbl_Insights_Title"
          />
        </Box>
      )}

      {headerSlot}

      <Stack
        sx={styles.stack}
        direction={isMobile || !drawerDialogSlot ? 'column' : 'row'}>
        {drawerDialogSlot ? (
          <Box sx={styles.leftPanel}>
            <Box sx={styles.drawerDialogContainer}>
              {isMobile ? (
                <SearchLayoutDialog
                  isOpen={isDialogOpen}
                  title={drawerDialogTitle}
                  primaryButtonText={dialogPrimaryButtonText}
                  secondaryButtonText={dialogSecondaryButtonText}
                  onClickPrimaryButton={() => {
                    if (dialogPrimaryButtonClickHandler) {
                      dialogPrimaryButtonClickHandler()
                    }
                    setIsDialogOpen(false)
                  }}
                  onClickSecondaryButton={dialogSecondaryButtonClickHandler}
                  onClose={() => {
                    if (dialogCloseHandler) {
                      dialogCloseHandler()
                    }
                    setIsDialogOpen(false)
                  }}>
                  {drawerDialogSlot}
                </SearchLayoutDialog>
              ) : (
                <>
                  <SearchLayoutDrawerDialogButton
                    isOpen={isDrawerOpen}
                    openIcon={drawerDialogOpenIcon}
                    closeIcon={drawerDialogCloseIcon}
                    collapseIcon={drawerDialogCollapseIcon}
                    onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
                    {drawerDialogTitle}
                  </SearchLayoutDrawerDialogButton>
                  <SearchLayoutDrawer
                    drawerWidth={drawerWidth}
                    isDrawerOpen={isDrawerOpen}>
                    {drawerDialogSlot}
                  </SearchLayoutDrawer>
                </>
              )}
            </Box>
          </Box>
        ) : null}

        <Box sx={styles.rightPanel}>
          <Box sx={styles.rightTopPanel}>
            <Box sx={styles.rightTopPanelHeader}>
              {searchSlot}

              {isMobile ? (
                <Box sx={styles.mobileDrawerDialogButtonContainer}>
                  {drawerDialogSlot && (
                    <SearchLayoutDrawerDialogButton
                      isOpen={isDialogOpen}
                      openIcon={drawerDialogOpenIcon}
                      closeIcon={drawerDialogCloseIcon}
                      onClick={() => setIsDialogOpen(true)}>
                      {drawerDialogTitle}
                    </SearchLayoutDrawerDialogButton>
                  )}

                  {searchAuxSlot}
                </Box>
              ) : (
                searchAuxSlot
              )}
            </Box>

            <Box sx={(theme) => computedStyles.chipsContainer(theme)}>
              {!isMobile && chipsSlot}
            </Box>
            {retentionInfo}
          </Box>

          <Box sx={styles.contentContainer}>
            <Box sx={(theme) => computedStyles.content(theme)}>{children}</Box>
          </Box>
        </Box>
      </Stack>

      {footerSlot}

      {!isMobile && (
        <ScrollTop>
          <Box sx={styles.scrollTop}>
            <Fab
              size="small"
              sx={styles.fab}
              aria-label={t('SearchLayout.ScrollToTop')}
              data-testid="Btn_ScrollToTop">
              <FontAwesomeIcon icon={faAnglesUp} />
            </Fab>
          </Box>
        </ScrollTop>
      )}
    </Box>
  )
}

SearchLayout.defaultProps = defaultProps

export default SearchLayout
