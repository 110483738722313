import React, { useEffect, useState } from 'react'
import {
  Box,
  Select,
  InputLabel,
  FormHelperText,
  SelectChangeEvent,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { PayerPaymentAccountViewModel } from '@rsmus/ecp-financeservice'
import { useSelector, useDispatch } from 'react-redux'
import { Controller, useFormContext } from 'react-hook-form'
import { PaymentMethodType, Styles } from '../../../types'
import NewPaymentMethod from './NewPaymentMethod'
import {
  getSelectedBankAccount,
  setSelectedBankAccount,
  getSelectedCreditCard,
  setSelectedCreditCard,
  getNewPaymentMethod,
  SelectedAccountTypeState,
  setAccountSelectedType,
  getAccountSelectedType,
  setDoNotSave,
  getSelectedPayer,
  getSelectedPaymentMethod,
  setpayeraccounts,
  getDoNotSave,
  getConfirmPaymentError,
} from '../../../store/invoices/paymentInfoSlice'
import { tokens } from '../../../styles/materialTheme'
import { CheckboxIcon, CheckboxOutlinedIcon, ArrowDownIcon } from '../../icons'
import api from '../../../api'
import PaymentMethodNew from './PaymentMethodNew'

const styles: Styles = {
  SelectPaymentMethodContainer: (theme) => ({
    width: '100%',
    paddingTop: 0,
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: 0,
    },
  }),
  AccountSelect: (theme) => ({
    width: '50%',
    [theme.breakpoints.down('desktop')]: {
      width: '100%',
    },
    [theme.breakpoints.only('mobile')]: {
      padding: 0,
    },
  }),
  choosePaymentMethod: (theme) => ({
    '.MuiOutlinedInput-root': {
      width: '100%',
      maxWidth: '100%',
    },
    '.MuiSelect-select': { fontFamily: 'Prelo-Book !important' },
    width: 'calc(100% - 4rem)',
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
    },
  }),
  choosePaymentMethodLabel: (theme) => ({
    fontFamily: 'Prelo-Black, sans-serif',
    fontSize: '1rem',
    paddingTop: '1.5rem',
    paddingBottom: '0.5rem',
    color: theme.palette.text.primary,
    display: 'block',
    [theme.breakpoints.down('desktop')]: {
      paddingTop: '1rem',
    },
  }),
  doNotSaveText: (theme) => ({
    color: theme.palette.primary.main,
    fontSize: '1rem',
    textDecorationColor: theme.palette.primary.main,
    marginLeft: 0,
  }),
  edit: (theme) => ({
    color: theme.palette.secondary.main,
    textDecorationColor: theme.palette.secondary.main,
  }),
  requiredError: {
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '0.875rem',
    color: tokens.colors.rsmRed.secondary,
  },
  disabled: () => ({
    color: tokens.colors.rsmGray.disabled,
  }),
}

export interface PaymentMethodProps {
  paymentMethodType: PaymentMethodType
  isScheduledPayment: boolean
  sessionCreating: () => void
  sessionChange: (sessionId: string, isError: boolean) => void
}

const PaymentMethod = ({
  paymentMethodType,
  isScheduledPayment,
  sessionCreating,
  sessionChange,
}: PaymentMethodProps) => {
  const { control } = useFormContext()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const accountSelectedType = useSelector(getAccountSelectedType)
  const isNewPaymentMethod = useSelector(getNewPaymentMethod)
  const selectedPayer = useSelector(getSelectedPayer)
  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)
  const selectedBankAccount = useSelector(getSelectedBankAccount)
  const selectedCreditCard = useSelector(getSelectedCreditCard)
  const doNotSaveChecked = useSelector(getDoNotSave)
  const confirmPaymentError = useSelector(getConfirmPaymentError)
  const accountSelectedTypeStatus: SelectedAccountTypeState = useSelector(
    getAccountSelectedType,
  )

  const [payerPaymentAccounts, setPayerPaymentAccounts] = useState<
    PayerPaymentAccountViewModel[]
  >([])

  const unavailableStatus: SelectedAccountTypeState = 'Unavailable'
  const newStatus: SelectedAccountTypeState = 'New'
  const existingStatus: SelectedAccountTypeState = 'Existing'
  const UnselectedStatus: SelectedAccountTypeState = 'Unselected'

  const loadPayerAccountData = async (paymentMethod: PaymentMethodType) => {
    if (selectedPayer?.payerId && paymentMethod === 'BankAccount') {
      const bankAccounts =
        (await api.finance.payment_GetPayerBankAccounts(selectedPayer?.payerId))
          ?.data || []
      setPayerPaymentAccounts(bankAccounts || [])
      dispatch(setpayeraccounts(bankAccounts.length))
    } else if (selectedPayer?.payerId && paymentMethod === 'CreditCard') {
      const creditCards =
        (await api.finance.payment_GetPayerCreditCards(selectedPayer?.payerId))
          ?.data || []
      setPayerPaymentAccounts(creditCards || [])
      dispatch(setpayeraccounts(creditCards.length))
    }
  }

  const getSelectedPaymentMethodInfo =
    (): PayerPaymentAccountViewModel | null => {
      const selectedId =
        paymentMethodType === 'BankAccount'
          ? selectedBankAccount
          : selectedCreditCard

      const selectedPaymentAccount: PayerPaymentAccountViewModel[] =
        payerPaymentAccounts.filter(
          (a: PayerPaymentAccountViewModel) => a.id === selectedId,
        )

      if (selectedPaymentAccount.length === 0) return null
      if (selectedPaymentAccount.length > 1)
        throw new Error('Multiple payment accounts with the same id.')
      return selectedPaymentAccount[0]
    }

  const setSelectedPaymentMethod = (selectedValue: number) => {
    if (paymentMethodType === 'BankAccount') {
      dispatch(setSelectedBankAccount(selectedValue))
    } else {
      dispatch(setSelectedCreditCard(selectedValue))
    }
  }

  const handleDoNotSaveCheckboxChange = (checked: boolean) => {
    dispatch(setDoNotSave(checked))
  }

  useEffect(() => {
    if (!confirmPaymentError) loadPayerAccountData(selectedPaymentMethod)
  }, [confirmPaymentError])

  useEffect(() => {
    loadPayerAccountData(selectedPaymentMethod)
  }, [selectedPayer, paymentMethodType])

  return (
    <Box sx={styles.SelectPaymentMethodContainer}>
      <Box sx={styles.AccountSelect}>
        <Box sx={styles.disabled}>
          <InputLabel
            disabled={
              accountSelectedTypeStatus === unavailableStatus ||
              accountSelectedTypeStatus === newStatus ||
              payerPaymentAccounts.length === 0
            }
            htmlFor={`choose${paymentMethodType}`}
            data-testid={`Lbl_PaymentMethod_Choose${paymentMethodType}`}
            sx={styles.choosePaymentMethodLabel}>
            {t(`Invoicing.Choose${paymentMethodType}`)}
            <span className="sr-only">{t('srOnlyRequired')}</span>
          </InputLabel>

          <Controller
            name="paymentMethod"
            control={control}
            defaultValue=""
            rules={{
              required:
                selectedPayer &&
                `${
                  payerPaymentAccounts.length > 0 ||
                  accountSelectedTypeStatus === unavailableStatus ||
                  accountSelectedTypeStatus === newStatus ||
                  accountSelectedTypeStatus === UnselectedStatus
                    ? t(`Invoicing.Select${paymentMethodType}`)
                    : ''
                } `,
            }}
            render={({
              field: { onChange, value, ref },
              fieldState: { error },
            }) => (
              <>
                {payerPaymentAccounts.length > 0 ||
                accountSelectedTypeStatus === unavailableStatus ? (
                  <Select
                    native
                    sx={styles.choosePaymentMethod}
                    id={`choose${paymentMethodType}`}
                    inputRef={ref}
                    error={!!error}
                    IconComponent={ArrowDownIcon}
                    disabled={
                      accountSelectedTypeStatus === unavailableStatus ||
                      accountSelectedTypeStatus === newStatus
                    }
                    value={value}
                    onChange={(e: SelectChangeEvent<number>) => {
                      onChange(e.target.value)
                      setSelectedPaymentMethod(e.target.value as number)
                      dispatch(setAccountSelectedType(existingStatus))
                    }}
                    inputProps={{
                      'aria-label': t(`Invoicing.Choose${paymentMethodType}`),
                      'aria-describedby': 'PaymentMethodErrorText',
                      'data-testid': `Sel_PaymentType_${paymentMethodType}`,
                    }}>
                    <option style={{ display: 'none' }} label=" " />
                    {isNewPaymentMethod ||
                      payerPaymentAccounts?.map(
                        (paymentAccount: PayerPaymentAccountViewModel) => (
                          <option
                            key={paymentAccount.id}
                            data-testid={paymentAccount.id}
                            value={paymentAccount.id}>
                            {paymentAccount.name}
                          </option>
                        ),
                      )}
                  </Select>
                ) : (
                  <Box sx={styles.disabled}>
                    {paymentMethodType === 'CreditCard'
                      ? t(`Invoicing.NoCardSaved`)
                      : t(`Invoicing.NoBankInfoSaved`)}
                  </Box>
                )}
                {error &&
                  (payerPaymentAccounts.length > 0 ||
                    accountSelectedTypeStatus === unavailableStatus) && (
                    <FormHelperText
                      id="PaymentMethodErrorText"
                      sx={styles.requiredError}>
                      {error.message}
                    </FormHelperText>
                  )}
              </>
            )}
          />
        </Box>
      </Box>
      <InputLabel
        sx={styles.choosePaymentMethodLabel}
        disabled={
          accountSelectedTypeStatus === unavailableStatus ||
          accountSelectedTypeStatus === existingStatus
        }>
        {t(`Invoicing.New${paymentMethodType}`)}
      </InputLabel>
      {!isScheduledPayment && (
        <Box>
          <Box>
            <Trans
              i18nKey="Invoicing.PaymentMethodSaveNote"
              components={{ b: <b />, i: <i /> }}
              values={{ clientName: selectedPayer?.name }}
            />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                id="doNotSaveCheckbox"
                sx={styles.doNotSaveText}
                checked={doNotSaveChecked}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleDoNotSaveCheckboxChange(e.target.checked)
                }
                disableRipple
                aria-label={t(`Invoicing.AccountInformationDoNotSave`)}
                disabled={!(accountSelectedTypeStatus === newStatus)}
                icon={<CheckboxOutlinedIcon />}
                checkedIcon={<CheckboxIcon />}
              />
            }
            label={t('Invoicing.AccountInformationDoNotSave').toString()}
          />
        </Box>
      )}
      <PaymentMethodNew
        clientId={selectedPayer?.payerId}
        paymentMethodType={paymentMethodType}
        sessionCreating={sessionCreating}
        sessionChange={sessionChange}
        paymentsAccountsCount={payerPaymentAccounts.length}
        disabled={
          !selectedPayer ||
          accountSelectedTypeStatus === unavailableStatus ||
          accountSelectedTypeStatus === existingStatus ||
          isNewPaymentMethod
        }
        accountAdded={(paymentMethod) => {
          loadPayerAccountData(paymentMethod)
        }}
        trackNewPaymentMethod
      />
      {accountSelectedType === 'New' && getSelectedPaymentMethodInfo() && (
        <NewPaymentMethod
          institutionName={getSelectedPaymentMethodInfo()?.institutionName}
          accountNumberSuffix={
            getSelectedPaymentMethodInfo()?.accountNumberSuffix
          }
          paymentMethodType={paymentMethodType}
        />
      )}
    </Box>
  )
}

export default PaymentMethod
