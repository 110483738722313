import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Box, Button, Stack, styled } from '@mui/material'
import { t } from 'i18next'
import { TeamDocsSiteViewModel } from '@rsmus/ecp-userservice'
import { StyledLink, useStyles } from './ProjectList.styles'
import { ProjectsContext } from '../state/context'
import OverFlowToolTip from '../../OverFlowToolTip/OverFlowToolTip'
import { nextPage } from '../state/actions'
import { NoSearchFound } from '../../../rsmCoreComponents/components/NoSearchFound/NoSearchFound'
import sortProjects from './ProjectList.service'

const LoadButtonContainer = styled(Box)({
  justifyContent: 'center',
  justifyItems: 'center',
  paddingBottom: '3.75rem',
  paddingTop: '3.75rem',
  whiteSpace: 'nowrap',
})

const LinkWithFocus = ({
  project,
  focused,
  testId,
}: {
  project: TeamDocsSiteViewModel
  focused: boolean
  testId?: string
}) => {
  const componentRef = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    if (focused && componentRef !== null && componentRef.current !== null) {
      componentRef.current?.focus()
    }
  }, [componentRef])

  return (
    <StyledLink
      ref={componentRef}
      target="_blank"
      rel="noreferrer noopener" // Stops tabnabbing
      href={project?.url}
      color="primary"
      data-testid={testId}>
      <OverFlowToolTip ignoreTabIndex>{project?.name}</OverFlowToolTip>
    </StyledLink>
  )
}

LinkWithFocus.defaultProps = {
  testId: '',
}

const ProjectList = () => {
  const {
    state: {
      projects,
      filter,
      displayedCount,
      pageSize,
      currentPageStartIndex = 0,
    },
    dispatch,
  } = useContext(ProjectsContext)
  const [initalFocus, setInitalFocus] = useState(false)

  const filteredProjects = useMemo(
    () =>
      !filter || filter.length < 2 // Search with 2+ chars entered
        ? [...projects.sort(sortProjects)]
        : projects.filter((project) =>
            project?.name?.toLowerCase().includes(filter.toLowerCase()),
          ),
    [filter, projects],
  )

  const resultsPage = useMemo(() => {
    const displayedResults = filteredProjects.slice(
      0,
      displayedCount || pageSize,
    )

    return displayedResults
  }, [filteredProjects, displayedCount])

  const displayLoadMore =
    pageSize && filteredProjects.length > (displayedCount || pageSize)

  const classes = useStyles()

  if (!resultsPage?.length) {
    return (
      <NoSearchFound
        title={t('Projects.NoSearchResultsTitle')}
        subTitle={t('Projects.NoSearchResultsSubTitle')}
      />
    )
  }

  return (
    <Stack sx={classes.root}>
      <>
        {
          /* eslint-disable react/no-array-index-key */
          resultsPage.map((project, index) => {
            const focused = currentPageStartIndex === index
            return (
              <LinkWithFocus
                key={`pl-${index}`}
                project={project}
                focused={initalFocus && focused}
                testId={`Projects_List_ProjectItem_${index + 1}`}
              />
            )
          })
        }
      </>
      <LoadButtonContainer display="flex">
        {displayLoadMore && (
          <Button
            sx={{ width: '8.175rem', height: '3.125rem' }}
            variant="contained"
            onClick={() => {
              dispatch(nextPage())
              setInitalFocus(true)
            }}
            aria-label={t('Projects.LoadMore')}
            data-testid="Btn_Projects_LoadMore">
            {t('Projects.LoadMore')}
          </Button>
        )}
      </LoadButtonContainer>
    </Stack>
  )
}

export default ProjectList
