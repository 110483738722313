import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import RoboIcon from '../icons/RoboIcon'
import { Styles } from '../../types'

const styles: Styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10% 0 10% 0',
    backgroundColor: 'white',
    justifyContent: 'center',
    textAlign: 'center',
    height: '55rem',
  },
  iconWrapper: {
    margin: '0 3.125rem 0 3.125rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  header: {
    marginTop: '1.25rem',
    fontFamily: 'Prelo-Light, sans-serif',
    fontWeight: 300,
    lineHeight: '2.5rem',
    fontSize: '2.25rem',
    color: (theme) => theme.palette.common.black,
  },
  msgWrapper: {
    marginTop: '1rem',
    color: (theme) => theme.palette.common.black,
    fontFamily: 'Prelo-Book, sans-serif',
    fontWeight: 400,
    lineHeight: '1.5rem',
    fontSize: '1.125rem',
    verticalAlign: 'middle',
  },
  msg: {
    display: 'inline-block',
    maxWidth: '36rem',
  },
  description: (theme) => ({
    marginTop: '5.3125rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '3.0625rem',
    },
    color: theme.palette.common.black,
    fontFamily: 'Prelo-Book, sans-serif',
    fontWeight: 400,
    lineHeight: '1.5rem',
    fontSize: '1.125rem',
  }),
}

const MovedOrDeletedError = () => {
  const { t } = useTranslation()

  return (
    <Box sx={styles.root}>
      <Box sx={styles.iconWrapper}>
        <RoboIcon />
      </Box>
      <Box sx={styles.header} data-testid="Hed_Error_NotWorkingHeader">
        {t('SomethingsNotWorkingHeader')}
      </Box>
      <Box sx={styles.msgWrapper}>
        <Box
          component="span"
          sx={styles.msg}
          data-testid="Lbl_Error_NotWorkingMessage">
          {t('ItemMovedOrDeletedMessage')}
        </Box>
      </Box>
      <Box sx={styles.description} data-testid="Lbl_Error_SubmitTicket">
        <Trans i18nKey="SubmitSupportTicketMessage">
          If you think this is a mistake, please{' '}
          <Link
            to="/support"
            style={{
              color: '#4B9C2E',
              textDecoration: 'underline',
            }}
            data-testid="Lnk_Error_SubmitTicket">
            submit a support ticket.
          </Link>
        </Trans>
      </Box>
    </Box>
  )
}

export default MovedOrDeletedError
