import { Box, Button } from '@mui/material'
import Typography from '@mui/material/Typography'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import { getHighContrast } from '../../../store/userSettings/userSettingsSlice'
import { Styles } from '../../../types'
import { ArticleMetaData } from '../../../utils/helpers/Article.service'
import { useAppSelector } from '../../../utils/hooks'
import ArticleHeroImageContainer from '../../layouts/ArticleHeroImageContainer'
import OverFlowToolTip from '../../OverFlowToolTip/OverFlowToolTip'
import DateIndustry from '../DateIndustry'

const styles: Styles = {
  container: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    marginRight: '1rem',
    marginLeft: '1rem',
    paddingTop: '2.5rem',
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('tablet')]: {
      marginLeft: '4rem',
      width: '24.5625rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingTop: '6.875rem',
    },
    [theme.breakpoints.up('desktop')]: {
      paddingTop: '8.125rem',
    },
  }),
  metaContainer: {
    display: 'flex',
  },
  featuredTitle: (theme) => ({
    fontFamily: 'Prelo-Bold, sans-serif',
    fontSize: '0.75rem',
    letterSpacing: '0.0625rem',
    textTransform: 'uppercase',
    color: theme.palette.common.white,
  }),
  articleTitleContainer: (theme) => ({
    marginTop: '1.5625rem',
    width: '15.625rem',
    [theme.breakpoints.up('tablet')]: {
      marginTop: '0.5rem',
      width: '24.5625rem',
    },
  }),
  articleTitle: (theme) => ({
    fontFamily: 'Prelo-Light, sans-serif',
    fontSize: '1.875rem',
    lineHeight: '2.5rem',
    color: theme.palette.common.white,
    [theme.breakpoints.up('tablet')]: {
      fontSize: '3rem',
      lineHeight: '3.5rem',
    },
  }),
  dateIndustryContainer: {
    display: 'flex',
    marginTop: '1.5rem',
  },
  snapshotContainer: {
    marginTop: '1rem',
    height: '4.5rem',
  },
  snapshot: (theme) => ({
    flexWrap: 'wrap',
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: theme.palette.common.white,
    // BEGIN cross-browser compatible line-clamp styles from Tailwind
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 4,
    // END cross-browser compatible line-clamp styles from Tailwind
    [theme.breakpoints.up('tablet')]: {
      WebkitLineClamp: 3,
    },
  }),
  readNowButtonContainer: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: '4.6875rem',
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
    [theme.breakpoints.up('tablet')]: {
      justifyContent: 'flex-start',
      marginTop: '2.625rem',
    },
  }),
  readNowButton: (theme) => ({
    padding: '0.75rem 1.5rem',
    height: '3.125rem',
    [theme.breakpoints.down('tablet')]: {
      width: '18rem',
      height: '3.125rem',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '8.75rem',
    },
  }),
}

interface SpotlightArticleProps {
  article?: ArticleMetaData
}

const defaultProps = {
  article: undefined,
}

const SpotlightArticle = ({ article }: SpotlightArticleProps) => {
  const navigate = useNavigate()
  const { isMobile } = useDeviceType()
  const { t } = useTranslation()
  const isHighContrast = useAppSelector(getHighContrast)

  const defaultLink = '/'

  const handleReadMore = (a: ArticleMetaData | undefined) => {
    const path = a ? `/insights/${a.ecpId ?? ''}` : defaultLink
    navigate(path)
  }

  const content = useMemo(() => {
    if (!article) {
      return <div />
    }

    const { id, title, snapshot } = article

    return (
      <Box sx={styles.container}>
        <Box sx={styles.metaContainer}>
          {isMobile ? (
            <Typography
              sx={styles.featuredTitle}
              data-testid="Lbl_SpotlightArticle_Title">
              {`${t('HomePage.ArticleFeaturedContent')}`}
            </Typography>
          ) : (
            <DateIndustry
              date={t('InsightArticle.ArticleDate', {
                date: article.publishedOn,
              })}
              label={article.label}
            />
          )}
        </Box>

        <Box sx={styles.articleTitleContainer}>
          <Typography
            component="div"
            role="heading"
            aria-level={2}
            id={`${id}`}
            sx={styles.articleTitle}
            data-testid="Lbl_SpotlightArticle_Title">
            {/* OverFlowToolTip doesn't currently support being disabled via the numberOfLines (or any other) prop. */}
            {isMobile ? (
              title
            ) : (
              <OverFlowToolTip numberOfLines={2}>{title}</OverFlowToolTip>
            )}
          </Typography>
        </Box>

        {isMobile && (
          <Box sx={styles.dateIndustryContainer}>
            <DateIndustry
              date={t('InsightArticle.ArticleDate', {
                date: article.publishedOn,
              })}
              label={article.label}
            />
          </Box>
        )}

        <Box sx={styles.snapshotContainer}>
          <Typography
            aria-describedby={`${id}`}
            sx={styles.snapshot}
            data-testid="Lbl_SpotlightArticle_Snapshot">
            {snapshot}
          </Typography>
        </Box>

        <Box sx={styles.readNowButtonContainer}>
          <Button
            variant={isHighContrast ? 'outlined' : 'contained'}
            color="secondary"
            sx={styles.readNowButton}
            aria-label={`${t('HomePage.ArticleReadNow')} ${title}`}
            onClick={() => handleReadMore(article)}
            data-testid="Btn_SpotlightArticle_ReadMore">
            {t('HomePage.ArticleReadNow')}
          </Button>
        </Box>
      </Box>
    )
  }, [article, isHighContrast, isMobile])

  return (
    <ArticleHeroImageContainer
      imageUrl={article?.imageUrl}
      imageTestId="Img_Spotlight_Picture">
      {content}
    </ArticleHeroImageContainer>
  )
}

SpotlightArticle.defaultProps = defaultProps

export default SpotlightArticle
