import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { Box, Button } from '@mui/material'
import { RangeViewModelOfDateTime } from '@rsmus/ecp-financeservice'
import ResponsiveModal from '../../../rsmCoreComponents/components/ResponsiveModal'
import { Styles } from '../../../types'
import api from '../../../api'

interface ScheduledPaymentDateChangeDialogProps {
  paymentInstallmentPlanId: number
  planName: string
  existingPaymentDate: Date
  open: boolean
  onClose: () => void
}

const CalendarIcon = () => <FontAwesomeIcon icon={faCalendar} size="2x" />

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.5rem',
    padding: '1.5rem 0 1.5rem 0',
    fontWeight: '700',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem',
  },
}

const ScheduledPaymentDateChangeDialog = ({
  paymentInstallmentPlanId,
  planName,
  existingPaymentDate,
  open,
  onClose,
}: ScheduledPaymentDateChangeDialogProps) => {
  const { t } = useTranslation()
  const [range, setRange] = useState<RangeViewModelOfDateTime | undefined>(
    undefined,
  )
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const twoDaysFromNow = new Date()
  twoDaysFromNow.setDate(twoDaysFromNow.getDate() + 2)

  const thirtyDaysFromNow = new Date()
  thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 31)

  const handleConfirmNewPaymentDate = useCallback(() => {
    if (!selectedDate) {
      return
    }
    api.finance
      .payment_ChangePaymentDate(paymentInstallmentPlanId, selectedDate)
      .then(() => {
        onClose()
      })
  }, [paymentInstallmentPlanId, onClose, selectedDate])

  useEffect(() => {
    if (!paymentInstallmentPlanId) return
    api.finance
      .payment_GetChangePaymentDateRange(paymentInstallmentPlanId)
      .then((response) => {
        if (!response.data) return
        // The dates are in UTC, but need to be interpreted as a local date so the datepicker shows the correct date range.
        setRange({
          start: new Date(
            response.data.start.getUTCFullYear(),
            response.data.start.getUTCMonth(),
            response.data.start.getUTCDate(),
          ),
          end: new Date(
            response.data.end.getUTCFullYear(),
            response.data.end.getUTCMonth(),
            response.data.end.getUTCDate(),
          ),
        })
      })
  }, [paymentInstallmentPlanId])

  const handleCancel = () => {
    setSelectedDate(null)
    onClose()
  }

  return (
    <ResponsiveModal
      open={open}
      onClose={onClose}
      desktopMaxHeight="49rem"
      desktopMaxWidth="26rem"
      removeHeaderSpacing
      removeContentSpacing
      removeCloseButton>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <CalendarIcon />
          <Box component="h1">
            {t('Invoicing.ChangeSchedulePaymentDate.Title')}
          </Box>
        </Box>
        <hr />
        <Box sx={styles.innerContainer}>
          <p>
            <Trans
              i18nKey="Invoicing.ChangeSchedulePaymentDate.Description"
              components={{ b: <b /> }}
              values={{ planName }}
            />
          </p>
          <p>
            <Trans
              i18nKey="Invoicing.ChangeSchedulePaymentDate.Note"
              components={{ b: <b /> }}
            />
          </p>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CalendarPicker
              date={selectedDate || existingPaymentDate}
              onChange={(newValue) => setSelectedDate(newValue)}
              minDate={range?.start || twoDaysFromNow}
              maxDate={range?.end || thirtyDaysFromNow}
              views={['day']}
            />
          </LocalizationProvider>
          <Button
            disableFocusRipple
            disableRipple
            variant="contained"
            onClick={handleConfirmNewPaymentDate}>
            {t('Invoicing.ChangeSchedulePaymentDate.ConfirmNewPaymentDate')}
          </Button>
          <Button onClick={handleCancel} variant="outlined">
            {t('Invoicing.ChangeSchedulePaymentDate.Cancel')}
          </Button>
        </Box>
      </Box>
    </ResponsiveModal>
  )
}
export default ScheduledPaymentDateChangeDialog
