/* eslint-disable react/no-unused-prop-types */
import { Box } from '@mui/material'
import React, { ReactNode } from 'react'

interface WizardStepProps {
  children: ReactNode
  handleBack?: (params: unknown) => unknown
  handleDone?: (params: unknown) => unknown
  handleNext?: (params: unknown) => unknown
  handleSave?: (params: unknown) => unknown
}

const defaultProps = {
  handleBack: undefined,
  handleDone: undefined,
  handleNext: undefined,
  handleSave: undefined,
}

const WizardStep = ({ children }: WizardStepProps) => (
  <Box className="RsmWizardStep-root">{children}</Box>
)

WizardStep.defaultProps = defaultProps

export default WizardStep
