import React, { useState } from 'react'
import { useGetServiceListQuery } from '../../../store/cmsService'
import BusinessBuildingIcon from '../../icons/BusinessBuildingIcon'
import CoinsIcon from '../../icons/CoinsIcon'
import CollaborationIcon from '../../icons/CollaborationIcon'
import DataCloudIcon from '../../icons/DataCloudIcon'
import DesktopDashboardIcon from '../../icons/DesktopDashboardIcon'
import FinancialTalkBubblesIcon from '../../icons/FinancialTalkBubblesIcon'
import GavelStrikeIcon from '../../icons/GavelStrikeIcon'
import GearConferenceIcon from '../../icons/GearConferenceIcon'
import GearDigitizationIcon from '../../icons/GearDigitizationIcon'
import GearCircuitryIcon from '../../icons/GearCircuitryIcon'
import GlobeCircumnavigateIcon from '../../icons/GlobeCircumnavigateIcon'
import MagnifyingGlassDocumentIcon from '../../icons/MagnifyingGlassDocumentIcon'
import MagnifyingGlassFingerprintIcon from '../../icons/MagnifyingGlassFingerprintIcon'
import MergerIcon from '../../icons/MergerIcon'
import PersonListeningIcon from '../../icons/PersonListeningIcon'
import ProtectionIcon from '../../icons/ProtectionIcon'
import StrategyIcon from '../../icons/StrategyIcon'
import TaxDocumentIcon from '../../icons/TaxDocumentIcon'
import ToDoListIcon from '../../icons/ToDoListIcon'
import TransactionIcon from '../../icons/TransactionIcon'
import { Interest, TagIconMap } from '../types'
import InterestTile from './InterestTile'

interface ServiceLineSelectorProps {
  defaultSelected?: number[]
  onChange: (value: number[]) => void
}

const ServiceLineSelector = ({
  defaultSelected,
  onChange,
}: ServiceLineSelectorProps) => {
  const { data } = useGetServiceListQuery()
  const [selected, setSelected] = useState<number[]>(defaultSelected ?? [])

  const tagIconMap: TagIconMap = {
    audit: <MagnifyingGlassDocumentIcon />,
    'business-applications': <BusinessBuildingIcon />,
    'business-strategy': <StrategyIcon />,
    'business-tax': <TaxDocumentIcon />,
    'cybersecurity-risk': <ProtectionIcon />,
    'data-digital-services': <DesktopDashboardIcon />,
    'digital-transformation': <GearDigitizationIcon />,
    'due-diligence': <ToDoListIcon />,
    'family-office-services': <GearConferenceIcon />,
    'financial-consulting': <FinancialTalkBubblesIcon />,
    'financial-management': <CoinsIcon />,
    'global-services': <GlobeCircumnavigateIcon />,
    'managed-services': <GearCircuitryIcon />,
    'managed-technology-services': <DataCloudIcon />,
    'management-consulting': <CollaborationIcon />,
    'mergers-acquisition': <MergerIcon />,
    'private-client-services': <PersonListeningIcon />,
    'regulatory-compliance': <GavelStrikeIcon />,
    'risk-consulting': <MagnifyingGlassFingerprintIcon />,
    'transaction-advisory': <TransactionIcon />,
  }

  const interests = data?.data?.map((interest) => {
    const tag = interest.tag?.split('/')?.pop()
    return tag ? { ...interest, icon: tagIconMap[tag] } : interest
  }) as Interest[]

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {interests?.map((interest, index) => (
        <InterestTile
          key={interest.tag}
          testId="ServiceLine"
          checked={selected.includes(interest.id)}
          index={index}
          interest={interest}
          onClick={(checked) => {
            const value = checked
              ? [...selected, interest.id]
              : selected.filter((i) => i !== interest.id)
            setSelected(value)
            onChange(value)
          }}
        />
      ))}
    </>
  )
}

ServiceLineSelector.defaultProps = {
  defaultSelected: undefined,
}

export default ServiceLineSelector
