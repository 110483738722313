import { IUserInfo } from '@rsmus/ecp-userservice'
import { useEffect, useState } from 'react'
import analyticsClickTracker from '../../analytics/eventListeners'

const useTracking = (userInfo: IUserInfo, dateConsentChanged: Date | null) => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const [trackingScriptsEnabled, setTrackingScriptsEnabled] =
    useState<boolean>(false)

  useEffect(() => {
    // If the user is not logged in or opted out to category "C0003" performance cookies return and don't load tracking scripts
    if (
      !userInfo ||
      !(window as any)?.OnetrustActiveGroups?.includes('C0003') ||
      loaded
    ) {
      //  if tracking was already enabled, and user does not agree to "C0003" performance cookies, stop tracking in pendo & adobe
      if (
        trackingScriptsEnabled &&
        !(window as any)?.OnetrustActiveGroups?.includes('C0003')
      ) {
        ;(window as any).pendo?.stopSendingEvents()
        window.removeEventListener('click', analyticsClickTracker, true)
        const adobeScript = document.getElementById('script_adobe')
        adobeScript?.remove()
      }
      return
    }

    // Adobe Launch
    const addAdobeLaunchScript = () => {
      const script = document.createElement('script')
      script.async = true
      document.head.appendChild(script)
      script.onload = () => {
        window.adobeDataLayer = window.adobeDataLayer || []
        window.addEventListener('click', analyticsClickTracker, true)
      }
      script.setAttribute('id', 'script_adobe')
      script.src = process.env.REACT_APP_ADOBE_ANALYTICS || ''
    }
    addAdobeLaunchScript()

    const initPendo = () => {
      // Execute the pendo script in index.html
      ;(window as any).globalPendoInit()

      // Wait for the pendo object to be available before initializing.
      setTimeout(() => {
        ;(window as any).pendo?.initialize({
          visitor: {
            id: userInfo.userId, // Required if user is logged in
            email: userInfo.emailAddress, // Recommended if using Pendo Feedback, or NPS Email
            full_name: `${userInfo.firstName} ${userInfo.lastName}`, // Recommended if using Pendo Feedback
            role: userInfo.userType, // Optional
            isEmployee: userInfo.isEmployee, // Optional
          },

          account: {
            id: userInfo.userId, // Highly recommended, required if using Pendo Feedback
            // name:         // Optional
            // is_paying:    // Recommended if using Pendo Feedback
            // monthly_value:// Recommended if using Pendo Feedback
            // planLevel:    // Optional
            // planPrice:    // Optional
            // creationDate: // Optional
            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
          },
        })
      }, 1000)
    }
    initPendo()

    setLoaded(true)
    setTrackingScriptsEnabled(true)
  }, [userInfo, dateConsentChanged])
}

export default useTracking
