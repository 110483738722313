import React from 'react'

export interface ChevronDownIconProps {
  className?: string
}

const defaultProps = {
  className: ''
}

const ChevronDownIcon = ({ className }:ChevronDownIconProps) => (
  <svg className={className}
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.55808 5.52511L1.01752 1.95923C0.772806 1.71277 0.772806 1.31423 1.01752 1.07038L1.60588 0.477818C1.85059 0.231352 2.2463 0.231352 2.48842 0.477818L4.99805 3.0054L7.50768 0.477818C7.7524 0.231352 8.14811 0.231352 8.39022 0.477818L8.98378 1.06776C9.2285 1.31423 9.2285 1.71277 8.98378 1.95661L5.44322 5.52249C5.19851 5.77158 4.8028 5.77158 4.55808 5.52511Z"
      fill="#888B8D"
    />
  </svg>
)

ChevronDownIcon.defaultProps = defaultProps
export default ChevronDownIcon
