import React from 'react'

import { v4 as uuidv4 } from 'uuid'

export interface IconProps {
  color?: string
  size?: number
}

const ErrorIcon = ({ color, size = 24 }: IconProps) => {
  const id = uuidv4()
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 21H23L12 2L1 21ZM13 18H11V16H13V18ZM13 14H11V10H13V14Z"
        fill={color}
      />
      <mask
        id={`mask0_${id}`}
        maskUnits="userSpaceOnUse"
        x="1"
        y="2"
        width="22"
        height="19">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 21H23L12 2L1 21ZM13 18H11V16H13V18ZM13 14H11V10H13V14Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#mask0_${id})`}>
        <rect width="24" height="24" fill={color} />
      </g>
    </svg>
  )
}

ErrorIcon.defaultProps = {
  color: undefined,
  size: '1.5rem',
}

export default ErrorIcon
