/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react'
import { Link as MuiLink, styled, Typography } from '@mui/material'
import { DownloadFileModel } from '@rsmus/ecp-userservice'
import api from '../../../../api'

const Link = styled(MuiLink)(({ theme }) => ({
  color: theme.palette.secondary.main,
  display: 'block',
  fontSize: '1rem',
  fontWeight: '400',
  textDecoration: 'underline',
}))

const SecureLink = ({
  href: fileName,
  children,
  testId,
}: {
  href: string
  children: React.ReactNode
  testId?: string | undefined
}) => {
  const [targetUrl, setTargetUrl] = useState<DownloadFileModel>({
    downloadUri: '',
    fileName: '',
  })

  useEffect(() => {
    const requestSecureLinkAsync = async (file: string) => {
      const fileData = (await api.user.document_GetDownloadFileUri(file, null))
        ?.data
      setTargetUrl(
        fileData || {
          downloadUri: '',
          fileName: '',
        },
      )
    }

    requestSecureLinkAsync(fileName)
  }, [])

  const disabled = useMemo(
    () => !targetUrl || !targetUrl.downloadUri,
    [targetUrl],
  )

  const { downloadUri } = targetUrl

  return disabled ? (
    <Typography data-testid={testId}>{children}</Typography>
  ) : (
    <Link
      href={downloadUri}
      target="_blank"
      rel="noopener noreferrer"
      data-testid={testId}>
      {children}
    </Link>
  )
}

SecureLink.defaultProps = {
  testId: undefined,
}

export default SecureLink
