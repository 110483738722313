import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  getInvoiceSelectedFilters,
  InvoiceSelectedFiltersConstants,
  InvoiceSelectedFiltersState,
  setFilterValue,
  getAmountRangeFilterMax,
} from '../../../store/invoices/invoiceSelectedFiltersSlice'
import FilterChips, { FilterChipData } from '../../FilterChips/FilterChips'
import { formatCurrencyNoDecimal } from '../../../rsmCoreComponents/utils/formatters'

let maxTranslation = 'default'
const getChipsData = (
  filters: InvoiceSelectedFiltersState,
  amountRangeFilterMax: number,
): FilterChipData<string>[] => {
  // Check if each filter has values and add it to the array.
  const chipsData = []

  if (filters.invoiceStatus?.length > 0) {
    const statuses = filters.invoiceStatus.map((i: string) => ({
      property: InvoiceSelectedFiltersConstants.invoiceStatus,
      value: i,
    }))
    chipsData.push(...statuses)
  }

  if (filters.invoiceDateRange.length > 0) {
    chipsData.push({
      property: InvoiceSelectedFiltersConstants.invoiceDates,
      value: filters.invoiceDateRange,
    })
  }

  // Special Handling for Currency Range
  if (filters.invoiceAmountFrom || filters.invoiceAmountTo) {
    const toValue =
      filters.invoiceAmountTo === amountRangeFilterMax
        ? maxTranslation
        : formatCurrencyNoDecimal(filters.invoiceAmountTo, filters.currency)
    chipsData.push({
      property: InvoiceSelectedFiltersConstants.invoiceAmountFrom,
      value: `${formatCurrencyNoDecimal(
        filters.invoiceAmountFrom,
        filters.currency,
      )} - ${toValue}`,
    })
  }

  return chipsData as FilterChipData<string>[]
}

interface InvoicesChipsProps {
  changeFilterAndSearch: (filterValue: string) => void
  clearCategoryFilter: (category: string) => void
  clearAllFilters: () => void
}

const InvoicesChips = ({
  changeFilterAndSearch,
  clearCategoryFilter,
  clearAllFilters,
}: InvoicesChipsProps) => {
  const { t } = useTranslation()
  const selectedFilters = useSelector(getInvoiceSelectedFilters)
  const amountRangeFilterMax = useSelector(getAmountRangeFilterMax)

  const [filterChipsData, setFilterChipsData] = useState<
    FilterChipData<string>[]
  >([])

  useEffect(() => {
    maxTranslation = t(`Invoicing.Max`)
    const chipsData = getChipsData(selectedFilters, amountRangeFilterMax)
    setFilterChipsData(chipsData)
  }, [selectedFilters])

  const handleDeleteChip = useCallback(
    (chipData: FilterChipData<string>) => {
      // Special Handling for Currency Range
      if (
        chipData.property === InvoiceSelectedFiltersConstants.invoiceAmountFrom
      ) {
        clearCategoryFilter(InvoiceSelectedFiltersConstants.invoiceAmounts)
        return
      }

      if (chipData.property === InvoiceSelectedFiltersConstants.invoiceDates) {
        clearCategoryFilter(InvoiceSelectedFiltersConstants.invoiceDates)
        return
      }

      changeFilterAndSearch(
        setFilterValue(chipData.property as string, chipData.value),
      )
    },
    [clearCategoryFilter, changeFilterAndSearch, setFilterValue],
  )

  return (
    <FilterChips<string>
      filtersSelected={filterChipsData}
      filterChipTranslationKeyPrefix="Invoicing.FilterGroups"
      onChipDeleted={handleDeleteChip}
      onClearAll={clearAllFilters}
      clearAllFocusQuerySelector="#invoices-filter-chips-clear-all-focus-target input"
    />
  )
}

export default InvoicesChips
