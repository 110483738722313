import { Box, Button, useTheme } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import processAdobePageView from '../../analytics/adobe/pageView'
import { AnalyticEvent, UserType } from '../../analytics/adobe/types'
import HomeInsightsSection from '../../components/Insights/HomeInsightsSection'
import ArticleHomeTilesWrapper from '../../components/Insights/HomeInsightsSection/ArticleHomeTilesWrapper'
import HomeSpotlightArticleSection from '../../components/Insights/HomeSpotlightArticleSection'
import MainContent from '../../components/layouts/MainContent'
import MainMenu from '../../components/layouts/MainMenu'
import SectionHeader from '../../components/layouts/SectionHeader'
import UserApps from '../../components/UserApp/UserApps'
import { REACT_APP_RSMUS_DOT_COM_LIVENESS_CHECK } from '../../envVariables'
import { useAITracking } from '../../rsmCoreComponents/hooks/useAITracking'
import { useDeviceType } from '../../rsmCoreComponents/hooks/useDeviceType'
import RsmTable from '../../rsmCoreComponents/components/table/RSMTable'
import { isFeatureFlagEnabled } from '../../rsmCoreComponents/utils/featureFlagUtils'
import { fetchAllEngagements } from '../../store/engagements/engagementSlice'
import { getCMSHealthInfo } from '../../store/serviceHealth/serviceHealthSlice'
import { getUserInfo } from '../../store/userInfo/userInfoSlice'
import { Styles } from '../../types'
import EngagementsTable from '../EngagementList/Table/EngagementsTable'

const pageTitle = 'Home Page'
const analyticEventType: AnalyticEvent = 'Navigation_Click'

async function checkRsmusIsDown(): Promise<boolean> {
  try {
    const url = REACT_APP_RSMUS_DOT_COM_LIVENESS_CHECK ?? undefined
    const currentTime = DateTime.now().toString()
    // if URL is undefined skip this check. Would be good if we could log this
    if (!url) {
      return false
      // TODO*** Log: "REACT_APP_RSMUS_DOT_COM_LIVENESS_CHECK azure config has not been set. Liveness check is not being made."
    }
    const response = await axios.get(`${url}?dt=${currentTime}`)

    if (response.status !== 200) {
      return true
    }
  } catch {
    return true
  }
  return false
}

const styles: Styles = {
  toolsSection: (theme) => ({
    padding: '2.5rem 1rem 4.1875rem',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up('tablet')]: {
      paddingBottom: '5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingRight: '2rem',
      paddingLeft: '2rem',
    },
    [theme.breakpoints.up('desktop')]: {
      paddingRight: '6.5rem',
      paddingLeft: '6.5rem',
    },
  }),
  recentlyAddedInsightsSection: (theme) => ({
    backgroundColor: theme.palette.primary.main,
    paddingTop: '1.6875rem',
    paddingRight: '1rem',
    paddingBottom: '1.25rem',
    paddingLeft: '1rem',
    [theme.breakpoints.up('tablet')]: {
      paddingTop: '2.1875rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingRight: '2rem',
      paddingBottom: '2rem',
      paddingLeft: '2rem',
    },
    [theme.breakpoints.up('desktop')]: {
      paddingRight: '6.5rem',
      paddingBottom: '2.6875rem',
      paddingLeft: '6.5rem',
    },
  }),
  viewAllInsightsButtonContainer: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.375rem',
    [theme.breakpoints.up('tablet')]: {
      marginTop: '0.8125rem',
    },
    [theme.breakpoints.up('desktop')]: {
      marginTop: '1.75rem',
    },
  }),
  engagementSection: (theme) => ({
    padding: '3.125rem 1rem 1.875rem',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.only('tablet')]: {
      paddingRight: '2rem',
      paddingLeft: '2rem',
    },
    [theme.breakpoints.up('desktop')]: {
      paddingRight: '6.5rem',
      paddingLeft: '6.5rem',
    },
  }),
  viewAllEngagementsButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2.5rem',
  },
  viewAllEngagementsButton: {
    width: '13.25rem',
    height: '3.25rem',
  },
}

const Home = () => {
  const { isMobile, isTablet, isDesktop } = useDeviceType()
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const { t } = useTranslation()
  const cmsIsHealthy = useSelector(getCMSHealthInfo).IsHealthy
  const userInfo = useSelector(getUserInfo)
  const [isCmsDown, setIsCmsDown] = useState<boolean>(false)
  const [isRsmusDown, setIsRsmusDown] = useState<boolean>(false)

  const userType: UserType = userInfo.isEmployee ? 'Internal' : 'External'

  const LoadEngagements = () => {
    dispatch(fetchAllEngagements({ isEmployee: userInfo.isEmployee }))
  }

  useAITracking(t('HomePage.ECPHomeTitle'), window.location.href)
  useEffect(() => {
    document.title = t('HomePage.ECPHomeTitle')

    if (userInfo.isEmployee !== undefined) {
      processAdobePageView(pageTitle, userType)
    }
  }, [userInfo, location.key])

  if (isFeatureFlagEnabled('HomePageEngagements')) {
    useEffect(() => {
      if (userInfo.isEmployee !== undefined) {
        LoadEngagements()
      }
    }, [userInfo.isEmployee])
  }

  useEffect(() => {
    // It's necessary because cmsIsHealthy could also be undefined
    setIsCmsDown(!cmsIsHealthy)
  }, [cmsIsHealthy])

  useEffect(() => {
    const fetchData = async () => {
      const checkIfRsmusIsDown = await checkRsmusIsDown()
      setIsRsmusDown(checkIfRsmusIsDown)
    }
    fetchData()
  }, [isRsmusDown])

  return (
    <>
      <MainMenu currentItem="home" />
      <MainContent>
        <Box
          component="section"
          sx={styles.toolsSection}
          data-testid="Sec_Home_Tools">
          <SectionHeader
            title={t('HomePage.ToolsHeader')}
            testId="Lbl_Home_Tools"
          />
          <UserApps />
        </Box>
        <HomeSpotlightArticleSection isUnavailable={isCmsDown || isRsmusDown} />{' '}
        {!isFeatureFlagEnabled('UserProfile') &&
          (isCmsDown || isRsmusDown ? null : (
            <Box
              component="section"
              sx={styles.recentlyAddedInsightsSection}
              data-testid="Sec_Home_Insights">
              <SectionHeader
                title={t('HomePage.InsightsHeader')}
                testId="Lbl_Home_Insights"
                alternateStyle
              />
              {isMobile && (
                <ArticleHomeTilesWrapper
                  totalTiles={2}
                  parentClassName="mx-[-26px]"
                  individualArticleClassName=""
                  defaultImageColor={theme.palette.primary.main}
                />
              )}

              {isTablet && (
                <ArticleHomeTilesWrapper
                  totalTiles={2}
                  parentClassName="flex flex-wrap mobile:mt-[-12px] tablet:mt-[0px] desktop:mt-[5px]"
                  individualArticleClassName="tablet:basis-1/2"
                  defaultImageColor={theme.palette.primary.main}
                />
              )}

              {isDesktop && (
                <ArticleHomeTilesWrapper
                  totalTiles={3}
                  parentClassName="flex flex-wrap mobile:mt-[-12px] tablet:mt-[0px] desktop:mt-[5px]"
                  individualArticleClassName="desktop:basis-1/3 mt-[15px]"
                  defaultImageColor={theme.palette.primary.main}
                />
              )}

              <Box sx={styles.viewAllInsightsButtonContainer}>
                <Button
                  sx={styles.viewAllInsightsButton}
                  variant="outlined"
                  color="secondary"
                  onClick={() => navigate('/insights')}
                  data-testid="Btn_Insights_ViewAll"
                  data-analytic-event={analyticEventType}>
                  {t('HomePage.ViewAllInsights')}
                </Button>
              </Box>
            </Box>
          ))}
        {/* **** section end ***** */}
        {isFeatureFlagEnabled('UserProfile') && <HomeInsightsSection />}
        {isFeatureFlagEnabled('HomePageEngagements') && (
          <Box
            component="section"
            sx={styles.engagementSection}
            data-testid="Sec_Home_Engagements">
            <SectionHeader
              title={t('HomePage.EngagementsHeader')}
              testId="Lbl_Home_Engagements"
            />
            <RsmTable testId="Tbl_Home_Engagements">
              <EngagementsTable maxRecords={10} />
            </RsmTable>
            <Box sx={styles.viewAllEngagementsButtonContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/engagements')}
                data-testid="Btn_Engagements_ViewAll">
                {t('HomePage.ViewAllEngagements')}
              </Button>
            </Box>
          </Box>
        )}
      </MainContent>
    </>
  )
}

export default Home
