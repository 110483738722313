import * as React from 'react'

import { CreditCardIcon } from '../../icons'
import CreditVisaIcon from '../../icons/CreditVisaIcon/CreditVisaIcon'
import CreditMastercardIcon from '../../icons/CreditMastercardIcon/CreditMastercardIcon'
import CreditDiscoverIcon from '../../icons/CreditDiscoverIcon/CreditDiscoverIcon'
import CreditAmexIcon from '../../icons/CreditAmexIcon/CreditAmexIcon'
import CreditDinersIcon from '../../icons/CreditDinersIcon/CreditDinersIcon'
import CreditJcbIcon from '../../icons/CreditJcbIcon/CreditJcbIcon'
import CreditCupIcon from '../../icons/CreditCupIcon/CreditCupIcon'

export interface CreditCardLogoProps {
  cardType?: string
  width?: number
  height?: number
}

const defaultProps: CreditCardLogoProps = {
  cardType: undefined,
  width: 26,
  height: 21.28,
}

const CreditCardLogo = ({ cardType, width, height }: CreditCardLogoProps) => {
  switch (cardType) {
    case 'VISA':
      return <CreditVisaIcon width={width} height={height} />
    case 'MC':
      return <CreditMastercardIcon width={width} height={height} />
    case 'DISC':
      return (
        <CreditDiscoverIcon width={width} height={height} id="discover-icon" />
      )
    case 'AMEX':
      return <CreditAmexIcon width={width} height={height} />
    case 'DINERS':
      return <CreditDinersIcon width={width} height={height} id="diners-icon" />
    case 'JCB':
      return <CreditJcbIcon width={width} height={height} id="jcb-icon" />
    case 'CUP':
      return <CreditCupIcon width={width} height={height} id="cup-icon" />
    default:
      return <CreditCardIcon width={width} height={height} />
  }
}

CreditCardLogo.defaultProps = defaultProps

export default CreditCardLogo
