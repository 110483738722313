import React, { useState, useRef, useEffect } from 'react'
import { Box, Button } from '@mui/material'
import { ArrowDownIcon, ArrowUpIcon } from '../icons'

interface SortableHeaderProps {
  columnName: string
  currentSortColumn: string
  sortDirection: string | undefined
  isMounted?: boolean // used to determine whether to set focus to a column header during remount or browser refresh.  needs to be passed from component that does not re-render on data refresh
  handleSort: (column: string, direction: 'asc' | 'desc') => void // Update handleSort function signature
}

const defaultProps = {
  isMounted: false,
}

const SortableHeader: React.FC<SortableHeaderProps> = ({
  columnName,
  currentSortColumn,
  sortDirection,
  isMounted,
  handleSort,
  children,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  // Function to toggle sort direction
  const toggleSortDirection = () => {
    handleSort(columnName, sortDirection === 'asc' ? 'desc' : 'asc') // Pass both column name and direction to handleSort
  }

  const handleMouseEnter = () => {
    if (columnName !== currentSortColumn) {
      setIsHovered(true)
    }
  }

  useEffect(() => {
    // If the column is sorted, focus on the button
    if (isMounted && currentSortColumn === columnName && buttonRef.current) {
      buttonRef.current.focus()
    }
  }, [])

  // Define a mapping object for column names and their accessibility text
  const columnAccessibilityText: { [key: string]: string } = {
    GrossInvoiceAmount: 'Original Amount',
    Method: 'Payment Type',
    Initiated: 'Payment Date',
  }

  // Set default accessibility text based on column name
  let sortingAccessibilityText = columnAccessibilityText[columnName]
    ? `${columnAccessibilityText[columnName]}`
    : `${columnName}`

  // Update accessibility text if the column is currently sorted
  if (currentSortColumn === columnName) {
    const sortedColumnText =
      columnAccessibilityText[currentSortColumn] || columnName
    sortingAccessibilityText =
      sortDirection === 'asc'
        ? `Sorted ascending ${sortedColumnText}`
        : `Sorted descending ${sortedColumnText}`
  }

  return (
    <Button
      ref={buttonRef}
      disableRipple
      aria-label={sortingAccessibilityText}
      sx={{
        fontSize: '14px',
        fontWeight: '700',
        width: '100%',
        justifyContent: 'flex-start',
        padding: '6px 0px',
        cursor: 'pointer',
      }}
      onKeyDown={(event) => {
        if (event.key === ' ' || event.key === 'Enter') {
          event.preventDefault() // Prevent default behavior for space and enter key
          toggleSortDirection()
        }
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setIsHovered(false)}
      onClick={toggleSortDirection} // Call toggleSortDirection when clicked
      className="sortable-header">
      {children}
      {(currentSortColumn === columnName || isHovered) && (
        <Box>
          {currentSortColumn === columnName &&
            !isHovered &&
            (sortDirection === 'asc' ? <ArrowUpIcon /> : <ArrowDownIcon />)}
          {isHovered && <ArrowDownIcon />}
        </Box>
      )}
    </Button>
  )
}

SortableHeader.defaultProps = defaultProps

export default SortableHeader
