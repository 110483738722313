import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { getUserInfo } from '../../store/userInfo/userInfoSlice'
import useTracking from './useTracking'

const TrackingManager = () => {
  const [dateConsentChanged, setDateConsentChanged] = useState<Date | null>(
    null,
  )

  const userInfo = useSelector(getUserInfo)
  useTracking(userInfo, dateConsentChanged)

  // Only load the OneTrust script when the user is logged in to prevent the banner from flashing before the login page is shown.
  useEffect(() => {
    if (userInfo?.userId) {
      window.addEventListener('OneTrustGroupsUpdated', () => {
        setDateConsentChanged(new Date())
      })

      const addOneTrustScript = () => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = `https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`
        script.setAttribute('data-language', 'en')
        script.setAttribute(
          'data-domain-script',
          process.env.REACT_APP_ONETRUST_DOMAIN_SCRIPT || '',
        )
        document.head.appendChild(script)
      }
      addOneTrustScript()
    }
  }, [userInfo])

  return null
}

export default TrackingManager
