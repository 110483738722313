/* eslint-disable react/require-default-props */
import { t } from 'i18next'
import React, { Component, ErrorInfo, ReactNode } from 'react'

interface Props {
  children: ReactNode
  noErrorMessage?: boolean
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    const { hasError } = this.state
    const { noErrorMessage, children } = this.props
    if (hasError) {
      if (noErrorMessage) return null
      return <h1>{t('ErrorBoundary.DefaultErrorMessage')}</h1>
    }

    return children
  }
}

export default ErrorBoundary
