import { Box, Toolbar } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import { hidePopover, setPopoverContent } from '../../../store/form/formSlice'
import { Styles } from '../../../types'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { RsmLogo } from '../../media'
import RsmDialog from '../../overlay/RsmDialog'
import { popoverComponentFactory } from '../../Profile/ProfileModal/ProfileModalContainer'
// import Modal from '../../Modal/Modal'
import DesktopMenu from '../DesktopMenu'
import HamburgerMenu from '../HamburgerMenu'
import SkipToMainContent from '../SkipToMainContent'
import WelcomeBar from '../WelcomeBar'
import tokens from '../../../styles/tokens.json'

const styles: Styles = {
  toolbar: () => ({
    display: 'flex',
    height: '5.4375rem',
    alignContent: 'center',
    alignItems: 'center',
  }),
  mobileDialogModal: () => ({
    '& .MuiDialog-paper': {
      maxWidth: '20rem',
    },
  }),
}

type CombinedMenusProps = {
  currentItem?: string
  bottomBorder?: boolean
}

const defaultProps = {
  currentItem: '',
  bottomBorder: true,
}

const CombinedMenus = ({ currentItem, bottomBorder }: CombinedMenusProps) => {
  const { isMobile, isTablet } = useDeviceType()

  const computedStyles = {
    toolbarContainer: useCallback(
      (theme) => ({
        height: 87,
        paddingLeft: '1rem',
        paddingRight: '1rem',
        color: '#515356',
        borderBottomWidth: bottomBorder ? 2 : 0,
        borderBottomColor: tokens.colors.backgroundGray,
        [theme.breakpoints.only('tablet')]: {
          paddingLeft: '2rem',
          paddingRight: '2rem',
        },
        [theme.breakpoints.up('desktop')]: {
          paddingLeft: '6.5rem',
          paddingRight: '6.5rem',
        },
      }),
      [bottomBorder],
    ),
  }

  const {
    popover: { messageType },
  } = useAppSelector((state) => state)

  const dispatch = useAppDispatch()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = useState<boolean>(messageType !== undefined)

  const handleClose = () => {
    setOpen(false)
    dispatch(setPopoverContent(undefined))
  }

  useEffect(() => {
    if (isMobile) {
      const notify = messageType !== undefined && messageType !== null
      setOpen(notify)
      dispatch(hidePopover())
    } else {
      setOpen(false)
    }
  }, [messageType, isMobile])

  return (
    <div>
      <SkipToMainContent />
      <WelcomeBar />
      <Box sx={computedStyles.toolbarContainer}>
        <Toolbar disableGutters sx={styles.toolbar}>
          <Box flexGrow={1}>
            <RsmLogo />
          </Box>

          {isMobile || isTablet ? (
            <HamburgerMenu currentItem={currentItem} />
          ) : (
            <DesktopMenu currentItem={currentItem} />
          )}
        </Toolbar>
      </Box>
      {isMobile && (
        <RsmDialog
          isOpen={open}
          closeHandler={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          dialogCustomProps={{
            sx: styles.mobileDialogModal,
          }}>
          <Box sx={{ p: 1 }}>{popoverComponentFactory(messageType)}</Box>
        </RsmDialog>
      )}
    </div>
  )
}

CombinedMenus.defaultProps = defaultProps

export default CombinedMenus
