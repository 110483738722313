/* eslint-disable @typescript-eslint/dot-notation */
import { Box, Button, GridSpacing } from '@mui/material'
import { ResponsiveStyleValue } from '@mui/system/styleFunctionSx'
import { UserContactPreferenceRequestViewModel } from '@rsmus/ecp-userservice'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DynamicForm from '../../../../rsmCoreComponents/components/DynamicForm/FormControls/DynamicForm'
import FormTextArea from '../../../../rsmCoreComponents/components/DynamicForm/FormControls/FormTextArea/FormTextArea'
import FormTextField from '../../../../rsmCoreComponents/components/DynamicForm/FormControls/FormTextField/FormTextField'
import {
  FormExclusions,
  FormValues,
} from '../../../../rsmCoreComponents/components/DynamicForm/models'
import { setContactPreferenceFormState } from '../../../../store/profile/profilePageSlice'
import { getSelectedContactMethod } from '../../../../store/profile/profileFormSlice'
import { getUserInfo } from '../../../../store/userInfo/userInfoSlice'
import {
  useSetUserContactPreferenceDetailsMutation,
  useGetUserContactPreferenceDetailsQuery,
} from '../../../../store/userService'
import ContactMethodRadioGroup from './ContactMethods/ContactMethodRadioGroup'
import NotificationFrequencySelect from './NotificationFrequencies/NotificationFrequencySelect'
import TimeZoneSelect from './TimeZones/TimeZoneSelect'

export interface DefaultFormValues {
  email: number
  timeZone: number
  mobileFrequency: number
  emailFrequency: number
}

const defaultFormValues: DefaultFormValues = {
  email: 1,
  timeZone: 3,
  mobileFrequency: 8,
  emailFrequency: 4,
}

export interface ContactPreferenceFormProps {
  excluded?: FormExclusions
  formRef?: any
  formType?: string
  spacing?: ResponsiveStyleValue<GridSpacing>
}

const defaultProps = {
  excluded: {},
  formRef: undefined,
  formType: 'Edit',
  spacing: { xs: '0.5rem', sm: '1rem', md: '2rem', lg: '3rem' },
}

const ContactPreferenceForm = ({
  excluded,
  formRef,
  formType,
  spacing,
}: ContactPreferenceFormProps) => {
  const { t } = useTranslation()
  const userInfo = useSelector(getUserInfo)
  const { data } = useGetUserContactPreferenceDetailsQuery()
  const preferedContactMethodState: string = useSelector(
    getSelectedContactMethod,
  )
  const clientBusinessNameValue = userInfo.isEmployee ? 'RSMUS' : 'RSMUS Client'

  const dispatch = useDispatch()
  const [setContactPreferenceDetails] =
    useSetUserContactPreferenceDetailsMutation()

  const formValues = {
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    businessEmail: userInfo.emailAddress,
    businessPhone: data?.data.businessPhone,
    clientBusinessName: clientBusinessNameValue,
    preferredContactMethod:
      data?.data.preferredContactMethod?.id ?? defaultFormValues.email,
    engagementActivityNotificationFrequency:
      data?.data.engagementActivityNotificationFrequency?.id ??
      defaultFormValues.emailFrequency,
    insightsActivityNotificationFrequency:
      data?.data.insightsActivityNotificationFrequency?.id ??
      defaultFormValues.emailFrequency,
    timeZone: data?.data.timeZone?.id ?? defaultFormValues.timeZone,
    visited: data?.data.visited,
  }

  const handleSubmitRequest = async (values: FormValues) => {
    const request: UserContactPreferenceRequestViewModel = {
      businessPhone: values['businessPhone'],
      engagementActivityNotificationFrequencyId:
        values['engagementActivityNotificationFrequency'],
      insightsActivityNotificationFrequencyId:
        values['insightsActivityNotificationFrequency'],
      preferredContactMethodId: values['preferredContactMethod'],
      timeZoneId: values['timeZone'],
      visited: true,
      clientBusinessName: '',
    }

    await setContactPreferenceDetails(request)
      .unwrap()
      .then(
        () => {
          dispatch(
            setContactPreferenceFormState({
              isViewOnlyMode: true,
              isSubmissionSuccessful: formType === 'Edit',
              isSubmissionFailed: undefined,
            }),
          )
        },
        () => {
          dispatch(
            setContactPreferenceFormState({
              isViewOnlyMode: true,
              isSubmissionSuccessful: undefined,
              isSubmissionFailed: true,
            }),
          )
        },
      )
  }

  const handleCancel = () => {
    dispatch(
      setContactPreferenceFormState({
        isViewOnlyMode: true,
        isSubmissionSuccessful: undefined,
        isSubmissionFailed: undefined,
      }),
    )
  }

  const footerComponent = () => (
    <Box>
      <span>
        <Button
          sx={{ my: 5, mx: 1 }}
          color="primary"
          variant="contained"
          type="submit"
          aria-label={t('UserProfile.ContactPreferenceForm.Submit')}
          data-testid="Btn_Profile_ContactPreferenceSubmit">
          {formType === 'Edit'
            ? t('UserProfile.ContactPreferenceForm.Save')
            : t('UserProfile.ContactPreferenceForm.Submit')}
        </Button>
      </span>
      <span>
        <Button
          sx={{ my: 5, mx: 3 }}
          color="primary"
          variant="outlined"
          aria-label={t('UserProfile.ContactPreferenceForm.Cancel')}
          onClick={handleCancel}
          data-testid="Btn_Profile_ContactPreferenceCancel">
          {t('UserProfile.ContactPreferenceForm.Cancel')}
        </Button>
      </span>
    </Box>
  )

  return (
    <DynamicForm
      formRef={formRef}
      containerLayout={{
        container: true,
        justifyContent: 'center',
        alignItems: 'center',
        spacing,
      }}
      exclusions={excluded}
      definition={{
        firstName: {
          label: 'UserProfile.ContactPreferenceForm.FirstName',
          ariaLabel: 'UserProfile.ContactPreferenceForm.FirstName',
          readonly: true,
          component: FormTextField,
          itemLayout: {
            item: true,
            lg: 6,
            md: 6,
            xs: 12,
          },
        },
        lastName: {
          label: 'UserProfile.ContactPreferenceForm.LastName',
          ariaLabel: 'UserProfile.ContactPreferenceForm.LastName',
          readonly: true,
          component: FormTextField,
          itemLayout: {
            item: true,
            lg: 6,
            md: 6,
            xs: 12,
          },
        },
        clientBusinessName: {
          label: 'UserProfile.ContactPreferenceForm.ClientOrBusinessName',
          ariaLabel: 'UserProfile.ContactPreferenceForm.ClientOrBusinessName',
          readonly: true,
          component: FormTextArea,
          rows: 1,
          cols: 1,
          itemLayout: {
            item: true,
            lg: 6,
            md: 6,
            xs: 12,
          },
        },
        timeZone: {
          label: 'UserProfile.ContactPreferenceForm.RegionOrTimeZone',
          ariaLabel: 'UserProfile.ContactPreferenceForm.RegionOrTimeZone',
          component: TimeZoneSelect,
          itemLayout: {
            item: true,
            lg: 6,
            md: 6,
            xs: 12,
          },
        },
        businessPhone: {
          label: 'UserProfile.ContactPreferenceForm.BusinessPhoneNumber',
          ariaLabel: 'UserProfile.ContactPreferenceForm.BusinessPhoneNumber',
          required: preferedContactMethodState !== '1',
          maxLength: 20,
          nullable: preferedContactMethodState !== '1',
          validator: 'Phone',
          component: FormTextField,
          itemLayout: {
            item: true,
            lg: 6,
            md: 6,
            xs: 12,
          },
        },
        businessEmail: {
          label: 'UserProfile.ContactPreferenceForm.BusinessEmailAddress',
          ariaLabel: 'UserProfile.ContactPreferenceForm.BusinessEmailAddress',
          readonly: true,
          component: FormTextField,
          itemLayout: {
            item: true,
            lg: 6,
            md: 6,
            xs: 12,
          },
        },
        preferredContactMethod: {
          label: 'UserProfile.ContactPreferenceForm.PreferredContactMethod',
          ariaLabel: 'UserProfile.ContactPreferenceForm.PreferredContactMethod',
          component: ContactMethodRadioGroup,
          itemLayout: {
            item: true,
            lg: 12,
            md: 12,
            xs: 12,
          },
        },
        engagementActivityNotificationFrequency: {
          label:
            'UserProfile.ContactPreferenceForm.EngagementActivityNotificationFrequency',
          ariaLabel:
            'UserProfile.ContactPreferenceForm.EngagementActivityNotificationFrequency',
          component: NotificationFrequencySelect,
          itemLayout: {
            item: true,
            lg: 6,
            md: 6,
            xs: 12,
          },
        },
        insightsActivityNotificationFrequency: {
          label:
            'UserProfile.ContactPreferenceForm.InsightsActivityNotificationFrequency',
          ariaLabel:
            'UserProfile.ContactPreferenceForm.InsightsActivityNotificationFrequency',
          component: NotificationFrequencySelect,
          itemLayout: {
            item: true,
            lg: 6,
            md: 6,
            xs: 12,
          },
        },
      }}
      initialValues={formValues}
      handleSubmit={(values: FormValues) => {
        handleSubmitRequest({ ...values })
      }}
      footerComponent={excluded?.footer ? null : footerComponent()}
      rootTestId="Sec_Profile_ContactForm"
    />
  )
}

ContactPreferenceForm.defaultProps = defaultProps

export default ContactPreferenceForm
