import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { enableMapSet } from 'immer'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'

import docRequestsReducer, {
  docRequestsSlice,
} from './docRequests/docRequestsSlice'
import cmsDataReducer from './cmsService'
import userDataReducer from './userService'

import userInfoReducer, { userInfoSlice } from './userInfo/userInfoSlice'
import engagmentsReducer, {
  engagementsSlice,
} from './engagements/engagementSlice'
import configurationsReducer, {
  configurationsSlice,
} from './configuration/configurationSlice'
import faqsReducer, { faqsSlice } from './faqs/faqsSlice'
import documentReducer, { documentSlice } from './document/documentSlice'
import highContrastReducer, {
  userSettingsSlice,
} from './userSettings/userSettingsSlice'
import serviceHealthSliceReducer, {
  serviceHealthSlice,
} from './serviceHealth/serviceHealthSlice'
import insightsSliceReducer, { insightsSlice } from './insights/insightsSlice'
import { popoverSlice } from './form/formSlice'
import { profileModalSlice } from './profile/profileModalSlice'
import { profilePageSlice } from './profile/profilePageSlice'
import { profileFormSlice } from './profile/profileFormSlice'
import { invoiceSelectedFiltersSlice } from './invoices/invoiceSelectedFiltersSlice'
import { invoiceSelectedInvoicesSlice } from './invoices/invoiceSelectedInvoicesSlice'
import { paymentInfoSlice } from './invoices/paymentInfoSlice'
import { paymentSelectedFiltersSlice } from './invoices/paymentSelectedFiltersSlice'
import { paymentSelectedPaymentsSlice } from './invoices/paymentSelectedPaymentsSlice'
import invoiceSearchService from './invoices/invoiceSearchService'
import paymentSearchService from './invoices/paymentSearchService'
import notificationReducer, {
  notificationSlice,
} from './notification/notificationSlice'
import { userAppsSlice } from './userApps/userAppsSlice'
import { spoltighArticleSlice } from './spotlightArticle/spotlightArticleSlice'
import { supportSlice } from './support/supportSlice'
import deepLinkReducer, { deepLinkSlice } from './deepLink/deepLinkSlice'

enableMapSet()

const persistConfig = {
  key: 'root',
  version: 0,
  storage,
  whitelist: [userSettingsSlice.name],
}

const rootReducer = combineReducers({
  [userSettingsSlice.name]: highContrastReducer,
  [docRequestsSlice.name]: docRequestsReducer,
  [configurationsSlice.name]: configurationsReducer,
  [userInfoSlice.name]: userInfoReducer,
  [engagementsSlice.name]: engagmentsReducer,
  [faqsSlice.name]: faqsReducer,
  [documentSlice.name]: documentReducer,
  [serviceHealthSlice.name]: serviceHealthSliceReducer,
  [insightsSlice.name]: insightsSliceReducer,
  [cmsDataReducer.reducerPath]: cmsDataReducer.reducer,
  [userDataReducer.reducerPath]: userDataReducer.reducer,
  [profileModalSlice.name]: profileModalSlice.reducer,
  [popoverSlice.name]: popoverSlice.reducer,
  [profilePageSlice.name]: profilePageSlice.reducer,
  [profileFormSlice.name]: profileFormSlice.reducer,
  [invoiceSelectedFiltersSlice.name]: invoiceSelectedFiltersSlice.reducer,
  [invoiceSearchService.reducerPath]: invoiceSearchService.reducer,
  [paymentSearchService.reducerPath]: paymentSearchService.reducer,
  [notificationSlice.name]: notificationReducer,
  [invoiceSelectedInvoicesSlice.name]: invoiceSelectedInvoicesSlice.reducer,
  [paymentInfoSlice.name]: paymentInfoSlice.reducer,
  [paymentSelectedFiltersSlice.name]: paymentSelectedFiltersSlice.reducer,
  [paymentSelectedPaymentsSlice.name]: paymentSelectedPaymentsSlice.reducer,
  [userAppsSlice.name]: userAppsSlice.reducer,
  [spoltighArticleSlice.name]: spoltighArticleSlice.reducer,
  [supportSlice.name]: supportSlice.reducer,
  [deepLinkSlice.name]: deepLinkReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      cmsDataReducer.middleware,
      userDataReducer.middleware,
      invoiceSearchService.middleware,
      paymentSearchService.middleware,
    ]),
})

setupListeners(store.dispatch)

export const persistor = persistStore(store)
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
