import React from 'react'

export interface ToastAlertProps {
  classNames?: string
  color?: string
  size?: number
}

const defaultProps = {
  classNames: '',
  color: '',
  size: 24,
}

const ToastAlertIcon = ({ size = 24, color, classNames }: ToastAlertProps) => (
  <svg
    className={classNames}
    height={size}
    width={size}
    viewBox="0 0 54 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M53.0417 40.1042L30.8542 2.1875C29.0833 -0.729167 24.8125 -0.729167 23.1458 2.1875L0.854168 40.1042C-0.812498 43.0208 1.27083 46.6667 4.70833 46.6667H49.1875C52.625 46.6667 54.7083 43.0208 53.0417 40.1042ZM24.5 14.1667C24.5 12.8125 25.5417 11.6667 27 11.6667C28.3542 11.6667 29.5 12.8125 29.5 14.1667V27.5C29.5 28.9583 28.3542 30 27 30C25.75 30 24.5 28.9583 24.5 27.5V14.1667ZM27 40C25.125 40 23.6667 38.5417 23.6667 36.7708C23.6667 35 25.125 33.5417 27 33.5417C28.7708 33.5417 30.2292 35 30.2292 36.7708C30.2292 38.5417 28.7708 40 27 40Z"
      fill="#515356"
    />
    fill={color}
  </svg>
)
ToastAlertIcon.defaultProps = defaultProps
export default ToastAlertIcon
