import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { InvoicePayerViewModel } from '@rsmus/ecp-financeservice'
import type { RootState } from '..'
import { PaymentMethodType } from '../../types'

// Define Types used int he slice
export declare type SelectedAccountTypeState =
  | 'Unavailable'
  | 'Unselected'
  | 'New'
  | 'Existing'

// Define a type for the slice state
interface PaymentInfoState {
  selectedPaymentMethod: PaymentMethodType
  selectedPayer: InvoicePayerViewModel | undefined
  selectedBankAccount: number | undefined
  selectedCreditCard: number | undefined
  newPaymentMethod: boolean
  accountSelectedType: SelectedAccountTypeState
  doNotSave: boolean
  paymentMethodError: boolean
  payeraccounts: number | undefined
  confirmPaymentError: boolean
  handleClicked: boolean
}

// Define the initial state using that type
const initialState: PaymentInfoState = {
  selectedPaymentMethod: undefined,
  selectedPayer: undefined,
  selectedBankAccount: undefined,
  selectedCreditCard: undefined,
  newPaymentMethod: false,
  accountSelectedType: 'Unselected',
  doNotSave: false,
  paymentMethodError: false,
  payeraccounts: undefined,
  confirmPaymentError: false,
  handleClicked: false,
}

export const paymentInfoSlice = createSlice({
  name: 'paymentInfo',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    resetAll: (state) => {
      state.selectedPaymentMethod = undefined
      state.selectedPayer = undefined
      state.selectedBankAccount = undefined
      state.selectedCreditCard = undefined
      state.newPaymentMethod = false
      state.accountSelectedType = 'Unselected'
      state.doNotSave = false
      state.paymentMethodError = false
      state.payeraccounts = undefined
      state.confirmPaymentError = false
      state.handleClicked = false
    },
    setSelectedPaymentMethod: (
      state,
      action: PayloadAction<PaymentMethodType>,
    ) => {
      state.selectedPaymentMethod = action.payload
    },
    setSelectedPayer: (
      state,
      action: PayloadAction<InvoicePayerViewModel | undefined>,
    ) => {
      state.selectedPayer = action.payload
    },
    setSelectedBankAccount: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.selectedBankAccount = action.payload
    },
    setSelectedCreditCard: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.selectedCreditCard = action.payload
    },
    setNewPaymentMethod: (state, action: PayloadAction<boolean>) => {
      state.newPaymentMethod = action.payload
    },
    setAccountSelectedType: (
      state,
      action: PayloadAction<SelectedAccountTypeState>,
    ) => {
      state.accountSelectedType = action.payload
    },
    setDoNotSave: (state, action: PayloadAction<boolean>) => {
      state.doNotSave = action.payload
    },
    setPaymentMethodError: (state, action: PayloadAction<boolean>) => {
      state.paymentMethodError = action.payload
    },
    setpayeraccounts: (state, action: PayloadAction<number | undefined>) => {
      state.payeraccounts = action.payload
    },
    setConfirmPaymentError: (state, action: PayloadAction<boolean>) => {
      state.confirmPaymentError = action.payload
    },
    sethandleClicked: (state, action: PayloadAction<boolean>) => {
      state.handleClicked = action.payload
    },
  },
})

export const { resetAll } = paymentInfoSlice.actions

export const { setSelectedPaymentMethod } = paymentInfoSlice.actions
export const getSelectedPaymentMethod = (state: RootState) =>
  state.paymentInfo.selectedPaymentMethod

export const { setSelectedPayer } = paymentInfoSlice.actions
export const getSelectedPayer = (state: RootState) =>
  state.paymentInfo.selectedPayer

export const { setSelectedBankAccount } = paymentInfoSlice.actions
export const getSelectedBankAccount = (state: RootState) =>
  state.paymentInfo.selectedBankAccount

export const { setSelectedCreditCard } = paymentInfoSlice.actions
export const getSelectedCreditCard = (state: RootState) =>
  state.paymentInfo.selectedCreditCard

export const { setNewPaymentMethod } = paymentInfoSlice.actions
export const getNewPaymentMethod = (state: RootState) =>
  state.paymentInfo.newPaymentMethod

export const { setAccountSelectedType } = paymentInfoSlice.actions
export const getAccountSelectedType = (state: RootState) =>
  state.paymentInfo.accountSelectedType

export const { setDoNotSave } = paymentInfoSlice.actions
export const getDoNotSave = (state: RootState) => state.paymentInfo.doNotSave

export const { setPaymentMethodError } = paymentInfoSlice.actions
export const getPaymentMethodError = (state: RootState) =>
  state.paymentInfo.paymentMethodError

export const { setpayeraccounts } = paymentInfoSlice.actions
export const getpayeraccounts = (state: RootState) =>
  state.paymentInfo.payeraccounts

export const { setConfirmPaymentError } = paymentInfoSlice.actions
export const getConfirmPaymentError = (state: RootState) =>
  state.paymentInfo.confirmPaymentError

export const { sethandleClicked } = paymentInfoSlice.actions
export const gethandleClicked = (state: RootState) =>
  state.paymentInfo.handleClicked
