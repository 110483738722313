import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'usehooks-ts'
import { useOutlet } from 'react-router-dom'
import SearchLayout from '../../layouts/SearchLayout'
import InvoicesActionBar from './InvoicesActionBar'
import PaymentHistoryResults from './PaymentHistoryResults'
import InvoicesSearch from './InvoicesSearch'
import usePaymentsSearch from './usePaymentsSearch'
import PaymentFilters from './PaymentFilters'
import PaymentChips from './PaymentChips'
import { RsmPaginationPageSizes } from '../../../rsmCoreComponents/components/RsmPagination/RsmPagination'
import {
  setFilterValue,
  PaymentSelectedFiltersConstants,
} from '../../../store/invoices/paymentSelectedFiltersSlice'
import { INVOICE_FILTER_STATUS_PAID } from '../../../utils/constants/constants'

const PaymentHistoryLayout = () => {
  const { t } = useTranslation()
  const outlet = useOutlet()
  const {
    changeFilterAndSearch,
    clearCategoryFilter,
    clearFilters,
    filterIsError,
    filterIsSuccess,
    filterData,
    paymentsData,
    paymentsIsError,
    paymentsIsLoading,
    requestSearch,
    changeSearchFilterAndSearch,
    setPaymentPageSize,
    toPage,
    pageInfo,
    SortPaymentHistoryData,
  } = usePaymentsSearch()

  const handlePageChange = (
    pageNumber: number,
    pageSize: RsmPaginationPageSizes,
  ) => {
    setPaymentPageSize(pageSize)
    toPage(pageNumber)
  }

  const [filter, setFilter] = useState<string>('')
  const [sortColumn, setSortColumn] = useState<string>('Initiated') // Initialize sortColumn state
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc') // Initialize sortDirection state
  const [isMounted, setIsMounted] = useState<boolean>(false) // Initialize if layout component is mounted
  const debouncedFilter = useDebounce<string>(filter, 500)

  // Define a function to handle sorting of invoices based on provided parameters
  const handleSorting = (sortBy: string, isAscending: 'asc' | 'desc') => {
    setSortDirection(isAscending)
    setSortColumn(sortBy)
    SortPaymentHistoryData(sortBy, isAscending)
    setIsMounted(true)
    toPage(1)
  }
  // Loading payments based on saved state
  // DO NOT use requestSearch anywhere else.
  useEffect(() => {
    changeFilterAndSearch(
      setFilterValue(
        PaymentSelectedFiltersConstants.paymentStatus,
        INVOICE_FILTER_STATUS_PAID,
      ),
    ) // sets default search filter for status to paid
    requestSearch()
  }, [])

  useEffect(
    () => () => {
      changeFilterAndSearch(
        setFilterValue(
          PaymentSelectedFiltersConstants.paymentStatus,
          INVOICE_FILTER_STATUS_PAID,
        ),
      ) // removes default filter on component unmount
    },
    [],
  )

  useEffect(() => {
    changeSearchFilterAndSearch(debouncedFilter)
  }, [debouncedFilter])

  return (
    outlet || (
      <SearchLayout
        title={t('Invoicing.Payments')}
        drawerDialogSlot={
          <PaymentFilters
            filterData={filterData}
            isFilterError={filterIsError}
            isFilterSuccess={filterIsSuccess}
            changeFilterAndSearch={changeFilterAndSearch}
            clearCategoryFilter={clearCategoryFilter}
          />
        }
        dialogSecondaryButtonClickHandler={clearFilters}
        searchSlot={<InvoicesSearch setFilter={setFilter} />}
        // removing currency dropdown for now
        // but leaving code in place so we can uncomment when ready to implement
        // searchAuxSlot={
        //   <CurrencyFilter
        //     changeFilterAndSearch={changeFilterAndSearch}
        //     selectedFilterConstant={PaymentSelectedFiltersConstants.currency}
        //     setFilterValue={setFilterValue}
        //     getSelectedFilters={getPaymentSelectedFilters}
        //   />
        // }
        chipsSlot={
          <PaymentChips
            changeFilterAndSearch={changeFilterAndSearch}
            clearCategoryFilter={clearCategoryFilter}
            clearAllFilters={clearFilters}
          />
        }
        footerSlot={<InvoicesActionBar />}>
        <PaymentHistoryResults
          pageNumber={pageInfo.pageNumber}
          pageSize={pageInfo.pageSize}
          paymentsData={paymentsData}
          isPaymentsError={paymentsIsError}
          isPaymentsLoading={paymentsIsLoading}
          onPageChange={handlePageChange}
          onSorting={handleSorting} // Pass down handleSorting
          sortColumn={sortColumn} // Pass down sortColumn
          sortDirection={sortDirection} // Pass down sortDirection
          isMounted={isMounted} // Pass down if layout component is mounted
        />
      </SearchLayout>
    )
  )
}

export default PaymentHistoryLayout
