import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SubMenu from '../layouts/SubMenu'
import NavListItem from '../Navigation/NavListItem'
import { isCemFeatureEnabled } from '../../rsmCoreComponents/utils/featureFlagUtils'
import {
  CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT,
  CEM_FEATURE_PAYMENT_CLIENT_ADMIN,
} from '../../utils/constants/constants'
import { getCemFeatures } from '../../store/userInfo/userInfoSlice'

type InvoicingMenuProps = {
  currentItem?: string
}

const defaultProps = {
  currentItem: '',
}

const InvoicingMenu = ({ currentItem = '' }: InvoicingMenuProps) => {
  const { t } = useTranslation()
  const cemFeatures = useSelector(getCemFeatures)

  const isClientAdmin = useMemo(
    () =>
      isCemFeatureEnabled(CEM_FEATURE_PAYMENT_CLIENT_ADMIN, cemFeatures, 'any'),
    [cemFeatures],
  )

  const isAcountManager = useMemo(
    () =>
      isCemFeatureEnabled(
        CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT,
        cemFeatures,
        'any',
      ),
    [cemFeatures],
  )

  return (
    <SubMenu currentItem={currentItem} title={t('Navigation.Invoices')}>
      {isClientAdmin && (
        <NavListItem
          to="/invoicing/dashboard"
          value="dashboard"
          testId="Lnk_Navigation_Dashboard">
          {t('Navigation.Dashboard')}
        </NavListItem>
      )}

      <NavListItem
        to="/invoicing/invoices"
        value="invoices"
        testId="Lnk_Navigation_Invoices">
        {t('Navigation.Invoices')}
      </NavListItem>

      <NavListItem
        to="/invoicing/payments"
        value="payments"
        testId="Lnk_Navigation_Payments">
        {t('Navigation.Payments')}
      </NavListItem>

      {isAcountManager && (
        <NavListItem
          to="/invoicing/account-management"
          value="account-management"
          testId="Lnk_Navigation_AccountManagement">
          {t('Navigation.AccountManagement')}
        </NavListItem>
      )}
    </SubMenu>
  )
}

InvoicingMenu.defaultProps = defaultProps

export default InvoicingMenu
