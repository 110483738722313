/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/dot-notation */
import React from 'react'

import { Box, FormControl, Grid, GridProps } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { FormDefinition, FormField, FormValues } from '../DynamicForm/models'
import DynamicFormLabel from '../DynamicForm/FormControls/DynamicFormLabel/DynamicFormLabel'
import OverFlowToolTip from '../../../components/OverFlowToolTip'

const DynamicView = ({
  containerLayout,
  definition,
  initialValues,
}: {
  containerLayout?: GridProps
  definition: FormDefinition
  initialValues?: FormValues
}) => {
  const { t } = useTranslation()

  const componentFactory = (
    name: string,
    field: FormField,
    fieldValue: string,
    isWrapped: boolean,
  ) => {
    const { component, ariaLabel, ...rest } = field
    if (!component) return null
    const componentProps = {
      ...rest,
      name,
      'aria-label': t(ariaLabel || ''),
      value: fieldValue,
    }
    const Component = component as any
    return isWrapped
      ? ((
          <Component {...componentProps}>
            <OverFlowToolTip>{fieldValue}</OverFlowToolTip>
          </Component>
        ) as React.ReactNode)
      : ((<Component {...componentProps} />) as React.ReactNode)
  }

  return (
    <Box>
      <Grid {...containerLayout} sx={{ alignItems: 'flex-start' }}>
        {Object.keys(definition).map((name) => {
          const { label, ariaLabel, itemLayout } = definition[name]
          const value = initialValues ? initialValues[name] : ''
          return (
            <Grid {...itemLayout}>
              <FormControl key={`${name}-fc`} sx={{ my: '12px' }}>
                {label && (
                  <DynamicFormLabel
                    aria-label={t(ariaLabel || label)}
                    id={`${name}-label`}
                    htmlFor={name}>
                    {t(label)}
                  </DynamicFormLabel>
                )}
                {componentFactory(name, definition[name], value, true)}
              </FormControl>
            </Grid>
          )
        })}
      </Grid>
      <Box sx={{ marginBottom: '5%' }} />
    </Box>
  )
}

DynamicView.defaultProps = {
  initialValues: {},
  containerLayout: {},
}

export default DynamicView
