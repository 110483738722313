import React from 'react'
import { Box } from '@mui/material'
import { ApiResponseOfDictionaryOfStringAndIEnumerableOfString } from '@rsmus/ecp-financeservice'
import { useTranslation } from 'react-i18next'
import { PaymentSelectedFiltersConstants } from '../../../store/invoices/paymentSelectedFiltersSlice'
import { Styles } from '../../../types'
import PaymentFiltersDateRange from './PaymentDateRangeFilters'
import PaymentFiltersCheckBoxes from './PaymentFiltersCheckBoxes'
import PaymentAmountRangeFilter from './PaymentAmountRangeFilter'

const styles: Styles = {
  container: {
    flexDirection: 'column',
    padding: 0,
  },
}

interface PaymentFiltersProps {
  filterData?: ApiResponseOfDictionaryOfStringAndIEnumerableOfString
  isFilterError: boolean
  isFilterSuccess: boolean
  clearCategoryFilter: (category: string) => void
  changeFilterAndSearch: (filterValue: string) => void
}

const defaultProps = {
  filterData: undefined,
}

const PaymentFilters = ({
  filterData,
  isFilterError,
  isFilterSuccess,
  clearCategoryFilter,
  changeFilterAndSearch,
}: PaymentFiltersProps) => {
  const { t } = useTranslation()

  if (isFilterError) {
    return <Box sx={styles.container}>{t('Invoicing.ErrorGettingFilters')}</Box>
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isFilterSuccess && (
        <Box sx={styles.container} className="filters">
          {Object.keys(filterData?.data ?? []).map((filterCategory: string) => {
            switch (filterCategory) {
              case PaymentSelectedFiltersConstants.paymentStatus:
                return (
                  <PaymentFiltersCheckBoxes
                    key={filterCategory}
                    filterCategory={filterCategory}
                    filterOptions={
                      filterData?.data
                        ? filterData.data[filterCategory] ?? []
                        : []
                    }
                    clearCategoryFilter={clearCategoryFilter}
                    changeFilterAndSearch={changeFilterAndSearch}
                    visibleItemsCount={5}
                  />
                )
              case PaymentSelectedFiltersConstants.paymentDates:
                return (
                  <PaymentFiltersDateRange
                    key={filterCategory}
                    filterCategory={filterCategory}
                    clearCategoryFilter={clearCategoryFilter}
                    changeFilterAndSearch={changeFilterAndSearch}
                  />
                )

              case PaymentSelectedFiltersConstants.paymentAmounts:
                return (
                  <PaymentAmountRangeFilter
                    key={filterCategory}
                    filterCategory={filterCategory}
                    clearCategoryFilter={clearCategoryFilter}
                    changeFilterAndSearch={changeFilterAndSearch}
                  />
                )

              default:
                return null
            }
          })}
        </Box>
      )}
    </>
  )
}

PaymentFilters.defaultProps = defaultProps

export default PaymentFilters
