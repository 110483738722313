import React from 'react'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Styles } from '../../../../types'
import { AnalyticEvent } from '../../../../analytics/adobe/types'

const styles: Styles = {
  container: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '11.625rem',
    [theme.breakpoints.only('tablet')]: {
      height: '12.625rem',
    },
    [theme.breakpoints.up('desktop')]: {
      height: '14.875rem',
    },
  }),
  image: {
    objectFit: 'cover',
    height: '100%',
    width: 'inherit',
    // both the title link & the image redirect to the article page
    // therefore we don't want screenreaders or tab to show the image as a link
    // but we want mouse users to see that clicking the image will act as a link
    '&&:hover': {
      cursor: 'pointer',
    },
  },
}

interface ArticleListItemImageProps {
  url?: string
  id?: string
  index?: number
}

const defaultProps = {
  url: '',
  id: '',
  index: 0,
}

const ArticleListItemImage = ({
  url,
  id,
  index,
}: ArticleListItemImageProps) => {
  const navigate = useNavigate()
  const analyticEventType: AnalyticEvent = 'Navigation_Click'

  return (
    <Box sx={styles.container}>
      <Box
        component="img"
        sx={styles.image}
        alt=""
        data-testid={`Img_Article_Image_${index}`}
        data-analytic-event={analyticEventType}
        onClick={() => {
          navigate(`/insights/${encodeURI(id ?? '')}`)
        }}
        src={url}
      />
    </Box>
  )
}

ArticleListItemImage.defaultProps = defaultProps

export default ArticleListItemImage
