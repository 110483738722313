import { faBookmark } from '@fortawesome/free-regular-svg-icons'
import { faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, styled, Theme, Tooltip, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AnalyticEvent } from '../../analytics/adobe/types'
import { useDeviceType } from '../../rsmCoreComponents/hooks/useDeviceType'
import { tokens } from '../../styles/materialTheme'
import { Styles } from '../../types'
import { ArticleMetaData } from '../../utils/helpers/Article.service'
import api from '../../api'

const analyticEventType: AnalyticEvent = 'Navigation_Click'

const styles: Styles = {
  container: (theme) => ({
    margin: '1.25rem 0.625rem 0',
    height: '28.5rem',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    [theme.breakpoints.up('tablet')]: {
      height: '30.5rem',
    },
    [theme.breakpoints.up('desktop')]: {
      margin: '1.25rem 1.25rem 0',
    },
  }),
  textContainer: (theme) => ({
    padding: '1.25rem',
    height: '11.5rem',
    [theme.breakpoints.up('tablet')]: {
      marginRight: '-1.25rem',
      marginLeft: '-1.25rem',
      paddingRight: '2.5rem',
      paddingLeft: '2.5rem',
      height: '13.5rem',
    },
    [theme.breakpoints.up('desktop')]: {
      paddingRight: '2.8125rem',
      paddingLeft: '2.8125rem',
    },
  }),
  topRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  headingTextContainer: (theme) => ({
    paddingBottom: '0.5rem',
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.0625rem',
    textTransform: 'uppercase',
  }),
  headingText: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  subHeadingText: {
    paddingBottom: '1rem',
    fontFamily: tokens.type.fontFamilies.preloBold, // TODO FIGURE OUT IF CAN ADD TO MATERIALTHEME
    fontSize: '0.75rem',
    lineHeight: '1rem',
    letterSpacing: '0.0625rem',
    textTransform: 'uppercase',
  },
  bookmarkContainer: (theme) => ({
    order: '2',
    marginTop: '-0.625rem',
    marginRight: '-1.8125rem',
    marginLeft: 'auto',
    [theme.breakpoints.up('desktop')]: {
      marginRight: '-1.625rem',
    },
  }),
  snapshotTextContainer: (theme) => ({
    paddingBottom: '2rem',
    fontFamily: theme.typography.fontFamily,
  }),
  snapshotText: (theme) => ({
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    [theme.breakpoints.up('tablet')]: {
      WebkitLineClamp: 3,
    },
  }),
  bottomRow: (theme) => ({
    paddingBottom: '1.375rem',
    paddingLeft: '1.375rem',
    [theme.breakpoints.up('tablet')]: {
      marginRight: '-0.125rem',
      marginBottom: '0.875rem',
      marginLeft: '-0.125rem',
    },
    [theme.breakpoints.up('desktop')]: {
      marginBottom: '1.5rem',
    },
  }),
}

interface ArticleHomeTileProps {
  index: number
  article: ArticleMetaData
  defaultImageColor?: string
}

const defaultProps = {
  defaultImageColor: undefined,
}

const ArticleHomeTile = ({
  index,
  article,
  defaultImageColor,
}: ArticleHomeTileProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isMobile } = useDeviceType()
  const muiTheme = useTheme()
  const focus = useRef<{
    element: HTMLButtonElement | null
  }>({
    element: null,
  })
  const [latestClickEvent, setLatestClickEvent] = useState(false)
  const [clickCount, setClickCount] = useState(0)
  const defaultLink = 'insights'
  const readLink: string = article.ecpId
    ? `insights/${article.ecpId}`
    : defaultLink

  const handleFavoriteClick = useCallback(
    (selectedArticle: ArticleMetaData) => {
      api.cms.insights_ToggleFavoriteArticle(selectedArticle.ecpId ?? null)
      // eslint-disable-next-line no-param-reassign
      selectedArticle.favorite = !selectedArticle.favorite
      setClickCount(clickCount + 1)
      setLatestClickEvent(!latestClickEvent)
    },
    [
      api.cms.insights_ToggleFavoriteArticle,
      setLatestClickEvent,
      latestClickEvent,
    ],
  )

  const Styled = {
    FavoriteIcon: styled(FontAwesomeIcon)(({ theme }) => ({
      height: '1.375rem',
      [theme.breakpoints.up('desktop')]: {
        height: '1.6875rem',
      },
    })),
  }

  useEffect(() => {
    if (clickCount > 0) {
      focus.current.element?.focus()
    }
  }, [defaultImageColor, clickCount])

  const computedStyles = {
    articleImageContainer: useCallback(
      (theme: Theme) => ({
        borderColor: theme.palette.common.white,
        borderWidth: '0.0625rem',
        backgroundColor:
          defaultImageColor && defaultImageColor.length > 0
            ? defaultImageColor
            : theme.palette.primary.main,
        [theme.breakpoints.up('tablet')]: {
          borderWidth: '0.0625rem',
        },
      }),
      [defaultImageColor],
    ),
    articleImage: useMemo(
      () => ({
        height: '12.5rem',
        width: '100%',
        backgroundImage: `url('${article.imageUrl}')`,
        backgroundPosition: `50% ${isMobile ? '40%' : '15%'}`,
        backgroundSize: 'cover',
      }),
      [article.imageUrl],
    ),
  }

  return (
    <Box component="article" sx={styles.container}>
      <Box sx={(theme) => computedStyles.articleImageContainer(theme)}>
        <Box
          sx={computedStyles.articleImage}
          data-testid={`Img_ArticleTile_Picture_${index}`}
        />
      </Box>
      <Box sx={styles.textContainer}>
        <Box sx={styles.topRow}>
          <Box>
            <Box sx={styles.headingTextContainer} id={article.ecpId}>
              <Box
                component="h3"
                sx={styles.headingText}
                data-testid={`Lbl_ArticleTile_Title_${index}`}>
                {article.title ? article.title : ''}
              </Box>
            </Box>
            <Box
              component="h4"
              sx={styles.subHeadingText}
              data-testid="Lbl_ArticleTile_DateIndustry">
              {article.label && (
                <>
                  <span data-testid={`Lbl_ArticleTile_Industry_${index}`}>
                    {article.label}
                  </span>
                  <span>&nbsp;|&nbsp;</span>
                </>
              )}
              <span data-testid={`Lbl_ArticleTile_Date_${index}`}>
                {new Date(article.publishedOn).toLocaleDateString('en-us', {
                  month: 'long',
                  day: 'numeric',
                  timeZone: 'UTC',
                })}
              </span>
            </Box>
          </Box>
          <Box sx={styles.bookmarkContainer}>
            <Tooltip
              disableTouchListener
              title={
                article.favorite
                  ? (t('InsightArticle.RemoveFromFavorites') as string)
                  : (t('InsightArticle.AddToFavorites') as string)
              }>
              <Button
                ref={(element) => {
                  focus.current.element = element
                }}
                onClick={() => handleFavoriteClick(article)}
                aria-label={
                  article?.favorite
                    ? t('InsightArticle.RemoveFromFavorites')
                    : t('InsightArticle.AddToFavorites')
                }
                aria-pressed={article.favorite}
                aria-describedby={article.ecpId}
                aria-live="polite"
                data-testid={
                  article?.favorite
                    ? `Btn_ArticleTile_Favorite_Remove_${index}`
                    : `Btn_ArticleTile_Favorite_Add_${index}`
                }>
                <Styled.FavoriteIcon
                  icon={article?.favorite ? faBookmarkSolid : faBookmark}
                  color={
                    article?.favorite
                      ? muiTheme.palette.secondary.main
                      : muiTheme.palette.common.black
                  }
                />
              </Button>
            </Tooltip>
          </Box>
        </Box>
        <Box sx={styles.snapshotTextContainer}>
          <Box
            component="span"
            sx={styles.snapshotText}
            data-testid={`Lbl_ArticleTile_Snapshot_${index}`}>
            {article.snapshot ? article.snapshot : ''}
          </Box>
        </Box>
      </Box>
      <Box sx={styles.bottomRow}>
        <Button
          onClick={() => {
            navigate(readLink)
          }}
          variant="outlined"
          color="primary"
          aria-label={`${t('HomePage.ArticleReadNow')} ${article.title}`}
          data-testid={`Btn_ArticleTile_ReadMore_${index}`}
          data-analytic-event={analyticEventType}>
          {t('HomePage.ArticleReadNow')}
        </Button>
      </Box>
    </Box>
  )
}

ArticleHomeTile.defaultProps = defaultProps

export default ArticleHomeTile
