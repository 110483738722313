/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Box, Typography } from '@mui/material'

import { useFormikContext } from 'formik'

import { useDebounce } from 'usehooks-ts'
import { StyledTextField, Autocomplete, IListModal } from './AutoCompleteHelper'

import HiddenSpanForSR from '../Accessibility/HiddenSpanForSR'
import { FormValues } from '../DynamicForm/models'

import { SearchIcon } from '../../../components/icons'

import tokens from '../../../styles/tokens.json'
import { OwnProps } from './AutoCompleteTypes'
import FilterChips from '../../../components/FilterChips'
import WarningIcon from '../../../components/icons/WarningIcon/WarningIcon'

const RsmAutoComplete = <T,>(ownProps: OwnProps<T>) => {
  const {
    MAX_NUM_ASSIGNEES = 1,
    MAX_ASSIGNEES_MESSAGE = '',
    optionList: source = [],
    header,
    renderCustomChip = false,
    renderChip = () => {},
    onUpdateList = () => {},
    autoCompleteOptions = {
      isLoading: false,
      getOptionLabel: () => '',
      chipLabel: () => '',
      renderOption: () => <div>No option</div>,
      handleChipAdd: () => {},
      handleChipRemove: () => {},
      onClearAll: () => {},
      chipAutoFocus: false,
      textBoxProps: { error: false },
    },
    noOptionText,
    inputPlaceHolder,
    fetchProvider,
  } = ownProps
  const name = 'rsmAutoComplete'
  const { setFieldValue } = useFormikContext<FormValues>() ?? {}
  const { t } = useTranslation()

  const textBoxRef = useRef<HTMLInputElement>()
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [isReadOnly, setIsReadOnly] = useState<boolean | undefined>(false)
  const [selectedItem, setSelectedItem] = useState<IListModal<T>>()
  const [listItems, setListItems] = useState<IListModal<T>[]>([])
  const [ariaText, setAriaText] = useState('')
  const [optionList, setOptionsList] = useState<IListModal<T>[]>(source)
  const [isLoading, setIsLoading] = useState(true)
  const ariaTimeOut = useRef<NodeJS.Timeout>()

  const debouncedFilter = useDebounce<string>(inputValue, 500)

  const potentialList = useMemo(() => {
    const filterItemList = listItems.map((a) => a?.key?.toLowerCase())
    return optionList.filter(
      (eu: IListModal<T>) =>
        filterItemList.indexOf(eu?.key?.toLowerCase()) === -1,
    )
  }, [optionList, listItems])

  useEffect(() => {
    if (ariaText) {
      if (ariaTimeOut.current) {
        clearTimeout(ariaTimeOut.current)
      }
      ariaTimeOut.current = setTimeout(() => {
        setAriaText('')
      }, 20000)
    }
  }, [ariaText])

  useEffect(() => {
    const reachedLimit = !(listItems?.length < MAX_NUM_ASSIGNEES)
    onUpdateList(listItems, setFieldValue)
    setIsReadOnly(reachedLimit)
    if (reachedLimit) {
      setAriaText(`User Reached Limit ${MAX_NUM_ASSIGNEES}`)
    }
  }, [listItems])

  const onUpdateItems = (items: IListModal<T>[]) => {
    setListItems(items)
    setFieldValue(name, items)
    textBoxRef.current?.focus()
  }

  // SAVE to useState() and Formik
  const onCommitItem = async (
    addedItems: IListModal<T>[],
    chipAction: string,
  ) => {
    let updatedAddedItems: any
    if (chipAction === 'removed') {
      autoCompleteOptions?.handleChipRemove?.(addedItems, setFieldValue)
    } else {
      updatedAddedItems = await autoCompleteOptions?.handleChipAdd?.(
        addedItems,
        setFieldValue,
      )
      if (updatedAddedItems) {
        const cloneItems = [...addedItems]
        cloneItems.map((item) => {
          if (item.key === updatedAddedItems.key) {
            // eslint-disable-next-line no-param-reassign
            item.context = {
              ...item.context,
              ...updatedAddedItems.context,
            }
          }
          return item
        })
        onUpdateItems(cloneItems)
        return
      }
    }

    onUpdateItems(addedItems)
  }

  const onClearAll = (updatedListItem?: IListModal<any>[]) => {
    const clearList = updatedListItem || []
    setListItems(clearList)
    textBoxRef.current?.focus()
    autoCompleteOptions?.onClearAll?.(clearList, setFieldValue)
  }

  useEffect(() => {
    async function fetch(filterQuery: string) {
      if (fetchProvider) {
        setIsLoading(true)
        const results = await fetchProvider(filterQuery)
        setIsLoading(false)
        setOptionsList(results)
      }
    }

    if (fetchProvider && debouncedFilter.length >= 2) {
      fetch(debouncedFilter)
    }
  }, [debouncedFilter])

  useEffect(() => {
    const updateUserList = (newValue: IListModal<T>) => {
      const repeatedAssign = [...listItems].find(
        (list: IListModal<T>) => list?.key === newValue?.key,
      )
      if ([...listItems].length < MAX_NUM_ASSIGNEES && !repeatedAssign) {
        const newAssignees: IListModal<T>[] = [...listItems, newValue]
        onCommitItem(newAssignees, 'added')
        setAriaText(
          `${t('AutoComplete.AriaLabel.FLAdded', {
            list: newValue?.key,
          })}`,
        )
      }
    }
    if (selectedItem) {
      updateUserList({ ...selectedItem } as IListModal<T>)
    }
    setSelectedItem(undefined)
  }, [selectedItem])

  useEffect(() => {
    if (!inputValue) {
      setIsLoading(true)
    }
  }, [inputValue])

  useEffect(() => {
    if (autoCompleteOptions?.textBoxProps?.error) {
      textBoxRef.current?.focus()
    }
  }, [autoCompleteOptions?.textBoxProps])

  const removeAssignee = (item: IListModal<T>, list: IListModal<T>[]) =>
    [...list].filter(
      (filterItem: IListModal<T>) => filterItem?.key !== item.key,
    )

  const chipHandleOnClicked = (item: any) => {
    const filteredItemList: IListModal<T>[] = removeAssignee(item, listItems)
    onCommitItem(filteredItemList, 'removed')

    const removedItemList = listItems?.find((x) => x?.key === item.key)
    setAriaText(
      `${t('AutoComplete.AriaLabel.FLRemoved', {
        list: removedItemList?.key,
      })}`,
    )
  }

  const PopUpIcon = useMemo(
    () => (
      <span style={{ opacity: isReadOnly ? 0.5 : 1 }}>
        <SearchIcon />
      </span>
    ),
    [isReadOnly],
  )

  const renderAutoComplete = () => (
    <Autocomplete
      id="rsmAutoComplete"
      aria-controls="listInfo"
      aria-describedby="listInfo"
      blurOnSelect
      clearOnBlur
      clearOnEscape
      disablePortal
      forcePopupIcon
      loading={isLoading}
      popupIcon={PopUpIcon}
      noOptionsText={noOptionText}
      value={isReadOnly ? MAX_ASSIGNEES_MESSAGE : null}
      readOnly={isReadOnly}
      sx={{
        '& .MuiInputBase-input.Mui-readOnly': {
          WebkitTextFillColor: tokens.colors.rsmPurple.secondary,
        },
        '& .MuiInputBase-root:focus-within': {
          boxShadow: 'none !important',
        },
      }}
      options={potentialList}
      renderOption={(props, option) => {
        setAriaText(t('AutoComplete.AriaLabel.UpDownNavigate'))
        const optionLabel = option as IListModal<T>
        return autoCompleteOptions?.renderOption(props, optionLabel)
      }}
      getOptionLabel={(option) => {
        const optionLabel = option as IListModal<T>
        return isReadOnly
          ? MAX_ASSIGNEES_MESSAGE
          : autoCompleteOptions?.getOptionLabel(optionLabel)
      }}
      renderInput={(params) => (
        <Box marginBottom={1}>
          <StyledTextField
            {...params}
            {...autoCompleteOptions.textBoxProps}
            inputRef={textBoxRef}
            data-testid="Txt_Insight_ShareSendTo"
            autoComplete="off"
            placeholder={inputPlaceHolder}
            onClick={() => {
              if (textBoxRef.current && !isReadOnly) {
                textBoxRef.current.value = ''
              }
            }}
            InputProps={{
              ...params.InputProps,
              ...(isReadOnly && {
                startAdornment: <WarningIcon />,
                endAdornment: null,
              }),
            }}
          />
        </Box>
      )}
      onChange={(event, newValue) => setSelectedItem(newValue as IListModal<T>)}
      inputValue={inputValue}
      onInputChange={(e, value) => {
        setInputValue(value)
        if (value.length < 2) {
          setOpen(false)
        }
      }}
      open={open}
      onOpen={() => inputValue && setOpen(true)}
      onClose={() => setOpen(false)}
    />
  )

  return (
    <Stack spacing={1}>
      {header}
      {renderAutoComplete()}
      <HiddenSpanForSR id="listInfo" aria-live="assertive" aria-atomic="true">
        {ariaText}
      </HiddenSpanForSR>
      {renderCustomChip
        ? renderChip?.({ listItems, onClearAll, chipHandleOnClicked })
        : !!listItems.length && (
            <Box sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
              <FilterChips
                clearAllFocusQuerySelector="rsmAutoComplete"
                filtersSelected={listItems.map((item: any) => {
                  const ctx = item.context
                  return { ...item, property: '', value: ctx.name as any }
                })}
                onChipDeleted={chipHandleOnClicked}
                onClearAll={() => {
                  onClearAll()
                }}
                filterChipTranslationKeyPrefix="InsightsList.FilterHeaders"
              />
            </Box>
          )}
    </Stack>
  )
}

export default RsmAutoComplete
