import React from 'react'

import { v4 as uuidv4 } from 'uuid'

const PlusIcon = () => {
  const id = uuidv4()
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 13.7143H13.7143V24H10.2857V13.7143H0V10.2857H10.2857V0H13.7143V10.2857H24V13.7143Z"
        fill="#888B8D"
      />
      <mask
        id={`mask0_${id}`}
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 13.7143H13.7143V24H10.2857V13.7143H0V10.2857H10.2857V0H13.7143V10.2857H24V13.7143Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#mask0_${id})`} />
    </svg>
  )
}
PlusIcon.defaultProps = { index: 0 }
export default PlusIcon
