import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ProfileCompletionStatusMessage =
  | 'Incomplete'
  | 'Complete'
  | undefined

// Define a type for the slice state
interface FormState {
  messageType?: ProfileCompletionStatusMessage
  shouldDisplay?: boolean
  isWelcomeShowing?: boolean
  popOverAnchor?: React.RefObject<HTMLButtonElement>
}

// Define the initial state using that type
const initialState: FormState = {
  messageType: undefined,
}

export const popoverSlice = createSlice({
  name: 'popover',
  initialState,
  reducers: {
    setPopoverContent: (
      state,
      { payload }: PayloadAction<ProfileCompletionStatusMessage>,
    ) => ({
      ...state,
      messageType: payload,
    }),
    setWelcomeState: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isWelcomeShowing: payload,
    }),
    showPopover: (
      state,
      { payload }: PayloadAction<React.RefObject<HTMLButtonElement>>,
    ) => ({ ...state, popOverAnchor: payload, shouldDisplay: true }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dismissPopover: (_, __: PayloadAction<void>) => ({}),
    hidePopover: (state) => ({
      ...state,
      shouldDisplay: false,
    }),
  },
})

export const {
  setPopoverContent,
  setWelcomeState,
  showPopover,
  dismissPopover,
  hidePopover,
} = popoverSlice.actions

export default popoverSlice.reducer
