import { useTabContext } from '@mui/lab/TabContext'
import { ButtonBase, Link as MUILink, ListItem } from '@mui/material'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import tokens from '../../../styles/tokens.json'
import { Styles } from '../../../types'
import { AnalyticEvent } from '../../../analytics/adobe/types'

const styles: Styles = {
  listItem: {
    padding: 0,
  },
  link: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    paddingX: '0.75rem',
    height: '100%',
    fontFamily: tokens.type.fontFamilies.preloBook,
    fontSize: '1rem',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    '&[aria-current="true"]': {
      fontFamily: tokens.type.fontFamilies.preloBold,
    },
  }),
  button: (theme) => ({
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingX: '0.75rem',
    height: '100%',
    fontFamily: tokens.type.fontFamilies.preloBook,
    fontSize: '1rem',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
  }),
}

const analyticEventType: AnalyticEvent = 'Navigation_Click'

interface BaseProps {
  children: React.ReactNode
  tabIndex?: number
  testId: string
}

interface RouterLinkProps extends BaseProps {
  href: never
  isCurrent?: boolean
  onClick: never
  rel?: never
  target?: never
  to: string
  value?: string
}

interface ExternalLinkProps extends BaseProps {
  href: string
  isCurrent?: never
  onClick: never
  rel?: string
  target?: string
  to: never
  value?: never
}

interface ButtonProps extends BaseProps {
  href: never
  isCurrent?: never
  onClick: (params: unknown) => unknown
  rel?: never
  target?: never
  to: never
  value?: never
}

type NavListItemProps = RouterLinkProps | ExternalLinkProps | ButtonProps

const defaultProps = {
  href: undefined,
  isCurrent: undefined,
  onClick: undefined,
  rel: 'noopener noreferrer',
  tabIndex: undefined,
  target: '_blank',
  to: undefined,
}

const NavListItem = ({
  children,
  href,
  isCurrent,
  onClick,
  rel = 'noopener noreferrer',
  tabIndex,
  target = '_blank',
  testId,
  to,
  value,
}: NavListItemProps) => {
  const isRouterLink = Boolean(to)
  const isExternalLink = Boolean(href)
  const isButton = Boolean(onClick)

  let isCurrentItem
  if (isRouterLink) {
    const tabContext = useTabContext()
    isCurrentItem = isCurrent ?? value === tabContext?.value
  }

  return (
    <ListItem sx={styles.listItem}>
      {isRouterLink && (
        <MUILink
          component={RouterLink}
          sx={styles.link}
          to={to}
          aria-current={isCurrentItem ?? 'page'}
          tabIndex={tabIndex}
          data-testid={testId}
          data-analytics-id={testId}
          data-analytic-event={analyticEventType}>
          {children}
        </MUILink>
      )}

      {isExternalLink && (
        <MUILink
          sx={styles.link}
          href={href}
          target={target}
          rel={rel}
          tabIndex={tabIndex}
          data-testid={testId}
          data-analytics-id={testId}
          data-analytic-event={analyticEventType}>
          {children}
        </MUILink>
      )}

      {isButton && (
        <ButtonBase
          sx={styles.button}
          tabIndex={tabIndex}
          data-testid={testId}
          data-analytics-id={testId}
          data-analytic-event={analyticEventType}
          onClick={onClick}>
          {children}
        </ButtonBase>
      )}
    </ListItem>
  )
}

NavListItem.defaultProps = defaultProps

export default NavListItem
