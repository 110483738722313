import { faBookmark } from '@fortawesome/free-regular-svg-icons'
import { faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, styled, Tooltip, useTheme } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Styles } from '../../../types'
import { ArticleMetaData } from '../../../utils/helpers/Article.service'
import api from '../../../api'

const styles: Styles = {
  container: {
    display: 'flex',
    marginTop: '0.125rem',
  },
}

const Styled = {
  FavoriteIcon: styled(FontAwesomeIcon)(() => ({
    height: '1.6875rem',
  })),
}

interface ArticleListItemFavoriteProps {
  article: ArticleMetaData
  articleTitleId: string
  index?: number
}

const defaultProps = {
  index: 0,
}

const ArticleListItemFavorite = ({
  article,
  articleTitleId,
  index,
}: ArticleListItemFavoriteProps) => {
  const { t } = useTranslation()
  const [latestClickEvent, setLatestClickEvent] = useState(false)
  const theme = useTheme()

  const handleFavoriteClick = useCallback(
    (selectedArticle: ArticleMetaData) => {
      api.cms.insights_ToggleFavoriteArticle(selectedArticle.ecpId ?? null)
      // eslint-disable-next-line no-param-reassign
      selectedArticle.favorite = !selectedArticle.favorite
      setLatestClickEvent(!latestClickEvent)
    },
    [
      api.cms.insights_ToggleFavoriteArticle,
      setLatestClickEvent,
      latestClickEvent,
    ],
  )

  return (
    <Box sx={styles.container}>
      <Tooltip
        disableTouchListener
        title={
          article.favorite
            ? (t('InsightArticle.RemoveFromFavorites') as string)
            : (t('InsightArticle.AddToFavorites') as string)
        }>
        <Button
          aria-describedby={`${articleTitleId}heading`}
          aria-label={
            article?.favorite
              ? t('InsightArticle.RemoveFromFavorites')
              : t('InsightArticle.AddToFavorites')
          }
          aria-pressed={article.favorite}
          data-testid={
            article?.favorite
              ? `Btn_Article_Favorite_Remove_${index}`
              : `Btn_Article_Favorite_Add_${index}`
          }
          onClick={() => handleFavoriteClick(article)}>
          <Styled.FavoriteIcon
            color={
              article.favorite
                ? theme.palette.secondary.main
                : theme.palette.common.black
            }
            data-testid={`Icn_Article_Favorite_${index}`}
            icon={article.favorite ? faBookmarkSolid : faBookmark}
            id={article.ecpId}
          />
        </Button>
      </Tooltip>
    </Box>
  )
}

ArticleListItemFavorite.defaultProps = defaultProps

export default ArticleListItemFavorite
