import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Typography } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { setExpandAll } from '../../../store/faqs/faqsSlice'
import { Styles } from '../../../types'

const styles: Styles = {
  buttonText: {
    marginRight: '1rem',
    fontSize: '1.125rem',
  },
}

interface ExpandButtonProps {
  testId?: string
}

const defaultProps = {
  testId: undefined,
}

const ExpandButton = ({ testId }: ExpandButtonProps) => {
  const dispatch = useDispatch()
  const { expandAll, hasFocus } = useSelector((state: RootState) => state.faqs)

  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    dispatch(setExpandAll(!expandAll))
  }

  useEffect(() => {
    if (hasFocus === 'expand-all') {
      buttonRef?.current?.focus()
    }
  }, [expandAll])

  const testIdWithState = testId + (expandAll ? '_Collapse' : '_Expand')

  return (
    <Button
      ref={buttonRef}
      aria-live="polite"
      onClick={handleToggle}
      data-testid={testIdWithState}
      disableFocusRipple>
      <Typography component="span" variant="h4" sx={styles.buttonText}>
        {expandAll
          ? t('DocumentRequests.Filters.CollapseAll')
          : t('DocumentRequests.Filters.ExpandAll')}
      </Typography>
      <FontAwesomeIcon
        height="0.75rem"
        icon={expandAll ? faAngleDoubleUp : faAngleDoubleDown}
      />
    </Button>
  )
}

ExpandButton.defaultProps = defaultProps

export default ExpandButton
