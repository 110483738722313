import { Box, Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import { Styles } from '../../../types'
import ArrowLeftIcon from '../../icons/ArrowLeftIcon'
import CloseOutlinedIcon from '../../icons/CloseOutlinedIcon'
import FiltersIcon from '../../icons/FiltersIcon'

const styles: Styles = {
  container: (theme) => ({
    display: 'flex',
    alignContent: 'center',
    backgroundColor: theme.palette.common.white,
  }),
  button: (theme) => ({
    justifyContent: 'space-between',
    padding: '1rem 1.25rem 1rem 0rem',
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
    [theme.breakpoints.up('tablet')]: {
      margin: '1rem 1rem 1rem 0',
      border: `1px solid rgba(0,0,0, 0.23)`,
      paddingLeft: '1rem',
      width: '100%',
      '&:hover, &:active': {
        borderColor: theme.palette.common.black,
      },
    },
    [theme.breakpoints.only('mobile')]: {
      fontSize: '1.125rem',
      padding: '1rem 0.625rem 1rem 0rem',
    },
  }),
  iconText: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  collapseIconContainer: (theme) => ({
    [theme.breakpoints.down('tablet')]: {
      visibility: 'hidden',
    },
    '& svg': {
      transform: 'scale(1.25)',
      '& path': {
        fill: theme.palette.common.black,
      },
    },
  }),
}

type SearchLayoutDrawerDialogButtonProps = {
  isOpen: boolean
  openIcon?: React.ReactNode
  closeIcon?: React.ReactNode
  collapseIcon?: React.ReactNode
  onClick: () => void
  children?: React.ReactNode
}

const defaultProps = {
  openIcon: undefined,
  closeIcon: undefined,
  collapseIcon: undefined,
  children: undefined,
}

const SearchLayoutDrawerDialogButton = ({
  isOpen,
  openIcon,
  closeIcon,
  collapseIcon,
  onClick,
  children,
}: SearchLayoutDrawerDialogButtonProps) => {
  const { isMobile } = useDeviceType()
  const { t } = useTranslation()

  return (
    <Box sx={styles.container}>
      <Button
        sx={styles.button}
        variant="text"
        disableRipple
        aria-expanded={isOpen ? 'true' : 'false'}
        data-testid="Btn_DrawerDialog"
        onClick={onClick}>
        <Box component="span" sx={styles.iconText}>
          {isMobile || !isOpen
            ? openIcon === null || <FiltersIcon />
            : closeIcon === null || <CloseOutlinedIcon />}
          &nbsp;
          {children || t('SearchLayout.Filters')}
        </Box>
        <Box component="span" sx={styles.collapseIconContainer}>
          {!isMobile && isOpen && (collapseIcon === null || <ArrowLeftIcon />)}
        </Box>
      </Button>
    </Box>
  )
}

SearchLayoutDrawerDialogButton.defaultProps = defaultProps

export default SearchLayoutDrawerDialogButton
