import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import { IEngagementDtoV2 } from '@rsmus/xcelerate-cemservice'
import type { RootState } from '..'
import { ActiveEngagement } from '../../pages/Engagement/Engagement.service'
import { EngagementView } from '../../pages/EngagementList/Interfaces/EngagementViewTypes'
import { CEM_ENGAGEMENT_MAX } from '../../envVariables'
import api from '../../api'

interface EngagementState {
  isReady?: boolean
  Engagements: EngagementView[]
  ActiveEngagement: ActiveEngagement | undefined
  isErrorOccured: boolean
}

const initialState: EngagementState = {
  isReady: false,
  Engagements: [],
  ActiveEngagement: undefined,
  isErrorOccured: false,
}

const formatDate = (old?: Date) => {
  if (!old) return ''
  const date = old.toString()
  return `${date.substr(4, 3)} ${date.substr(8, 2)}, ${date.substr(11, 4)}`
}
export const massageData = async (
  isEmployee: boolean,
  engagementList?: IEngagementDtoV2[],
): Promise<EngagementView[]> => {
  // This takes the object from the server and gets only active engagements, formats the date to string so we can search, and sorts the engagements by engagement name alphabetically by default
  const engagements: EngagementView[] = []

  engagementList?.forEach((item) => {
    if (item.status !== 'Active') {
      return
    }
    engagements.push({
      ...item,
      scheduledStartDateView: formatDate(item.scheduledStartDate),
      scheduledEndDateView: item.scheduledEndDate
        ? formatDate(item.scheduledEndDate)
        : undefined,
    })
  })

  return engagements
}

export const sortEngagements = (EngagementList: EngagementView[]) => {
  const sortMe = [...EngagementList]
  const sortedEngagements = sortMe.sort((a, b) => {
    if (a.name && b.name) {
      return a.name.localeCompare(b.name)
    }
    return 0
  })
  return sortedEngagements
}

export const fetchAllEngagements = createAsyncThunk(
  'engagements/getAll',
  async ({ isEmployee }: { isEmployee: boolean }, { rejectWithValue }) => {
    let results: EngagementView[] = []
    let rejection: any
    try {
      const response = await api.cem.engagements_GetAll2(
        CEM_ENGAGEMENT_MAX,
        undefined,
        undefined,
        undefined,
      )
      results = await massageData(isEmployee, response.results)
    } catch (error: any) {
      rejection = rejectWithValue(error)
    }

    if (!rejection) return results

    return rejection
  },
)

export const engagementsSlice = createSlice({
  name: 'engagements',
  initialState,
  reducers: {
    setActiveEngagement: (state, { payload }) => {
      state.ActiveEngagement = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllEngagements.rejected, (state) => {
        state.isErrorOccured = true
        state.Engagements = []
        state.isReady = true
      })
      .addCase(fetchAllEngagements.fulfilled, (state, action) => {
        state.Engagements = action.payload
        state.isErrorOccured = false
        state.isReady = true
      })
  },
})

export const { setActiveEngagement } = engagementsSlice.actions

export const getEngagements = (state: RootState) =>
  state.engagements.Engagements

export const getSortedEngagements = createSelector(
  [
    getEngagements,
    (state: RootState, maxRecords: number | undefined) => maxRecords,
  ],
  (items: EngagementView[], maxRecords: any) =>
    sortEngagements(
      maxRecords === undefined ? items : items.slice(0, maxRecords),
    ),
)

export const getReadyState = (state: RootState) => state.engagements.isReady

export const getActiveEngagement = (state: RootState) =>
  state.engagements.ActiveEngagement

export const isEngagementDataAvailable = (state: RootState) =>
  !state.engagements.isErrorOccured

export default engagementsSlice.reducer
