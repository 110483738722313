import { t } from 'i18next'
import locales from '../../i18n/locales'
import messages from '../../i18n/messages'

/**
 * @param {string | null} template - The string containing tokens that match an ActivityTemplates key.
 * @param {{ [key: string]: any } | null} params - The json object with properties that will be merged with the template.
 * @description - Parses tokens from template, looks up the i18n template from messages.ts, and merges the text with the parameters.
 * @returns {Array<string>} - An array of strings where each string should be displayed on its own line (e.g. enclose in Divs or delimit with <br>)
 */
export default (
  template: string | null,
  params: { [key: string]: any } | null,
) => {
  const updatedParams = params
  // Convert date string to dates.

  if (updatedParams != null) {
    Object.keys(updatedParams).map((prop) => {
      if (prop.endsWith('Date')) {
        updatedParams[prop] = new Date(updatedParams[prop])
      }
      return updatedParams
    })
  }

  const result = template?.replace(/\{[^}]+\}/gi, (token) => {
    const key = token.replace('{', '').replace('}', '')
    if (!(key in messages[locales.ENGLISH].translation.ActivityTemplates)) {
      throw new Error('Template token is not found in ActivityTemplates.')
    }

    if (updatedParams === null) {
      return t(`ActivityTemplates.${key}`)
    }

    return t(`ActivityTemplates.${key}`, updatedParams)
  })

  return result === undefined ? [''] : result.split('\n')
}
