import { Typography } from '@mui/material'
import React from 'react'
import { Styles } from '../../types'

const styles: Styles = {
  text: (theme) => ({
    fontFamily: 'Prelo-Bold, sans-serif',
    fontSize: '0.75rem',
    letterSpacing: '0.0625rem',
    textTransform: 'uppercase',
    color: theme.palette.common.white,
  }),
}

export interface DateIndustryProps {
  date?: string
  label?: string
}

const defaultProps = {
  date: '',
  label: '',
}

const DateIndustry = ({ date, label }: DateIndustryProps) => (
  <Typography sx={styles.text}>
    <span data-testid="Lbl_SpotlightArticle_Date">{date}</span>
    {label && (
      <>
        <span>&nbsp;|&nbsp;</span>
        <span data-testid="Lbl_SpotlightArticle_Industry">{label}</span>
      </>
    )}
  </Typography>
)

DateIndustry.defaultProps = defaultProps

export default DateIndustry
