import React from 'react'
import {
  ApplicationInsights,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web'
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js'
import {
  ReactPlugin,
  withAITracking,
} from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'
import { APP_INSIGHTS_INSTRUMENTATION_KEY as KEY } from '../../envVariables'

export const rcPlugin = new ReactPlugin()

const clickPluginInstance = new ClickAnalyticsPlugin()
// Click Analytics configuration
const clickPluginConfig = {
  autoCapture: true,
  dataTags: {
    useDefaultContentNameOrId: true,
  },
}

const bHistory = createBrowserHistory()

const aInsights = new ApplicationInsights({
  config: {
    instrumentationKey: KEY || 'APP_KEY',
    enableAutoRouteTracking: true,
    extensions: [rcPlugin, clickPluginInstance],
    extensionConfig: {
      [rcPlugin.identifier]: { history: bHistory },
      [clickPluginInstance.identifier]: clickPluginConfig,
    },
  },
})

aInsights.loadAppInsights()

aInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
  envelope.tags?.push({ 'ai.cloud.role': 'React App' })
})

export default (com: React.ComponentType<unknown>) =>
  withAITracking(rcPlugin, com)
export const { appInsights } = aInsights
