/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Collapse, Typography, Radio, RadioGroup } from '@mui/material'

import { styled } from '@mui/material/styles'
import { ArrowUpIcon, ArrowDownIcon } from '../icons'

import './FilterInsights.css'
import StyledButton from '../../../components/DocumentRequests/components/StyledButton/StyledButton'

interface FilterRadioProps<T> {
  filterTitle: string
  option: T[keyof T]
  isFilterChecked: (option: T[keyof T]) => boolean
  handleSelectedChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    option: T[keyof T],
  ) => void
  formatFilterDisplayFunction: ((valueString: string) => string) | undefined
}

const FilterTitle = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.125rem',
  fontFamily: 'Prelo-Bold, sans-serif',
}))

const defaultProps = {
  formatFilterDisplayFunction: (valueString: string) => valueString,
}

// Replace spaces with dashes.
const sanitizeWhitespace = (title: string) => title.replace(/\s+/g, '-')

const FilterRadio = <T,>(props: FilterRadioProps<T>) => {
  const {
    filterTitle,
    option,
    isFilterChecked,
    handleSelectedChange,
    formatFilterDisplayFunction,
  } = props

  return (
    <div>
      <Radio
        id={`${filterTitle}_${option}`}
        checked={isFilterChecked(option)}
        onChange={(event) => handleSelectedChange(event, option)}
        data-testid={`Btn_Insight_Filter_${sanitizeWhitespace(String(option))}`}
      />
      <label htmlFor={`${filterTitle}_${option}`}>
        {formatFilterDisplayFunction && typeof option === 'string'
          ? formatFilterDisplayFunction(option)
          : option}
      </label>
    </div>
  )
}

type FilterInsightsProps<T> = {
  name: string
  filterProperty: keyof T
  filterTitle: string
  filterOptions: Array<T[keyof T]>
  selectedValues: Map<keyof T, Set<T[keyof T]>> | undefined
  setSelectedValues: (
    payload: Map<keyof T, Set<T[keyof T]>> | undefined,
  ) => void
  formatFilterDisplayFunction?: (valueString: string) => string
  visibleItemsCount: number
  showMoreItems: boolean
  handleFilterGroupExpanded: (group: string, state: boolean) => void
}

const FilterInsights = <T,>({
  name,
  filterTitle,
  filterOptions,
  selectedValues,
  filterProperty,
  setSelectedValues,
  formatFilterDisplayFunction,
  visibleItemsCount,
  showMoreItems,
  handleFilterGroupExpanded,
}: FilterInsightsProps<T>) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(true)

  useEffect(() => {
    const yourInsights: keyof T = 'yourinsights' as keyof T
    const tempSelected = new Map(selectedValues)

    if (selectedValues?.get(yourInsights) === undefined) {
      // set default radio
      const value = new Set(selectedValues?.get(yourInsights))
      value.add('All Insights' as unknown as T[keyof T])
      tempSelected.set(yourInsights, value)
      setSelectedValues(tempSelected)
    }
  }, [selectedValues])

  const handleSelectedChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    option: T[keyof T],
  ) => {
    // bug #489076 radio buttons are predefined filters
    // and will not combine other existing filters
    if (e.target.checked)
      setSelectedValues(new Map().set(filterProperty, new Set([option])))
    else setSelectedValues(new Map())

    /// Keeping just to make sure there is no otehr bug with last minute change
    // const tempSelected = new Map(selectedValues)
    // if (e.target.checked) {
    //   const value = new Set<T[keyof T]>([])
    //   value.add(option)

    //   tempSelected.set(filterProperty, value)
    // }
    // setSelectedValues(tempSelected)
  }

  const shouldCollapseItems = filterOptions.length > visibleItemsCount
  const allwaysVisibleItems = shouldCollapseItems
    ? filterOptions.slice(0, visibleItemsCount)
    : filterOptions
  const moreItems = shouldCollapseItems
    ? filterOptions.slice(visibleItemsCount, filterOptions.length)
    : []

  // Check if filter option is checked
  const isFilterChecked = (option: T[keyof T]) =>
    new Set(selectedValues?.get(filterProperty)).has(option)

  return (
    <fieldset className="filterItem">
      <div className="filtersHeader">
        <FilterTitle
          id={`clear_${sanitizeWhitespace(filterTitle)}`}
          data-testid="Btn_YourInsights_ExpandCollapse"
          onClick={() => setExpanded(!expanded)}
          disableRipple
          aria-expanded={expanded}>
          <legend>{filterTitle}</legend>
          {expanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </FilterTitle>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <RadioGroup>
          {allwaysVisibleItems.map((option: T[keyof T]) => (
            <FilterRadio
              key={`${filterTitle}_${option}`}
              filterTitle={filterTitle}
              option={option}
              isFilterChecked={isFilterChecked}
              handleSelectedChange={handleSelectedChange}
              formatFilterDisplayFunction={formatFilterDisplayFunction}
            />
          ))}
        </RadioGroup>
        <Collapse in={showMoreItems} timeout="auto" unmountOnExit>
          {moreItems.map((option: T[keyof T], index: number) => {
            const key = `${option}_${index}`
            return (
              <FilterRadio
                key={key}
                filterTitle={filterTitle}
                option={option}
                isFilterChecked={isFilterChecked}
                handleSelectedChange={handleSelectedChange}
                formatFilterDisplayFunction={formatFilterDisplayFunction}
              />
            )
          })}
        </Collapse>
        {shouldCollapseItems && (
          <li>
            <StyledButton
              sx={{
                height: 56,
                color: 'secondary.main',
                textDecoration: 'underline',
                cursor: 'pointer',
                padding: '0 6px',
              }}
              data-testid={
                showMoreItems
                  ? `Btn_Insight_SeeLess${sanitizeWhitespace(filterTitle)}`
                  : `Btn_Insight_SeeMore${sanitizeWhitespace(filterTitle)}`
              }
              onClick={() => handleFilterGroupExpanded(name, !showMoreItems)}
              aria-live="polite">
              <Typography
                sx={{
                  color: 'secondary.main',
                  fontWeight: 'medium',
                  fontSize: '1.125rem',
                  fontFamily: 'Prelo-Medium, sans-serif',
                }}>
                {showMoreItems
                  ? t('Filters.CollapsedToggle.SeeLess')
                  : t('Filters.CollapsedToggle.SeeMore')}
              </Typography>
            </StyledButton>
          </li>
        )}
      </Collapse>
    </fieldset>
  )
}

FilterInsights.defaultProps = defaultProps
export default FilterInsights
