import React, { ReactNode } from 'react'
import { Box, Button, styled } from '@mui/material'
import { TFunction, useTranslation } from 'react-i18next'
import SectionHeader from '../../../../components/layouts/SectionHeader'
import { tokens } from '../../../../styles/materialTheme'

const StyledSection = styled('section')(({ theme }) => ({
  backgroundColor: 'white',
  paddingTop: '2.5rem',
  paddingBottom: '2.5rem',
  paddingLeft: '2.5rem',
  paddingRight: '2.5rem',
  [theme.breakpoints.only('mobile')]: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  [theme.breakpoints.only('tablet')]: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
}))

interface FormCardProps {
  title: TFunction
  desc: TFunction
  children: ReactNode
  handleSubmit: () => void
  submitBtnDisabled?: boolean
  testId: string
}

const defaultProps = {
  submitBtnDisabled: false,
}
const FormCard = ({
  title,
  desc,
  children,
  handleSubmit,
  submitBtnDisabled,
  testId,
}: FormCardProps) => {
  const { t } = useTranslation()

  return (
    <form onSubmit={handleSubmit}>
      <Box
        borderColor={tokens.colors.rsmGray.disabled}
        sx={{
          flexWrap: 'wrap',
          borderWidth: '.0625rem',
          borderStyle: 'solid',
        }}>
        <StyledSection data-testid="Sec_Support_FormBox">
          <SectionHeader title={`${title}`} testId={`Lbl_${testId}_Title`} />
          <Box
            data-testid={`Lbl_${testId}_Description`}
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(1,1fr)',
              gap: '0.5rem',
              paddingBottom: '2.5rem',
              fontSize: '1rem',
              fontFamily: 'Prelo-Book, sans-serif',
            }}>
            {desc}
          </Box>
          {children}
          <Box
            sx={{
              display: 'flex',
              flex: '1 1 0%',
              justifyContent: 'center',
              paddingTop: '2rem',
            }}>
            <Button
              variant="contained"
              type="submit"
              sx={{ maxHeight: '3.125rem' }}
              color="primary"
              disabled={submitBtnDisabled}
              data-testid={`Btn_${testId}_Submit`}>
              {t('Submit')}
            </Button>
          </Box>
        </StyledSection>
      </Box>
    </form>
  )
}

FormCard.defaultProps = defaultProps

export default FormCard
