/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  ShareArticleRecipientViewModel,
  ShareArticleResultViewModel,
} from '@rsmus/ecp-cmsservice'
import { AssociateViewModel } from '@rsmus/ecp-userservice'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DynamicForm from '../../../rsmCoreComponents/components/DynamicForm/FormControls/DynamicForm'
import DynamicFormLabel from '../../../rsmCoreComponents/components/DynamicForm/FormControls/DynamicFormLabel/DynamicFormLabel'
import FormCheckboxLabel from '../../../rsmCoreComponents/components/DynamicForm/FormControls/FormCheckboxLabel'
import FormTextArea from '../../../rsmCoreComponents/components/DynamicForm/FormControls/FormTextArea/FormTextArea'
import { FormValues } from '../../../rsmCoreComponents/components/DynamicForm/models'
import { IListModal } from '../../../rsmCoreComponents/components/RsmAutoComplete/AutoCompleteHelper'
import { IChipInfo } from '../../../rsmCoreComponents/components/RsmAutoComplete/AutoCompleteTypes'
import RsmAutoComplete from '../../../rsmCoreComponents/components/RsmAutoComplete/RsmAutoComplete'
import { getUserInfo } from '../../../store/userInfo/userInfoSlice'
import { useAppSelector } from '../../../utils/hooks'
import { CustomErrorAlert } from '../../forms/Alert'
import { CloseIcon } from '../../icons'
import { ShareArticleChip } from './ShareArticleChip'
import useStyles from './ShareArticleModal.useStyles'
import api from '../../../api'

export type ShareArticleModalResult = {
  users?: ShareArticleResultViewModel[]
}

interface ShareArticleModalProps {
  articleId?: string
  open: boolean
  onClose: (result?: ShareArticleModalResult) => void
}

const defaultProps = {
  articleId: undefined,
}

export const ShareArticleModal = ({
  open,
  onClose,
  articleId,
}: ShareArticleModalProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const styles = useStyles()
  const formRef = useRef<any>()
  const closeIconRef = useRef<any>()
  const userInfo = useAppSelector(getUserInfo)
  const [showError, setShowError] = useState<boolean>(false)
  const [autoCompleteError, setAutoCompleteError] = useState(false)
  const [checkingEcpUser, setCheckingEcpUser] = useState(false)
  const [showEcpWarning, setShowEcpWarning] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [ecpUserCheck, setEcpUserCheck] = useState(false)
  const checkboxRef = useRef<any>()
  const [messageEdited, setMessageEdited] = useState(false)

  const [formState] = useState<{
    formValues: any
    isViewOnlyMode: boolean
  }>({
    formValues: {
      FormTextArea: ``,
      rsmAutoComplete: ``,
      FormCheckboxLabel: false,
    },
    isViewOnlyMode: false,
  })

  const { formValues } = formState

  const fullScreen = useMediaQuery(theme.breakpoints.down('tablet'))

  useEffect(() => {
    if (closeIconRef.current && open) {
      closeIconRef.current.focus()
    }
  }, [closeIconRef, open])

  const AutoCompleteHeader = (
    <DynamicFormLabel sx={{ marginBottom: 0 }} htmlFor="rsmAutoComplete">
      Send to:
    </DynamicFormLabel>
  )

  const onCloseModal = (result?: ShareArticleModalResult) => {
    onClose(result)
    setShowError(false)
    setAutoCompleteError(false)
    setMessageEdited(false)
    setShowEcpWarning(false)
    setEcpUserCheck(false)
  }

  const checkEcpUser = async (
    selectedUser: AssociateViewModel,
  ): Promise<boolean | null> => {
    try {
      setCheckingEcpUser(true)
      const hasEcp: boolean =
        (await api.user.userInfo_IsEcpUser(selectedUser.user?.userId || ''))
          ?.data || false
      return hasEcp
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return null
    } finally {
      setCheckingEcpUser(false)
    }
  }

  const onEcpUserCheckChange = (checked: boolean) => {
    setEcpUserCheck(checked)
    setShowEcpWarning(!checked)
  }

  const handleSendArticle = async (values: FormValues) => {
    const rsmAutoComplete = values.rsmAutoComplete || []

    const checkIfNonEcpUserFound = rsmAutoComplete.find(
      (autoComplete: any) => autoComplete.context.nonEcpUser,
    )

    if (checkIfNonEcpUserFound && !ecpUserCheck) {
      setShowEcpWarning(true)
      if (checkboxRef.current) {
        checkboxRef.current?.focus()
      }
      return
    }

    if (checkingEcpUser) {
      return
    }

    const listItems = values.rsmAutoComplete as IListModal<
      AssociateViewModel & { nonEcpUser: boolean }
    >[]
    if (!rsmAutoComplete?.length) {
      setAutoCompleteError(true)
      return
    }

    try {
      setAutoCompleteError(false)

      const response = await api.cms.sharedArticle_ShareArticle({
        articleId,
        recipients: listItems.map(
          (item): ShareArticleRecipientViewModel =>
            ({
              sharedWithUserId: item.context?.user?.userId,
              sharedWithFirstName: item.context?.user?.firstName,
              sharedWithLastName: item.context?.user?.lastName,
              sharedWithUserEmail: item.context?.user?.email,
              isEcpUser: !item.context.nonEcpUser,
              associateHash: item.context?.hash,
            } as ShareArticleRecipientViewModel),
        ),
        sendCopy: values.FormCheckboxLabel,
        userMessage: values.FormTextArea,
      })

      onCloseModal({
        users: response.data || [],
      })
      setShowEcpWarning(false)
    } catch (error) {
      console.log('Error ', error)
      setShowError(true)
    }
  }

  const footerComponent = () => (
    <Stack sx={styles.btnContainer}>
      <Button
        disabled={checkingEcpUser || showError}
        data-test-id="Save_Button"
        data-testid="Btn_Insight_ShareSave"
        type="submit"
        variant="contained"
        fullWidth={fullScreen}
        color="primary">
        Send
      </Button>
      <Button
        data-test-id="Cancel_Button"
        data-testid="Btn_Insight_ShareCancel"
        type="button"
        variant="outlined"
        onClick={() => onCloseModal()}
        fullWidth={fullScreen}>
        Cancel
      </Button>
    </Stack>
  )

  const fetchProvider = async (searchFilter: string) => {
    setIsLoading(true)
    const response = await api.user.userInfo_Associates(searchFilter)
    const source = response.data || []
    setIsLoading(false)
    return source?.map(
      (x: AssociateViewModel) =>
        ({
          key: x?.user?.email,
          context: x,
        } as IListModal<AssociateViewModel>),
    )
  }

  const getDefaultTransKey = (isMultiple: boolean, hasEcp: boolean) => {
    if (isMultiple) {
      return 'ShareArticleModal.DefaultMessageMultiple'
    }
    return hasEcp
      ? 'ShareArticleModal.DefaultMessage'
      : 'ShareArticleModal.DefaultMessageNonEcpUser'
  }

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      fullWidth
      aria-labelledby="share-article-heading"
      maxWidth="tablet"
      onClose={() => {
        setShowError(false)
        setMessageEdited(false)
      }}
      sx={(sxTheme) => ({
        '& .MuiDialog-paper': {
          width: '45%',
          maxWidth: '100%',
          [sxTheme.breakpoints.down('desktop')]: {
            width: '76%',
          },
          [sxTheme.breakpoints.down('tablet')]: {
            width: '100%',
          },
        },
      })}>
      <DialogContent data-testid="share-dialog-container">
        <Box sx={styles.root}>
          <CustomErrorAlert
            testId="Banner_ShareArticle_Error"
            header="InsightArticle.ArticleSharedError"
            open={showError}
            close={() => onCloseModal()}
            message="InsightArticle.ArticleSharedErrorMessage"
          />
          <Box role="presentation" sx={styles.closeIcon}>
            <IconButton
              id="share_dialog_close"
              autoFocus
              ref={closeIconRef}
              data-testid="Share_Dialog_Close"
              aria-label={t('ShareArticleModal.AriaLabel.AriaCloseDialog')}
              onClick={() => onCloseModal()}
              color="inherit">
              <CloseIcon size={13} />
            </IconButton>
          </Box>

          <Typography
            variant="h1"
            component="h1"
            sx={styles.title}
            id="share-article-heading">
            {t('ShareArticleModal.Title')}
          </Typography>

          <Box width="100%">
            <DynamicForm
              initialValues={formValues}
              formRef={formRef}
              rootAriaLabel={t('ShareArticleModal.AriaLabel.ShareArticle')}
              definition={{
                RsmAutoComplete: {
                  name: 'rsmAutoComplete',
                  header: AutoCompleteHeader,
                  MAX_NUM_ASSIGNEES: 10,
                  MAX_ASSIGNEES_MESSAGE: t('ShareArticleModal.MaxUserLimit'),
                  onUpdateList: (
                    listItems: any,
                    setFieldValue: (name: string, value: string) => void,
                  ) => {
                    setFieldValue('rsmAutoComplete', listItems)
                  },
                  optionList: [],
                  noOptionText: t('ShareArticleModal.NoOptionsText'),
                  inputPlaceHolder: t(
                    'ShareArticleModal.UserSearchInputPlaceholder',
                  ),
                  renderCustomChip: true,
                  autoCompleteOptions: {
                    filterKey: 'name',
                    chipAutoFocus: true,
                    isLoading,
                    textBoxProps: {
                      error: autoCompleteError,
                      helperText: autoCompleteError ? (
                        <Typography
                          component="span"
                          sx={() => ({
                            float: 'left',
                            fontFamily: 'Prelo-Book, sans-serif',
                            fontSize: '0.875rem',
                            color: theme.palette.text.primary,
                            paddingTop: '0rem',
                          })}>
                          {t('ShareArticleModal.RequiredField')}
                        </Typography>
                      ) : (
                        ''
                      ),
                    },
                    renderOption: (
                      props: React.HTMLAttributes<HTMLLIElement>,
                      item: IListModal<AssociateViewModel>,
                    ) => (
                      <Box
                        component="li"
                        {...props}
                        sx={{
                          borderBottom: '0.0625rem solid #707274',
                          display: 'flex',
                        }}
                        data-testid={`Lbl_Insight_ShareArticleDropdownOption_${item.key}`}>
                        <Box sx={{ flex: 1 }}>
                          <p>
                            <strong>{`${item.context.user?.firstName} ${item.context.user?.lastName}`}</strong>
                          </p>
                        </Box>
                      </Box>
                    ),
                    getOptionLabel: ({
                      context,
                    }: IListModal<AssociateViewModel>) =>
                      `${context?.user?.firstName} ${context?.user?.lastName}`,
                    chipLabel: (op: IListModal<AssociateViewModel>) =>
                      `${op.context?.user?.firstName} ${op.context?.user?.lastName}`,
                    handleChipAdd: async (
                      addedItems: IListModal<AssociateViewModel>[],
                      setFieldValue: (name: string, value: string) => void,
                    ) => {
                      const item = addedItems[addedItems.length - 1]
                      const hasEcp = await checkEcpUser(item.context)
                      // If unable to determine if user is ECP user, show error and clear the input.
                      if (hasEcp === null) {
                        setShowError(true)
                        setAutoCompleteError(false)
                        setFieldValue('FormTextArea', '')
                      } else {
                        setAutoCompleteError(false)
                        if (!messageEdited) {
                          setFieldValue(
                            'FormTextArea',
                            t(
                              getDefaultTransKey(
                                addedItems?.length > 1,
                                hasEcp,
                              ),
                              {
                                receiverName:
                                  addedItems?.length === 1
                                    ? item.context?.user?.firstName
                                    : null,
                                senderName: userInfo?.firstName,
                              },
                            ),
                          )
                        }
                      }
                      return {
                        ...item,
                        context: { ...item.context, nonEcpUser: !hasEcp },
                      }
                    },
                    onClearAll: (
                      listItem: any,
                      setFieldValue: (name: string, value: string) => void,
                    ) => {
                      if (!listItem?.length) {
                        setFieldValue('FormTextArea', '')
                        setShowError(false)
                        setMessageEdited(false)
                      }
                    },
                    handleChipRemove: (
                      addedItems: IListModal<
                        AssociateViewModel & { nonEcpUser: boolean }
                      >[],
                      setFieldValue: (name: string, value: string) => void,
                    ) => {
                      if (!addedItems?.length) {
                        setFieldValue('FormTextArea', '')
                        setShowError(false)
                        setMessageEdited(false)
                        return
                      }
                      if (!messageEdited) {
                        setFieldValue(
                          'FormTextArea',
                          t(
                            getDefaultTransKey(
                              addedItems?.length > 1,
                              !addedItems?.[0]?.context?.nonEcpUser,
                            ),
                            {
                              receiverName:
                                addedItems?.length === 1
                                  ? addedItems?.[0]?.context?.user?.firstName
                                  : null,
                              senderName: userInfo?.firstName,
                            },
                          ),
                        )
                      }
                    },
                  },

                  fetchProvider,
                  renderChip: (
                    props: IChipInfo<
                      AssociateViewModel & { nonEcpUser: boolean }
                    >,
                  ) => (
                    <ShareArticleChip
                      {...props}
                      checkboxRef={checkboxRef}
                      ecpUserCheck={ecpUserCheck}
                      setEcpUserCheck={onEcpUserCheckChange}
                      showEcpWarning={showEcpWarning}
                      setShowEcpWarning={setShowEcpWarning}
                    />
                  ),

                  component: RsmAutoComplete,
                },
                FormTextArea: {
                  name: 'FormTextArea',
                  rows: 6,
                  label: t('ShareArticleModal.IncludeMessageLabel'),
                  disabledField: (values: FormValues) =>
                    values?.rsmAutoComplete.length === 0,
                  testId: 'Txt_Insight_ShareMessage',
                  component: FormTextArea,
                  onChange: () => setMessageEdited(true),
                },
                FormCheckboxLabel: {
                  name: 'FormCheckboxLabel',
                  checkboxLabel: 'Send me a copy',
                  sx: {
                    '& .MuiFormControlLabel-label': {
                      color: theme.palette.secondary.main,
                    },
                    '& .MuiSvgIcon-root': {
                      fontSize: '1.75rem',
                    },
                  },
                  component: FormCheckboxLabel,
                },
              }}
              footerComponent={footerComponent()}
              handleSubmit={(values: FormValues) => {
                handleSendArticle(values)
              }}
              rootTestId="Dlg_Insight_ShareModal"
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

ShareArticleModal.defaultProps = defaultProps

export default ShareArticleModal
