/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, styled } from '@mui/material'
import { useDebounce } from 'usehooks-ts'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import RsmPickersDay from '../../../rsmCoreComponents/components/DynamicForm/FormControls/FormDatePicker/RsmPickersDay'
import { Styles } from '../../../types'
import '../../../rsmCoreComponents/components/FiltersGroup/FiltersGroup.css'
import {
  getInvoiceSelectedFilters,
  InvoiceSelectedFiltersConstants,
  setFilterValue,
} from '../../../store/invoices/invoiceSelectedFiltersSlice'
import InvoicesFilterCategoryContainer from './InvoicesFilterCategoryContainer'
import useInvoicesSearch from './useInvoicesSearch'
import tokens from '../../../styles/tokens.json'
import { handleStartDateError, handleEndDateError } from './dateUtils'
import {
  getStartDateError,
  getEndDateError,
  minYear,
  maxYear,
} from './InvoicesDateRangeFilters.service'

const zIndex = 4000
const styles: Styles = {
  datePickerBox: {
    padding: '0.5rem 2.75rem 0.5rem 0.5625rem',
    '& .MuiInputBase-root:focus-within': {
      boxShadow: 'revert !important',
    },
  },
  datePickerTextInput: {
    '& .MuiOutlinedInput-input': {
      textTransform: 'uppercase',
    },
  },
  styledLink: () => ({
    color: 'primary',
  }),
  titleBox: {
    width: '100%',
    paddingRight: '2.75rem',
    justifyContent: 'space-between',
    display: 'flex',
  },
  error: {
    color: tokens.colors.rsmRed.secondary,
    marginLeft: '0.5rem',
    fontSize: '0.75rem',
    lineHeight: '0.75rem',
  },
}

const DatePicker = styled(MuiDatePicker)(() => ({
  '& .MuiPaper-root': {},
}))

interface InvoiceFiltersDateRangeProps {
  filterCategory: string
  clearCategoryFilter: (s: string) => void
  changeFilterAndSearch: (s: string) => void
}

const InvoicesFiltersDateRange = ({
  filterCategory,
  clearCategoryFilter,
  changeFilterAndSearch,
}: InvoiceFiltersDateRangeProps) => {
  const { t } = useTranslation()
  const { changeFilterValue } = useInvoicesSearch()
  const closeOnSelect = true
  const showToolbar = false
  const unmountOnExit = false
  const startDateName = 'startdate'
  const endDateName = 'enddate'
  const CalendarIcon = React.memo(() => <FontAwesomeIcon icon={faCalendar} />)
  const selectedFilters = useSelector(getInvoiceSelectedFilters)
  const [startDateError, setStartDateError] = useState<boolean>(false)
  const [endDateError, setEndDateError] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date | string>('null')
  const [endDate, setEndDate] = useState<Date | string>('null')
  const [startDateDebounceValue, setStartDateDebounce] = useState<
    Date | string
  >('null')
  const [endDateDebounceValue, setEndDateDebounce] = useState<Date | string>(
    'null',
  )
  const startDateDebounce = useDebounce(startDateDebounceValue, 500)
  const endDateDebounce = useDebounce(endDateDebounceValue, 500)
  const localeOptions: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
  }

  const clearDatePickers = () => {
    setStartDate('null')
    setEndDate('null')
  }

  const handleOnChange = () => {
    if (
      selectedFilters.invoiceStartDate &&
      selectedFilters.invoiceEndDate &&
      selectedFilters.invoiceStartDate >= minYear &&
      selectedFilters.invoiceEndDate <= maxYear &&
      selectedFilters.invoiceStartDate <= selectedFilters.invoiceEndDate
    ) {
      const invoiceDateRange = `${selectedFilters.invoiceStartDate.toLocaleDateString(
        'en-US',
        localeOptions,
      )} - ${selectedFilters.invoiceEndDate.toLocaleDateString(
        'en-US',
        localeOptions,
      )}`
      changeFilterAndSearch(
        setFilterValue(
          InvoiceSelectedFiltersConstants.invoiceDates,
          invoiceDateRange,
        ),
      )
    }
  }

  const onDateChange = (filter: string, newDate: Date | string) => {
    const filterString = setFilterValue(filter, newDate)

    if (filter === InvoiceSelectedFiltersConstants.invoiceStartDate) {
      setStartDateDebounce(newDate)
      setStartDate(newDate)
    } else {
      setEndDateDebounce(newDate)
      setEndDate(newDate)
    }

    if (newDate === 'null') {
      changeFilterAndSearch(filterString)
    } else {
      changeFilterValue(filterString)
    }
  }

  useEffect(() => {
    if (startDateDebounce) {
      handleOnChange()
    }
  }, [startDateDebounce])

  useEffect(() => {
    if (endDateDebounce) {
      handleOnChange()
    }
  }, [endDateDebounce])

  useEffect(() => {
    if (selectedFilters.invoiceStartDate === null) setStartDate('null')
  }, [selectedFilters.invoiceStartDate])

  useEffect(() => {
    if (selectedFilters.invoiceEndDate === null) setEndDate('null')
  }, [selectedFilters.invoiceEndDate])

  useEffect(() => {
    setStartDateError(false)
    setEndDateError(false)
  }, [
    selectedFilters.invoiceEndDate,
    selectedFilters.invoiceStartDate,
    startDate,
    endDate,
  ])

  useEffect(() => {
    handleStartDateError(startDate, endDate, setStartDateError)
    handleEndDateError(startDate, endDate, setEndDateError)
  }, [startDate, endDate])

  return (
    <InvoicesFilterCategoryContainer
      filterCategory={filterCategory}
      clearCategoryFilter={() => {
        clearDatePickers()
        clearCategoryFilter(filterCategory)
      }}
      unmountOnExit={unmountOnExit}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <>
          <Box sx={styles.datePickerBox}>
            <DatePicker
              label={t('Invoicing.FilterGroups.startdate')}
              value={
                startDate !== 'null' &&
                startDate !== selectedFilters.invoiceStartDate
                  ? startDate
                  : selectedFilters.invoiceStartDate
              }
              minDate={minYear} // January 1, 1950
              maxDate={endDate === 'null' ? maxYear : endDate}
              inputFormat="MM/dd/yyyy"
              renderDay={(date, selectedDates, pickersDayProps) => (
                <RsmPickersDay {...pickersDayProps} />
              )}
              onChange={(newDate) => {
                const newStartDate =
                  newDate === null ? 'null' : (newDate as Date)
                onDateChange(
                  InvoiceSelectedFiltersConstants.invoiceStartDate,
                  newStartDate,
                )
              }}
              onError={(reason) => {
                if (reason) {
                  setStartDateError(true)
                } else {
                  setStartDateError(false)
                }
              }}
              components={{
                OpenPickerIcon: CalendarIcon,
              }}
              InputProps={{ 'aria-describedby': `${startDateName}InputAlert` }}
              renderInput={(params) => (
                <TextField
                  sx={styles.datePickerTextInput}
                  {...params}
                  error={startDateError}
                  fullWidth
                  id={startDateName}
                  name={startDateName}
                  autoComplete="off"
                  data-testid={`${startDateName}-test-id`}
                />
              )}
              PopperProps={{
                placement: 'bottom',
                'aria-label': 'Calendar',
                style: { zIndex },
              }}
              DialogProps={{
                style: { zIndex },
              }}
              componentsProps={{ actionBar: { sx: { display: 'none' } } }}
              closeOnSelect={closeOnSelect}
              showToolbar={showToolbar}
            />
            {startDateError && (
              <Box
                id={`${startDateName}InputAlert`}
                component="span"
                sx={styles.error}
                role="alert">
                {getStartDateError(selectedFilters.invoiceStartDate)}
              </Box>
            )}
          </Box>
          <Box sx={styles.datePickerBox}>
            <DatePicker
              label={t('Invoicing.FilterGroups.enddate')}
              value={
                endDate !== 'null' && endDate !== selectedFilters.invoiceEndDate
                  ? endDate
                  : selectedFilters.invoiceEndDate
              }
              minDate={startDate}
              maxDate={maxYear}
              inputFormat="MM/dd/yyyy"
              renderDay={(date, selectedDates, pickersDayProps) => (
                <RsmPickersDay {...pickersDayProps} />
              )}
              onChange={(newDate) => {
                const newEndDate = newDate === null ? 'null' : (newDate as Date)
                onDateChange(
                  InvoiceSelectedFiltersConstants.invoiceEndDate,
                  newEndDate,
                )
              }}
              onError={(reason) => {
                if (reason) {
                  setEndDateError(true)
                } else {
                  setEndDateError(false)
                }
              }}
              components={{
                OpenPickerIcon: CalendarIcon,
              }}
              InputProps={{ 'aria-describedby': `${endDateName}InputAlert` }}
              renderInput={(params) => (
                <TextField
                  sx={styles.datePickerTextInput}
                  {...params}
                  error={endDateError}
                  fullWidth
                  id={endDateName}
                  value={selectedFilters.invoiceEndDate}
                  name={endDateName}
                  autoComplete="off"
                  data-testid={`${endDateName}-test-id`}
                />
              )}
              PopperProps={{
                placement: 'bottom',
                'aria-label': 'Calendar',
                style: { zIndex },
              }}
              DialogProps={{
                style: { zIndex },
              }}
              componentsProps={{ actionBar: { sx: { display: 'none' } } }}
              closeOnSelect={closeOnSelect}
              showToolbar={showToolbar}
            />
            {endDateError && (
              <Box
                id={`${endDateName}InputAlert`}
                component="span"
                sx={styles.error}
                role="alert">
                {getEndDateError(selectedFilters.invoiceEndDate)}
              </Box>
            )}
          </Box>
        </>
      </LocalizationProvider>
    </InvoicesFilterCategoryContainer>
  )
}

export default InvoicesFiltersDateRange
