import { FeatureFlag, TargetingFeatureFlag } from '@rsmus/ecp-core-constants'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ProtectedRouteError from '../ProtectedRouteError'
import {
  getUserInfo,
  getReadyState,
  getTargetedFeatureFlags,
  getCemFeatures,
} from '../../store/userInfo/userInfoSlice'
import {
  isTargetedFeatureEnabled,
  isCemFeatureEnabled,
  isFeatureFlagEnabled,
} from '../../rsmCoreComponents/utils/featureFlagUtils'
import Spinner from '../forms/Spinner/Spinner'

const ProtectedRoute = ({
  children,
  requireEmployee,
  requireEmployeeFallback,
  feature,
  featureFallback,
  cemFeature,
  cemFeatureFallback,
  targetedFeature,
  targetedFeatureFallback,
  matchType,
}: {
  children: React.ReactNode
  requireEmployee?: boolean
  requireEmployeeFallback?: React.ReactNode | string
  feature?: FeatureFlag[] | FeatureFlag
  featureFallback?: React.ReactNode | string
  cemFeature?: string[] | string
  cemFeatureFallback?: React.ReactNode | string
  targetedFeature?: TargetingFeatureFlag[] | TargetingFeatureFlag
  targetedFeatureFallback?: React.ReactNode | string
  matchType: 'any' | 'all'
}) => {
  const userInfo = useSelector(getUserInfo)
  const cemFeatures = useSelector(getCemFeatures)
  const targetedFeatureFlags = useSelector(getTargetedFeatureFlags)
  const isReady = useSelector(getReadyState)

  if (!feature && !targetedFeature && !cemFeature) {
    throw new Error(
      'Either feature, cemFeature, or targetedFeature must be specified on the ProtectedRoute component.',
    )
  }

  // If the user info failed to load, show the robot page.
  if (isReady === false) {
    return <ProtectedRouteError />
  }

  // Ensure the user is loaded before checking their access.
  if (isReady === undefined) {
    return <Spinner open={!userInfo?.userType} />
  }

  // Define a function to call when the user does not have access.
  const accessFailed = (fallback: React.ReactNode | string) => {
    if (typeof fallback === 'string' || fallback instanceof String) {
      const navigate = useNavigate()
      navigate(fallback as string)
      return null
    }
    return <> {fallback || null} </>
  }

  // Check if the user must have an employee requirement.
  if (requireEmployee && !userInfo?.isEmployee) {
    return accessFailed(requireEmployeeFallback)
  }

  // Check if the user must have a specified Azure feature flag.
  const featureFlagEnabled =
    !feature || isFeatureFlagEnabled(feature, matchType)
  if (!featureFlagEnabled) {
    return accessFailed(featureFallback)
  }

  // Check if the user must have a specified Azure targeted feature flag.
  const targetedFeatureEnabled =
    !targetedFeature ||
    isTargetedFeatureEnabled(targetedFeature, targetedFeatureFlags, matchType)
  if (!targetedFeatureEnabled) {
    return accessFailed(targetedFeatureFallback)
  }

  // Check if the user must have a specified CEM feature.
  const isInCemFeatures =
    !cemFeature || isCemFeatureEnabled(cemFeature, cemFeatures, matchType)
  if (!isInCemFeatures) {
    return accessFailed(cemFeatureFallback)
  }

  // If the user has access to the component, render it.
  return <> {children} </>
}

ProtectedRoute.defaultProps = {
  requireEmployee: false,
  feature: null,
  cemFeature: null,
  targetedFeature: null,
  requireEmployeeFallback: <ProtectedRouteError />,
  featureFallback: <ProtectedRouteError />,
  cemFeatureFallback: <ProtectedRouteError />,
  targetedFeatureFallback: <ProtectedRouteError />,
}

export default ProtectedRoute
