import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import CallUsIcon from '../icons/CallUsIcon'

import { Styles } from '../../../types'
import { tokens } from '../../../styles/materialTheme'

const style: Styles = {
  card: (theme) => ({
    display: 'flex',
    maxWidth: '27.0625rem',
    width: '100%',
    background: theme.palette.background.paper,
    border: '0.0625rem solid',
    borderColor: tokens.colors.rsmGray.disabled,
    padding: '1.5rem',
    [theme.breakpoints.up('desktop')]: {
      minWidth: '21.875rem',
    },
  }),
  firstCol: {
    flex: '0 1 auto',
    maxWidth: '12.5rem',
  },
  secondCol: {
    display: 'flex',
    flex: '1 1 0%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  head: (theme) => ({
    fontFamily: 'Prelo-Book, sans-serif',
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    marginTop: '0.3125rem',
    color: theme.palette.text.primary,
  }),
  description: (theme) => ({
    fontFamily: 'Prelo-Book, sans-serif',
    fontWeight: 'normal',
    lineHeight: '1.5rem',
    color: theme.palette.text.primary,
    marginTop: '1rem',
    whiteSpace: 'pre-line',

    [theme.breakpoints.up('mobile')]: {
      lineHeight: '1.5rem',
    },
  }),
  phoneLinkStyle: (theme) => ({
    marginLeft: '0.15rem',
    color: theme.palette.secondary.main,
    fontWeight: '800',
    display: 'block',
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    marginTop: '1.25rem',
  }),
  emptyPhoneLinkStyle: {
    marginLeft: '0.15rem',
    display: 'block',
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    marginTop: '1.25rem',
  },
}

export interface CallUsProps {
  supportDescriptionKey: string
  supportPhone: string
  isLoading?: boolean
}

const CallUs = ({
  supportPhone,
  supportDescriptionKey,
  isLoading,
}: CallUsProps) => {
  const { t } = useTranslation()

  return (
    <Box sx={style.card}>
      <Box sx={style.firstCol}>
        <Typography
          variant="h1"
          sx={style.head}
          data-testid="Lbl_Support_CallUsCardTitle">
          {t('CallUs.Title')}
        </Typography>
        <Box
          component="p"
          className="font-prelo-book"
          sx={style.description}
          data-testid="Lbl_Support_CallUsCardDescription">
          {t(supportDescriptionKey)}
        </Box>
        {!isLoading ? (
          <Box sx={style.phoneLinkStyle}>
            <a
              data-testid="Lnk_Support_CallUsCardPhoneNumber"
              href={`tel:${supportPhone?.replaceAll('-', '')}`}
              className="font-prelo-book mobile:text-[1rem] mobile:leading-[1.5rem] text-[1rem] leading-[1.5rem] block mt-[1.25rem] ">
              {supportPhone}
            </a>
          </Box>
        ) : (
          <Box sx={style.emptyPhoneLinkStyle}>XXX-XXX-XXXX</Box>
        )}
      </Box>

      <Box sx={style.secondCol}>
        <CallUsIcon />
      </Box>
    </Box>
  )
}

CallUs.defaultProps = {
  isLoading: false,
}

export default CallUs
