import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress } from '@mui/material'
import Dropdown, { DropdownObject } from '../../Dropdown/Dropdown'
import { Styles } from '../../../types'
import tokens from '../../../styles/tokens.json'

interface MobileMenuProps {
  // Slot for content appearing above dropdown
  headerSlot?: React.ReactNode

  currentSelected: string
  dropdownData: DropdownObject[]
  dropdownName: string
  dropdownAriaLabel: string
  ariaLabel: string
  dropdownMaxWidth?: string
}

const defaultProps = {
  headerSlot: null,
  dropdownMaxWidth: '31.25rem',
}

const styles: Styles = {
  container: {
    marginTop: '1rem',
    borderBottomWidth: 2,
    borderBottomColor: tokens.colors.backgroundGray,
  },

  dropdownContainer: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingBottom: '1rem',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    verticalAlign: 'middle',
    height: '6.125rem',
  },
}

const MobileMenu = ({
  headerSlot,
  dropdownData,
  currentSelected,
  dropdownName,
  dropdownAriaLabel,
  ariaLabel,
  dropdownMaxWidth,
}: MobileMenuProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [dropdownDefault, setDropdownDefault] = useState<DropdownObject>({
    value: '',
    display: t('Navigation.PleaseSelect'),
  })

  const redirectToDropdownValue = (data: string | unknown) => {
    navigate(`${data}`)
  }

  const handleOnKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { keyCode } = e
    // keyCodes 37 = ArrowLeft, 38 = ArrowUp, 39 = ArrowRight, 40 = ArrowDown
    if (keyCode === 37 || keyCode === 38 || keyCode === 39 || keyCode === 40)
      e.preventDefault()
  }

  const parseLocationKey = (path: string): string => {
    const fragments = path?.split('/')
    if (fragments?.length > 1) return fragments[2]
    return path ?? ''
  }

  useEffect(() => {
    let defaultOption = dropdownData[0]
    dropdownData.forEach((item) => {
      if (currentSelected !== undefined) {
        const currentItem = parseLocationKey(item.value)
        if (currentItem.toLowerCase() === currentSelected.toLowerCase()) {
          defaultOption = item
        }
      }
    })
    setDropdownDefault(defaultOption)
  }, [setDropdownDefault, dropdownData, currentSelected])

  return (
    <Box sx={styles.container}>
      {dropdownDefault.value === '' ? (
        <Box sx={styles.loadingContainer} aria-busy="true">
          {' '}
          <CircularProgress size="6.25rem" />{' '}
        </Box>
      ) : (
        <Box sx={styles.dropdownContainer}>
          {headerSlot && { headerSlot }}
          <Box
            component="nav"
            aria-label={ariaLabel}
            sx={{ maxWidth: dropdownMaxWidth }}>
            <Dropdown
              data={dropdownData}
              defaultSelection={dropdownDefault}
              dropdownName={dropdownName}
              ariaLabel={dropdownAriaLabel}
              onCompleteFunction={redirectToDropdownValue}
              onKeyDownFunction={handleOnKeyDown}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

MobileMenu.defaultProps = defaultProps

export default MobileMenu
