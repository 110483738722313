/* eslint-disable react/jsx-props-no-spreading */
import { FormControl } from '@mui/material'
import Select, { SelectProps } from '@mui/material/Select'
import { NotificationFrequencyViewModel } from '@rsmus/ecp-userservice'
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormValues } from '../../../../../rsmCoreComponents/components/DynamicForm/models'
import { RootState } from '../../../../../store'
import { useGetNotificationFrequenciesListQuery } from '../../../../../store/userService'
import { Styles } from '../../../../../types'
import { ArrowDownIcon } from '../../../../icons'

const styles: Styles = {
  formControl: {
    minWidth: '7.5rem',
  },
}

interface NotificationFrequencySelectProps extends SelectProps {
  name: string
}

const NotificationFrequencySelect = ({
  label,
  name,
  required,
  ...rest
}: NotificationFrequencySelectProps) => {
  const { t } = useTranslation()
  const { handleChange, values } = useFormikContext<FormValues>()
  const { data } = useGetNotificationFrequenciesListQuery()

  const [selectedValue, setSelectedValue] = useState<number>(
    (values[name] as number) ?? 1,
  )

  const [filteredNotificationFrequencies, setFilteredNotificationFrequencies] =
    useState<NotificationFrequencyViewModel[]>(data?.data ?? [])

  const selectedContactMethod = useSelector(
    (state: RootState) => state.profileForm.contactMethod,
  )

  useEffect(() => {
    const frequencies = data?.data.filter(
      (item) =>
        item?.contactMethodId?.toString() === selectedContactMethod.toString(),
    )
    setFilteredNotificationFrequencies(frequencies ?? [])
    setSelectedValue(values[name])
  }, [values, data, selectedContactMethod])

  return (
    <FormControl sx={styles.formControl}>
      <Select
        {...rest}
        native
        fullWidth
        IconComponent={ArrowDownIcon}
        name={name}
        value={selectedValue}
        onChange={handleChange}>
        {filteredNotificationFrequencies?.map((item) => (
          <option key={item.id} value={item.id}>
            {`${t(
              `UserProfile.ContactPreferenceForm.NotificationFrequencies.${item.frequencyTextKey}`,
            )}`}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}

export default NotificationFrequencySelect
