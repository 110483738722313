import { OidcIdentityContext, getAccessToken } from '@rsmus/react-auth'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => {
        const s = `00${c.charCodeAt(0).toString(16)}`
        return `%${s.slice(-2)}`
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

const AuthVerify = (props: any) => {
  const { logout } = React.useContext(OidcIdentityContext)
  const location = useLocation()
  const [token, setToken] = useState<string>()

  useEffect(() => {
    const fetchData = async () => {
      setToken((await getAccessToken()) || '')
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (token) {
      const decodedJwt = parseJwt(token)
      if (decodedJwt != null && decodedJwt.exp * 1000 < Date.now()) {
        logout(true)
      }
    }
  }, [location, props, token])
  return <div />
}

export default AuthVerify
