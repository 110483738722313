import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Collapse } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ArrowUpIcon, ArrowDownIcon } from '../../icons'
import '../../../rsmCoreComponents/components/FiltersGroup/FiltersGroup.css'

const FilterTitle = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.125rem',
  fontFamily: 'Prelo-Bold, sans-serif',
}))

const ClearFilters = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '1.125rem',
  fontFamily: 'Prelo-Medium, sans-serif',
  textDecoration: 'underline',
}))

interface InvoicesFilterCategoryContainerProps {
  filterCategory: string
  clearCategoryFilter: (category: string) => void
  children: React.ReactNode
  unmountOnExit?: boolean
}

const defaultProps = {
  unmountOnExit: true,
}

const InvoicesFilterCategoryContainer = ({
  filterCategory,
  clearCategoryFilter,
  children,
  unmountOnExit,
}: InvoicesFilterCategoryContainerProps) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(true)

  return (
    <div className="filtersGroup">
      <div className="filtersHeader">
        <FilterTitle
          id={`clear_${filterCategory}`}
          onClick={() => setExpanded(!expanded)}
          disableRipple
          aria-expanded={expanded}>
          {t(`Invoicing.FilterGroups.${filterCategory}`)}
          {expanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </FilterTitle>
        <ClearFilters
          onClick={() => clearCategoryFilter(filterCategory)}
          disableRipple
          aria-describedby={`clear_${filterCategory}`}
          data-testid={`Btn_Invoice_Clear${filterCategory}`}>
          {t(`Invoicing.Clear`)}
        </ClearFilters>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit={unmountOnExit}>
        {children}
      </Collapse>
    </div>
  )
}

InvoicesFilterCategoryContainer.defaultProps = defaultProps

export default InvoicesFilterCategoryContainer
