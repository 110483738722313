/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  CSSProperties,
} from 'react'
import { debounce, Tooltip } from '@mui/material'
import { useEventListener } from 'usehooks-ts'

export type OverFlowToolTipProps = {
  children: any
  overrideText?: string | undefined
  numberOfLines?: number
  ignoreTabIndex?: boolean
  testId?: string | undefined
  id?: string
}

const OverFlowToolTip = ({
  children,
  overrideText,
  numberOfLines,
  ignoreTabIndex = false,
  testId = undefined,
  id = undefined,
}: OverFlowToolTipProps) => {
  const [isOverflowed, setIsOverflow] = useState(false)
  const textElementRef = useRef(null as unknown as HTMLDivElement)
  // below to override ts error saying object can be undefined
  const numberOfLinesLocal: number = numberOfLines ?? 1

  const title = useMemo(() => {
    if (overrideText && overrideText !== '') {
      return overrideText
    }
    return children
  }, [overrideText, children])

  const tabIndex = useMemo(() => {
    if (isOverflowed === true) {
      return ignoreTabIndex ? { tabIndex: -1 } : { tabIndex: 0 }
    }
    return {}
  }, [isOverflowed])

  const applyStyles = useMemo(() => {
    if (numberOfLinesLocal > 1) {
      return {
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: `${numberOfLinesLocal}`,
        WebkitBoxOrient: 'vertical',
      } as CSSProperties
    }
    return {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    } as CSSProperties
  }, [numberOfLines])

  const setOverflow = debounce(() => {
    if (numberOfLinesLocal > 1) {
      setIsOverflow(
        textElementRef.current?.scrollHeight >
          textElementRef.current?.clientHeight,
      )
    } else {
      setIsOverflow(
        textElementRef.current?.scrollWidth >
          textElementRef.current?.clientWidth,
      )
    }
  }, 30)

  useEffect(() => {
    setOverflow()
  }, [])

  useEventListener('resize', () => setOverflow())

  if (!isOverflowed) {
    return (
      <div
        {...tabIndex}
        id={id}
        ref={textElementRef}
        style={applyStyles}
        data-testid={testId}>
        {children}
      </div>
    )
  }

  return (
    <Tooltip title={title} leaveTouchDelay={2000} enterTouchDelay={0}>
      <div
        {...tabIndex}
        id={id}
        ref={textElementRef}
        style={applyStyles}
        data-testid={testId}>
        {children}
      </div>
    </Tooltip>
  )
}

OverFlowToolTip.defaultProps = {
  overrideText: undefined,
  numberOfLines: 1,
  ignoreTabIndex: false,
  testId: undefined,
  id: undefined,
}

export default OverFlowToolTip
