import React from 'react'

import { v4 as uuidv4 } from 'uuid'

export interface IconProps {
  size?: number
  color?: string
}

const CloseIcon = ({ size = 24, color = '#888B8D' }: IconProps) => {
  const id = uuidv4()
  return (
    <svg
      data-testid="close"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 2.41714L21.5829 0L12 9.58286L2.41714 0L0 2.41714L9.58286 12L0 21.5829L2.41714 24L12 14.4171L21.5829 24L24 21.5829L14.4171 12L24 2.41714Z"
        fill={color}
      />
      <mask
        id={`mask0_${id}`}
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 2.41714L21.5829 0L12 9.58286L2.41714 0L0 2.41714L9.58286 12L0 21.5829L2.41714 24L12 14.4171L21.5829 24L24 21.5829L14.4171 12L24 2.41714Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#mask0_${id})`} />
    </svg>
  )
}

CloseIcon.defaultProps = { size: 24, color: '#888B8D' }

export default CloseIcon
