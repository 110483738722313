import React, { useMemo } from 'react'
import { CircularProgress, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Unavailable from '../../../pages/Unavailable'
import { InvoicesData } from '../../../store/invoices/invoiceSearchService'
import InvoicesTable from './InvoicesTable'
import { Styles } from '../../../types'
import { isCemFeatureEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import {
  CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT,
  CEM_FEATURE_PAYMENT_CLIENT_ADMIN,
} from '../../../utils/constants/constants'
import { getCemFeatures } from '../../../store/userInfo/userInfoSlice'
import { NoSearchFound } from '../../../rsmCoreComponents/components/NoSearchFound/NoSearchFound'
import RsmPagination, {
  RsmPaginationPageSizes,
} from '../../../rsmCoreComponents/components/RsmPagination/RsmPagination'

interface InvoicesResultsProps {
  invoicesData: InvoicesData | undefined
  isInvoicesError: boolean
  isMounted: boolean
  pageNumber: number
  pageSize: RsmPaginationPageSizes
  onPageChange: (pageNumber: number, pageSize: RsmPaginationPageSizes) => void
  onSorting: (sortBy: string, isAscending: 'asc' | 'desc') => void
  sortColumn: string //
  sortDirection: 'asc' | 'desc'
}

const styles: Styles = {
  container: () => ({
    display: 'flex',
    justifyContent: 'center',
    verticalAlign: 'middle',
    height: '2rem',
    marginTop: '2rem',
  }),
}

const InvoicesResults = ({
  invoicesData,
  isInvoicesError,
  pageNumber,
  pageSize,
  onPageChange,
  onSorting,
  sortColumn,
  sortDirection,
  isMounted,
}: InvoicesResultsProps) => {
  const { t } = useTranslation()

  const cemFeatures = useSelector(getCemFeatures)
  const clientAdmin = useMemo(
    () =>
      isCemFeatureEnabled(CEM_FEATURE_PAYMENT_CLIENT_ADMIN, cemFeatures, 'any'),
    [cemFeatures],
  )
  const isAccountManager = useMemo(
    () =>
      isCemFeatureEnabled(
        CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT,
        cemFeatures,
        'any',
      ),
    [cemFeatures],
  )

  if (isInvoicesError) {
    return (
      <Unavailable
        returnUrl={`/invoicing/invoices?key=${new Date().getTime()}`}
      />
    )
  }

  if (invoicesData?.data && invoicesData.data.length === 0) {
    let subTitle = ''
    if (isAccountManager) {
      subTitle = t('Invoicing.NoInvoicesAccountManager')
    } else if (clientAdmin) {
      subTitle = t('Invoicing.NoInvoicesClientAdmin')
    }
    return (
      <NoSearchFound title={t('Invoicing.NoInvoices')} subTitle={subTitle} />
    )
  }

  if (invoicesData) {
    const handleSort = (columnName: string, direction: 'asc' | 'desc') => {
      onSorting(columnName, direction)
    }

    return (
      <>
        <InvoicesTable
          invoicesData={invoicesData}
          onSort={handleSort}
          sortColumn={sortColumn} // Pass the sort column to InvoicesTable
          sortDirection={sortDirection} // Pass the sort direction to InvoicesTable
          isMounted={isMounted} // Pass down if layout component is mounted
        />
        <RsmPagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalRows={invoicesData.totalRows}
          onPageChange={onPageChange}
        />
      </>
    )
  }

  return (
    <Box sx={styles.container} aria-busy="true">
      <CircularProgress color="secondary" data-testid="Spn_Invoices_Loading" />
    </Box>
  )
}

export default InvoicesResults
