import { Box, Link, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RsmLogo } from '../../media'
import { Styles } from '../../../types'

const styles: Styles = {
  container: {
    backgroundColor: '#63666A',
    fontFamily: 'Prelo-Light, sans-serif',
  },
  upperFooter: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'space-between',
    paddingTop: '1.625rem',
    paddingX: '1rem',
    paddingBottom: '0.875rem',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    letterSpacing: '0.0625rem',
    color: '#F7F6F4',
    [theme.breakpoints.only('tablet')]: {
      paddingX: '2rem',
    },
    [theme.breakpoints.up('desktop')]: {
      paddingX: '6.5rem',
    },
  }),
  links: (theme) => ({
    paddingTop: '1.625rem',
    [theme.breakpoints.only('tablet')]: {
      paddingTop: '3.625rem',
    },
    [theme.breakpoints.up('desktop')]: {
      paddingTop: '5.3125rem',
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'inherit',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
  lowerFooter: (theme) => ({
    paddingX: '1rem',
    paddingY: '1.375rem',
    backgroundColor: '#5D6065',
    color: '#F7F6F4',
    [theme.breakpoints.only('tablet')]: {
      paddingX: '2rem',
    },
    [theme.breakpoints.up('desktop')]: {
      paddingX: '6.5rem',
    },
  }),
  lowerFooterText: {
    fontFamily: 'Prelo-Light, sans-serif',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    letterSpacing: '0.0625rem',
  },
  copyright: {
    fontFamily: 'Prelo-Light, sans-serif',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    letterSpacing: '0.0625rem',
    display: 'block',
    marginTop: '1rem',
  },
}

const Footer = () => {
  const { t } = useTranslation()

  return (
    <Box component="footer" sx={styles.container}>
      <Box sx={styles.upperFooter}>
        <RsmLogo variant="white" />
        <Box sx={styles.links}>
          <Link
            role="button"
            data-testid="Lnk_PrivacyPolicy"
            href="https://rsmus.com/pages/rsm-us-privacy-policy.html"
            target="_blank"
            rel="noopener">
            {t('Footer.PrivacyPolicy')}
          </Link>
        </Box>
      </Box>
      <Box sx={styles.lowerFooter}>
        <Typography
          sx={styles.lowerFooterText}
          data-testid="Txt_PrivacyPolicyDisclaimer">
          {t('Footer.Disclaimer')}
        </Typography>
        <Typography
          component="span"
          sx={styles.copyright}
          data-testid="Txt_PrivacyPolicyCopyright">
          {t('Footer.Copyright')}
        </Typography>
      </Box>
    </Box>
  )
}

export default Footer
