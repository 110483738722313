import React from 'react'

export interface IconProps {
    classNames?: string
}

const defaultProps = {
    classNames: ''
}

const WebPageIcon = ({ classNames }: IconProps) => (


    <svg className={classNames} width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M71.9898 65.3895V52H43V65.3895" stroke="#CCCCCC" strokeWidth="2" />
        <path fillRule="evenodd" clipRule="evenodd" d="M69.1007 62.3428L59.6772 59.1675L61.6392 68.9149L63.9507 65.4266L68.3229 70.3919C68.6633 70.778 69.2526 70.8165 69.6388 70.4779C70.025 70.1356 70.0634 69.5482 69.7212 69.162L65.3489 64.1949L69.1007 62.3428Z" fill="#009CDE" stroke="#009CDE" strokeLinecap="round" />
        <path d="M60.9359 65.4173H2.8631C1.83455 65.4173 1 64.5827 1 63.5542V11.8613C1 10.8327 1.83455 10 2.8631 10H77.3086C78.3371 10 79.1698 10.8327 79.1698 11.8613V63.5542C79.1698 64.5827 78.3371 65.4173 77.3086 65.4173H66.4319" stroke="#009CDE" strokeWidth="2" strokeLinecap="round" />
        <path d="M1 16.5186H79.1698" stroke="#009CDE" strokeWidth="2" strokeLinecap="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M76.1433 13.2592C76.1433 14.0315 75.5174 14.6556 74.7469 14.6556C73.9745 14.6556 73.3486 14.0315 73.3486 13.2592C73.3486 12.4887 73.9745 11.8628 74.7469 11.8628C75.5174 11.8628 76.1433 12.4887 76.1433 13.2592Z" stroke="#009CDE" strokeLinecap="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M71.1786 13.2592C71.1786 14.0315 70.5527 14.6556 69.7822 14.6556C69.0117 14.6556 68.3857 14.0315 68.3857 13.2592C68.3857 12.4887 69.0117 11.8628 69.7822 11.8628C70.5527 11.8628 71.1786 12.4887 71.1786 13.2592Z" stroke="#009CDE" strokeLinecap="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M66.2136 13.2592C66.2136 14.0315 65.5877 14.6556 64.8172 14.6556C64.0449 14.6556 63.4189 14.0315 63.4189 13.2592C63.4189 12.4887 64.0449 11.8628 64.8172 11.8628C65.5877 11.8628 66.2136 12.4887 66.2136 13.2592Z" stroke="#009CDE" strokeLinecap="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10.4053 31.4209H39.395V20.3594H10.4053V31.4209Z" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M42.1895 31.4209H63.0295V20.3594H42.1895V31.4209Z" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M65.5918 31.4209H71.1793V20.3594H65.5918V31.4209Z" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" />
        <path d="M10.4048 35.4971H39.3945" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" />
        <path d="M10.4048 41.8999H39.3945" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" />
        <path d="M10.4048 48.3023H39.3945" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" />
        <path d="M42.1885 35.4971H71.1782" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" />
        <path d="M42.1885 41.8999H71.1782" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" />
        <path d="M42.1885 48.3023H71.1782" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" />
        <path d="M10.4048 54.7066H39.3945" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" />
        <path d="M10.4048 61.1094H39.3945" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" />
    </svg>


)

WebPageIcon.defaultProps = defaultProps
export default WebPageIcon
