import { Box } from '@mui/material'
import React from 'react'
import { Styles } from '../../../types'
import { ArticleMetaData } from '../../../utils/helpers/Article.service'
import ArticleListItem from '../ArticleListItem'
import ArticleListNoSearchResults from './ArticleListNoSearchResults'

const styles: Styles = {
  container: (theme) => ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gridGap: '1rem',
    [theme.breakpoints.only('tablet')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.up('desktop')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    '@media (min-width: 112.5rem)': {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  }),
}

interface ArticleListProps {
  filter: string
  articleListMetaData: ArticleMetaData[]
  focusIndex?: number
}

const defaultProps = {
  focusIndex: 0,
}

const ArticleList = ({
  filter,
  articleListMetaData,
  focusIndex,
}: ArticleListProps) =>
  articleListMetaData?.length > 0 ? (
    <Box sx={styles.container} data-testid="articleListContainer">
      {articleListMetaData?.map((article: ArticleMetaData, index) => (
        <ArticleListItem
          article={article}
          articleIndex={index}
          articleListLength={articleListMetaData.length}
          focusIndex={focusIndex}
          key={`article-${article.ecpId}`}
        />
      ))}
    </Box>
  ) : (
    <ArticleListNoSearchResults filter={filter} />
  )

ArticleList.defaultProps = defaultProps

export default ArticleList
