import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  ClientScheduledPaymentViewModel,
  InstallmentPaymentDetailsViewModel,
  ScheduledInvoiceViewModel,
  PaymentInstallmentTypeEnum,
} from '@rsmus/ecp-financeservice'
import {
  Box,
  Select,
  FormControl,
  CircularProgress,
  SelectChangeEvent,
  Button,
  Tooltip,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { format, formatDistanceStrict } from 'date-fns'
import {
  setScheduledPaymentCount,
  getScheduledPaymentCount,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import SectionHeader from '../../layouts/SectionHeader'
import { tokens } from '../../../styles/materialTheme'
import { Styles } from '../../../types'
import { ArrowDownIcon } from '../../icons'
import api from '../../../api'
import {
  formatCurrency,
  formatDate,
} from '../../../rsmCoreComponents/utils/formatters'
import { InvoiceSelectedFiltersConstants } from '../../../store/invoices/invoiceSelectedFiltersSlice'
import DownloadIcon from '../../icons/DownloadIcon'
import downloadFileStream from '../../../rsmCoreComponents/utils/fileStreamUtils'
import { NoSearchFoundsmall } from '../../../rsmCoreComponents/components/NoSearchFoundSmall/NoSearchFoundSmall'
import ScheduledPaymentDateChangeDialog from './ScheduledPaymentDateChangeDialog'
import CancelScheduleInvoicesDialog from '../Invoices/CancelScheduleInvoicesDialog'

const styles: Styles = {
  cardContainer: (theme) => ({
    background: theme.palette.common.white,
    border: `0.0625rem solid ${tokens.colors.rsmGray.disabled}`,
    padding: '1.5rem',
    fontFamily: 'prelo-book',
    fontWeight: 500,
  }),
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    minHeight: 50,
  },
  Download: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 50,
  },
  NoData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ButtonDownload: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 50,
    width: '100%',
    fontSize: '1rem',
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.only('mobile')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.only('tablet')]: {
      fontSize: '0.8rem',
    },
  }),
  savedPaymentMethodsHeader: {
    display: 'inline-block',
    fontWeight: 800,
    marginBottom: '0.5rem',
    marginRight: '0.5rem',
    marginTop: '0.5rem',
    color: tokens.colors.rsmBlue.accessibility,
  },
  NoClientHeader: {
    textAlign: 'center',
    display: 'inline-block',
    fontWeight: 700,
    fontSize: '0.875rem',
    paddingTop: '1rem',
    color: tokens.colors.rsmBlue.accessibility,
    width: '100%',
  },
  NoRecordsHeader: (theme) => ({
    display: 'inline-block',
    fontWeight: 700,
    fontSize: '0.875rem',
    paddingTop: '1rem',
    paddingRight: '2.5rem',
    paddingLeft: '3.5rem',
    [theme.breakpoints.only('mobile')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
    color: tokens.colors.rsmBlue.accessibility,
    width: '100%',
  }),
  SchedulepaymentTypeSelect: () => ({
    '.MuiOutlinedInput-root': {
      width: '100%',
      maxWidth: '100%',
    },
  }),
  cellTitle: (theme) => ({
    fontWeight: 400,
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    [theme.breakpoints.only('mobile')]: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
  }),
  amount: (theme) => ({
    fontWeight: 700,
    fontSize: '1.75rem',
    lineHeight: '1.75rem',
    marginBottom: '.5rem',
    [theme.breakpoints.only('mobile')]: {
      fontSize: '1.375rem',
      lineHeight: '1.5rem',
    },
  }),
}

const ScheduledPayments = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const scheduledPaymentCount = useSelector(getScheduledPaymentCount)
  const { clientId = '' } = useParams<{ clientId: string }>()
  const [openChangeDateDialog, setOpenChangeDateDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [clientScheduledPayments, setClientScheduledPayments] = useState<
    ClientScheduledPaymentViewModel[]
  >([])
  const [scheduledInvoicesData, setScheduledInvoicesData] = useState<
    ScheduledInvoiceViewModel[]
  >([])
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedPlanId, setSelectedPlanId] = useState<number | undefined>(
    undefined,
  )
  const [planDetails, setPlanDetails] = useState<
    InstallmentPaymentDetailsViewModel | undefined
  >(undefined)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [focused, setFocused] = useState<boolean>(false)

  const fetchPlanDetails = useCallback(async (planId: number) => {
    if (planId) {
      try {
        const { data } = await api.finance.payment_GetPlanDetails(planId)

        setPlanDetails(data)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch plan details', error)
      }
    }
  }, [])

  const loadScheduledPaymentData = useCallback(async () => {
    setIsLoading(true)
    try {
      if (clientId) {
        const { data: scheduledPaymentDates } =
          (await api.finance.payment_GetClientScheduledPayments(clientId)) || []

        const paymentDatesArray: ClientScheduledPaymentViewModel[] =
          scheduledPaymentDates ?? []

        setClientScheduledPayments(paymentDatesArray)
        setSelectedPlanId(paymentDatesArray[0]?.installmentPlanId)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to load scheduled payments', error)
    } finally {
      setIsLoading(false)
    }
  }, [clientId, fetchPlanDetails])

  const scrollToInput = (scopbuttonRef?: HTMLButtonElement | null) => {
    if (scopbuttonRef) {
      const boundingRect = scopbuttonRef?.getBoundingClientRect()
      if (typeof boundingRect.top === 'number') {
        const offset = -150 // '-12.5rem'; // Adjust this value according to your header height
        const elementPosition = boundingRect.top + window.scrollY

        const offsetPosition = elementPosition + offset
        // Delay the scroll action by a small duration
        setTimeout(() => {
          window.scrollTo({
            top: offsetPosition,
            behavior: 'auto', // Use 'auto' instead of 'smooth'
          })
        }, 30) // Adjust this delay duration if needed
      }
    }
  }

  const handleClose = () => {
    setFocused(true)
    buttonRef?.current?.focus()
    setIsDialogOpen(false) // Close the dialog
    scrollToInput(buttonRef?.current)
  }

  const handleConfirmDialog = useCallback(async (): Promise<void> => {
    buttonRef?.current?.focus()
    setIsDialogOpen(false) // Close the dialog
    loadScheduledPaymentData()
    if (!focused) {
      setFocused(true)
    }
  }, [loadScheduledPaymentData, planDetails])

  const handleCancelPlanId = useCallback(async (): Promise<void> => {
    if (selectedPlanId !== undefined) {
      try {
        const { data } = await api.finance.payment_GetScheduledInvoicesByPlanId(
          selectedPlanId,
        )
        setScheduledInvoicesData(data ?? [])
      } catch (error) {
        console.error('Failed to fetch scheduled invoices', error)
      } finally {
        setIsLoading(false)
      }
    }
  }, [selectedPlanId])

  useEffect(() => {
    loadScheduledPaymentData()
  }, [clientId, loadScheduledPaymentData])

  useEffect(() => {
    if (selectedPlanId) {
      fetchPlanDetails(selectedPlanId)
      handleCancelPlanId()
    }
  }, [selectedPlanId, fetchPlanDetails])

  useEffect(() => {
    if (scheduledPaymentCount === 1) {
      setClientScheduledPayments([])
      setSelectedPlanId(0)
      loadScheduledPaymentData()
      dispatch(setScheduledPaymentCount(0))
    }
  }, [scheduledPaymentCount, dispatch, loadScheduledPaymentData])

  const handleChange = (event: SelectChangeEvent<number>) => {
    const planId = +event.target.value
    setSelectedPlanId(planId)
  }

  const handleDownloadScheduledPlanDetails = useCallback(
    async (transactionId: string) => {
      setIsLoading(true)
      const response =
        await api.finance.invoiceDocument_DownloadScheduledPaymentReceipt(
          transactionId,
        )
      downloadFileStream(response)
      setIsLoading(false)
    },
    [
      api.finance.invoiceDocument_DownloadScheduledPaymentReceipt,
      downloadFileStream,
    ],
  )

  const handleDownloadInstallmentPlanDetails = useCallback(
    async (installmentPlanId: number) => {
      setIsLoading(true)
      const response =
        await api.finance.invoiceDocument_DownloadInstallmentPlanSummary(
          installmentPlanId,
        )
      downloadFileStream(response)
      setIsLoading(false)
    },
    [
      api.finance.invoiceDocument_DownloadInstallmentPlanSummary,
      downloadFileStream,
    ],
  )

  return (
    <Box sx={styles.cardContainer}>
      <SectionHeader
        title={t('Invoicing.ScheduledPayment.ScheduledPaymentTitle')}
        testId="Lbl_ScheduledPayments_Header"
      />
      {isLoading ? (
        <Box sx={styles.loading}>
          <CircularProgress
            size="3rem"
            color="secondary"
            data-testid="Spn_AccountInformation_Loading"
          />
        </Box>
      ) : (
        <Box>
          {planDetails && clientScheduledPayments.length !== 0 && (
            <Box component="h3" sx={styles.savedPaymentMethodsHeader}>
              {t('Invoicing.ScheduledPayment.ScheduledPaymentSelect')}
            </Box>
          )}
          {planDetails && clientScheduledPayments.length !== 0 ? (
            <Box>
              <FormControl fullWidth sx={{ paddingBottom: '1rem' }}>
                <Select
                  native
                  sx={styles.SchedulepaymentTypeSelect}
                  id="SelectScheduledPayment"
                  IconComponent={ArrowDownIcon}
                  value={selectedPlanId}
                  onChange={handleChange}
                  inputProps={{
                    'aria-label': t(
                      'Invoicing.ScheduledPayment.ScheduledPaymentSelect',
                    ),
                    'aria-required': true,
                    'data-testid': 'Sel_PaymentType',
                    'aria-describedby': 'paymentSelectInstructions',
                  }}>
                  <option style={{ display: 'none' }} label=" " />
                  {clientScheduledPayments.map((schedulePaymentDate) => {
                    let messageName = ''
                    switch (
                      schedulePaymentDate.scheduledPaymentType.toLowerCase()
                    ) {
                      case PaymentInstallmentTypeEnum.Installments.toLowerCase():
                        messageName =
                          'Invoicing.ScheduledPayment.InstallmentPaymentDateSelect'
                        break
                      case PaymentInstallmentTypeEnum.Scheduled.toLowerCase():
                        messageName =
                          'Invoicing.ScheduledPayment.ScheduledPaymentDateSelect'
                        break
                      default:
                        return null
                    }
                    const displayText = t(messageName, {
                      name: format(
                        new Date(schedulePaymentDate.createdDate),
                        'MM/dd/yyyy',
                      ),
                    })
                    return (
                      <option
                        key={schedulePaymentDate.installmentPlanId}
                        data-testid={schedulePaymentDate.installmentPlanId}
                        value={schedulePaymentDate.installmentPlanId}>
                        {displayText}
                      </option>
                    )
                  })}
                </Select>
              </FormControl>
              <span
                id="paymentSelectInstructions"
                className="sr-only"
                aria-hidden="true">
                {t('Invoicing.ScheduledPayment.paymentSelectInstructions')}
              </span>
              <Box component="h3" sx={styles.savedPaymentMethodsHeader}>
                {planDetails.totalInstallmentCount > 1
                  ? t('Invoicing.ScheduledPayment.InstallmentPaymentDate')
                  : t('Invoicing.ScheduledPayment.ScheduledPaymentDate')}
              </Box>
              <Box sx={styles.cellTitle}>
                {planDetails && (
                  <Box>
                    {formatDate(
                      planDetails.nextPaymentDate.toLocaleDateString(),
                    )}
                  </Box>
                )}
              </Box>
              <Box sx={styles.amount}>
                {planDetails && (
                  <Box>
                    {formatCurrency(
                      planDetails.nextPaymentAmount ?? 0,
                      InvoiceSelectedFiltersConstants.DefaultCurrency,
                    )}{' '}
                    {InvoiceSelectedFiltersConstants.DefaultCurrency.toUpperCase()}
                  </Box>
                )}
              </Box>
              {planDetails.totalInstallmentCount > 1 && (
                <Box>
                  {t('Invoicing.ScheduledPayment.InstallmentNumber', {
                    curentNumber: planDetails.currentInstallmentNumber,
                    totalNumber: planDetails.totalInstallmentCount,
                  })}
                </Box>
              )}
              <Box component="h3" sx={styles.savedPaymentMethodsHeader}>
                {planDetails.totalInstallmentCount > 1
                  ? t('Invoicing.ScheduledPayment.InstallmentPlanDetails')
                  : t('Invoicing.ScheduledPayment.ScheduledPaymentDetails')}
              </Box>
              {planDetails.totalInstallmentCount > 1 && (
                <Box>
                  <Box>
                    {t('Invoicing.ScheduledPayment.InstallmentPlanLength', {
                      length: formatDistanceStrict(
                        planDetails.endDate.getTime(),
                        planDetails.startDate.getTime(),
                      ),
                    })}
                  </Box>
                  <Box>
                    {t('Invoicing.ScheduledPayment.InstallmentPlanStart', {
                      startDate: formatDate(
                        planDetails.startDate.toLocaleDateString(),
                      ),
                    })}
                  </Box>
                  <Box>
                    {t('Invoicing.ScheduledPayment.InstallmentPlanEnd', {
                      endDate: formatDate(
                        planDetails.endDate.toLocaleDateString(),
                      ),
                    })}
                  </Box>
                </Box>
              )}
              <Box>
                {planDetails && (
                  <Box>
                    {t('Invoicing.ScheduledPayment.PaymentType', {
                      name:
                        planDetails.paymentMethod === 1
                          ? t('Invoicing.ScheduledPayment.BankAccount')
                          : planDetails.paymentType,
                      num: planDetails.accountNumberSuffix,
                    })}
                  </Box>
                )}
              </Box>
              <Box>
                {planDetails && (
                  <Box>
                    {planDetails.clientIds &&
                    planDetails.clientIds.length > 3 ? (
                      <Tooltip title={planDetails.clientIds.join(', ')}>
                        <Box>
                          {t('Invoicing.ScheduledPayment.AccountsonPayment', {
                            name: `${planDetails.clientIds
                              .slice(0, 3)
                              .join(', ')},...`,
                          })}
                        </Box>
                      </Tooltip>
                    ) : (
                      <Box>
                        {t('Invoicing.ScheduledPayment.AccountsonPayment', {
                          name: planDetails.clientIds?.join(', '),
                        })}
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
              <Box>
                {planDetails && (
                  <Box>
                    {planDetails.invoiceNumbers &&
                    planDetails.invoiceNumbers.length > 3 ? (
                      <Tooltip title={planDetails.invoiceNumbers.join(', ')}>
                        <Box>
                          {t('Invoicing.ScheduledPayment.InvoicesPaid', {
                            name: `${planDetails.invoiceNumbers
                              .slice(0, 3)
                              .join(', ')},...`,
                          })}
                        </Box>
                      </Tooltip>
                    ) : (
                      <Box>
                        {t('Invoicing.ScheduledPayment.InvoicesPaid', {
                          name: planDetails.invoiceNumbers?.join(', '),
                        })}
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
              {planDetails && (
                <Box sx={{ display: 'flex', paddingBottom: '2rem' }}>
                  <Box>{t('Invoicing.ScheduledPayment.DownloadDetails')}</Box>
                  <Box sx={{ paddingLeft: '1.5rem' }}>
                    <Button
                      sx={{ padding: 0 }}
                      data-testid="Btn_ScheduledPayments_DownloadDetails"
                      component="span"
                      aria-label={t(
                        'Invoicing.ScheduledPayment.DownloadPaymentDetails',
                      )}
                      onClick={() => {
                        if (planDetails.installmentPlanId === null) return null
                        if (planDetails.totalInstallmentCount > 1)
                          return handleDownloadInstallmentPlanDetails(
                            planDetails.installmentPlanId,
                          )
                        return handleDownloadScheduledPlanDetails(
                          planDetails.firstTransactionId ?? '',
                        )
                      }}
                      disableFocusRipple
                      disableRipple>
                      <DownloadIcon style={{ cursor: 'pointer' }} />
                    </Button>
                  </Box>
                </Box>
              )}
              <Box sx={styles.Download}>
                <Button
                  sx={styles.ButtonDownload}
                  size="small"
                  aria-label={t('Invoicing.ScheduledPayment.ChangePaymentDate')}
                  variant="outlined"
                  disabled={!planDetails}
                  onClick={() => {
                    setOpenChangeDateDialog(true)
                  }}>
                  {t('Invoicing.ScheduledPayment.ChangePaymentDate')}
                </Button>
                <ScheduledPaymentDateChangeDialog
                  open={openChangeDateDialog}
                  onClose={() => {
                    setOpenChangeDateDialog(false)
                    fetchPlanDetails(selectedPlanId ?? 0)
                  }}
                  paymentInstallmentPlanId={planDetails?.installmentPlanId ?? 0}
                  planName={
                    t('Invoicing.ScheduledPayment.ScheduledPaymentDateSelect', {
                      name: format(
                        new Date(
                          clientScheduledPayments.find(
                            (x) =>
                              x.installmentPlanId ===
                              planDetails?.installmentPlanId,
                          )?.createdDate ?? new Date(),
                        ),
                        'MM/dd/yyyy',
                      ),
                    }) ?? ''
                  }
                  existingPaymentDate={
                    planDetails?.nextPaymentDate ?? new Date()
                  }
                />
              </Box>
              <Box sx={styles.Download} style={{ paddingTop: '1.5rem' }}>
                <Button
                  sx={styles.ButtonDownload}
                  ref={buttonRef}
                  disableFocusRipple
                  disableRipple
                  size="small"
                  aria-label={
                    planDetails.planTypeId > 1
                      ? t('Invoicing.ScheduledPayment.CancelInstallmentPayment')
                      : t('Invoicing.ScheduledPayment.CancelScheduledPayment')
                  }
                  onClick={() => {
                    setIsDialogOpen(true)
                    buttonRef.current?.focus()
                    setFocused(true)
                  }}
                  variant="outlined">
                  {planDetails.planTypeId > 1
                    ? t('Invoicing.ScheduledPayment.CancelInstallmentPayment')
                    : t('Invoicing.ScheduledPayment.CancelScheduledPayment')}
                </Button>
                <CancelScheduleInvoicesDialog
                  open={isDialogOpen}
                  onClose={() => handleClose()}
                  onConfirm={() => handleConfirmDialog()}
                  scheduledInvoicesData={scheduledInvoicesData}
                  selectedPlanId={selectedPlanId ?? 0}
                  isScheduled={planDetails.planTypeId === 1}
                  planDate={format(
                    new Date(
                      clientScheduledPayments.find(
                        (x) => x.installmentPlanId === selectedPlanId ?? 0,
                      )?.createdDate ?? new Date(),
                    ),
                    'MM/dd/yyyy',
                  )}
                />
              </Box>
            </Box>
          ) : (
            <Box>
              <Box sx={styles.NoData}>
                <NoSearchFoundsmall />
              </Box>
              <Box
                component="span"
                sx={clientId ? styles.NoRecordsHeader : styles.NoClientHeader}>
                {clientId
                  ? t('Invoicing.ScheduledPayment.NoRecordsData')
                  : t('Invoicing.ScheduledPayment.NoRecordsClientAdmin')}
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

export default ScheduledPayments
