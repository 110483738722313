import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  getPaymentSelectedFilters,
  PaymentSelectedFiltersConstants,
  PaymentSelectedFiltersState,
  setFilterValue,
  getAmountRangeFilterMax,
} from '../../../store/invoices/paymentSelectedFiltersSlice'
import FilterChips, { FilterChipData } from '../../FilterChips/FilterChips'
import { formatCurrencyNoDecimal } from '../../../rsmCoreComponents/utils/formatters'

let maxTranslation = 'default'
const getChipsData = (
  filters: PaymentSelectedFiltersState,
  amountRangeFilterMax: number,
): FilterChipData<string>[] => {
  // Check if each filter has values and add it to the array.
  const chipsData = []

  if (filters.paymentStatus?.length > 0) {
    const statuses = filters.paymentStatus.map((i: string) => ({
      property: PaymentSelectedFiltersConstants.paymentStatus,
      value: i,
    }))
    chipsData.push(...statuses)
  }

  if (filters.paymentDateRange.length > 0) {
    chipsData.push({
      property: PaymentSelectedFiltersConstants.paymentDates,
      value: filters.paymentDateRange,
    })
  }

  // Special Handling for Currency Range
  if (filters.paymentAmountFrom || filters.paymentAmountTo) {
    const toValue =
      filters.paymentAmountTo === amountRangeFilterMax
        ? maxTranslation
        : formatCurrencyNoDecimal(filters.paymentAmountTo, filters.currency)
    chipsData.push({
      property: PaymentSelectedFiltersConstants.paymentAmountFrom,
      value: `${formatCurrencyNoDecimal(
        filters.paymentAmountFrom,
        filters.currency,
      )} - ${toValue}`,
    })
  }

  return chipsData as FilterChipData<string>[]
}

interface PaymentsChipsProps {
  changeFilterAndSearch: (filterValue: string) => void
  clearCategoryFilter: (category: string) => void
  clearAllFilters: () => void
}

const PaymentsChips = ({
  changeFilterAndSearch,
  clearCategoryFilter,
  clearAllFilters,
}: PaymentsChipsProps) => {
  const { t } = useTranslation()
  const selectedFilters = useSelector(getPaymentSelectedFilters)
  const amountRangeFilterMax = useSelector(getAmountRangeFilterMax)

  const [filterChipsData, setFilterChipsData] = useState<
    FilterChipData<string>[]
  >([])

  useEffect(() => {
    maxTranslation = t(`Invoicing.Max`)
    const chipsData = getChipsData(selectedFilters, amountRangeFilterMax)
    setFilterChipsData(chipsData)
  }, [selectedFilters])

  const handleDeleteChip = useCallback(
    (chipData: FilterChipData<string>) => {
      // Special Handling for Currency Range
      if (
        chipData.property === PaymentSelectedFiltersConstants.paymentAmountFrom
      ) {
        clearCategoryFilter(PaymentSelectedFiltersConstants.paymentAmounts)
        return
      }

      if (chipData.property === PaymentSelectedFiltersConstants.paymentDates) {
        clearCategoryFilter(PaymentSelectedFiltersConstants.paymentDates)
        return
      }

      changeFilterAndSearch(
        setFilterValue(chipData.property as string, chipData.value),
      )
    },
    [clearCategoryFilter, changeFilterAndSearch, setFilterValue],
  )

  return (
    <FilterChips<string>
      filtersSelected={filterChipsData}
      filterChipTranslationKeyPrefix="Invoicing.FilterGroups"
      onChipDeleted={handleDeleteChip}
      onClearAll={clearAllFilters}
      clearAllFocusQuerySelector="#payments-filter-chips-clear-all-focus-target input"
    />
  )
}

export default PaymentsChips
