import React, { useRef, useEffect, useState } from 'react'
import {
  Box,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Stack,
  styled,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import {
  ScheduledInvoicesPaymentViewModel,
  PayerPaymentAccountViewModel,
  PaymentInstallmentTypeEnum,
} from '@rsmus/ecp-financeservice'
import api from '../../../api'
import { ArrowDownIcon } from '../../icons'
import ResponsiveModal from '../../../rsmCoreComponents/components/ResponsiveModal'
import { Styles } from '../../../types'
import AlertIconCircle from '../../icons/AlertIcon/AlertIconCircle'

export type PaymentMethod = PayerPaymentAccountViewModel & {
  isBankAccount: boolean
  index: number
}

interface ConfirmPaymentMethodDeleteDialogProps {
  Dialogtext: string
  Subtext1: string
  Subtext2: string
  buttonCloseText: string
  clientId: number
  open: boolean
  onClose: () => void
  onSuccess: () => void
  onError: () => void
  scheduledInvoicesData: ScheduledInvoicesPaymentViewModel[]
  PayerPaymentAccountsData: PayerPaymentAccountViewModel[]
  paymentMethod: PaymentMethod
}

const styles: Styles = {
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    maxHeight: '100%',
    height: '100%',
    border: '0',
    padding: '0.5rem',
  },
  subText: {
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    marginTop: '2rem',
    marginBottom: '1rem',
    border: '0',
    textAlign: 'center',
    padding: '0.5rem',
  },
  subTextLineItems: {
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    marginTop: '2rem',
    marginBottom: '1rem',
    border: '0',
    textAlign: 'left',
    padding: '0.5rem',
    fontWeight: 'bold',
    paddingLeft: '1.5rem',
  },
  scrollableContainer: {
    maxHeight: '10rem',
    overflowY: 'auto',
    width: '100%',
    border: '0',
  },
  acceptWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.5rem',
    paddingTop: '0.5rem',
    border: '0',
  },
  choosePaymentMethod: (theme) => ({
    border: '0',
    '.MuiOutlinedInput-root': {
      width: '100%',
      maxWidth: '100%',
      border: '0',
      padding: '0.5rem',
    },
    '.MuiTableCell-root': {
      border: '0',
    },
    '.MuiSelect-select': { fontFamily: 'Prelo-Book !important' },
    width: 'calc(100% - 4rem)',
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
    },
  }),
  selectContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    border: '0',
  },
}

const StyledLabel = styled('label')(() => ({
  fontFamily: 'Prelo-Book, sans-serif',
  fontWeight: '700',
  fontSize: '1.125rem',
  padding: '0.5rem',
  paddingLeft: '2rem',
  color: '#2167AE',
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  width: '100%',
  border: '0',
}))

const ConfirmPaymentMethodDeleteDialog = ({
  Dialogtext,
  Subtext1,
  Subtext2,
  buttonCloseText,
  clientId,
  open,
  onClose,
  onSuccess,
  onError,
  scheduledInvoicesData,
  PayerPaymentAccountsData,
  paymentMethod,
}: ConfirmPaymentMethodDeleteDialogProps) => {
  const { t } = useTranslation()
  const desktopMaxWidth = '46rem'

  // State to manage the max height
  const [desktopMaxHeight, setDesktopMaxHeight] = useState('40rem')
  const [selectedItem, setSelectedItem] = useState<string | undefined>(
    undefined,
  )

  // Ref to get the content height
  const contentRef = useRef<HTMLTableElement>(null)
  const selectRef = useRef<HTMLSelectElement>(null)

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight
      const newHeight = Math.min(contentHeight + 80, 640) // max height 640px (40rem)
      const newHeightInRem = newHeight / 16 // convert to rem
      setDesktopMaxHeight(`${newHeightInRem}rem`)
    }
  }, [scheduledInvoicesData, open])

  const handleConfirmDelete = async () => {
    try {
      if (typeof selectedItem === 'string') {
        const selectedItemId = parseInt(selectedItem, 10)
        const response = await api.finance.payment_ChangePaymentMethod(
          paymentMethod.id,
          selectedItemId,
          clientId,
        )

        if (response.data?.deleteSuccessful) {
          onClose()
          onSuccess()
        }
      }
    } catch (e: any) {
      onError()
    }
  }

  return (
    <Box className="Rsm-ProfileModal">
      <ResponsiveModal
        open={open}
        onClose={onClose}
        aria-label={Dialogtext}
        desktopMaxHeight={desktopMaxHeight}
        desktopMaxWidth={desktopMaxWidth}
        removeHeaderSpacing>
        <Table ref={contentRef}>
          <TableBody>
            <TableRow>
              <TableCell sx={styles.dialogContent}>
                <AlertIconCircle />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles.subText}>{Subtext1}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ border: '0' }}>
                <Box
                  sx={{
                    ...styles.scrollableContainer,
                    display: 'grid',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  {scheduledInvoicesData.map((invoice) => (
                    <TableRow
                      key={invoice.createdDate.toString()}
                      id={invoice.scheduledPaymentType}>
                      <TableCell sx={styles.subTextLineItems}>
                        <span
                          style={{ marginRight: '0.5rem', fontWeight: 'bold' }}>
                          •
                        </span>
                        {invoice.scheduledPaymentType.toLowerCase() ===
                        PaymentInstallmentTypeEnum.Installments.toLowerCase()
                          ? t(
                              'Invoicing.PaymentManagement.ConfirmDeletePaymentMethod.InstallmentPaymentDateSelect',
                              {
                                name: format(
                                  new Date(invoice.createdDate),
                                  'MM/dd/yyyy',
                                ),
                              },
                            )
                          : t(
                              'Invoicing.PaymentManagement.ConfirmDeletePaymentMethod.ScheduledPaymentDateSelect',
                              {
                                name: format(
                                  new Date(invoice.createdDate),
                                  'MM/dd/yyyy',
                                ),
                              },
                            )}
                      </TableCell>
                    </TableRow>
                  ))}
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ border: '0', padding: '0rem' }}>
                {PayerPaymentAccountsData.length > 1 && (
                  <StyledLabel htmlFor="SelectPayer">
                    {t(
                      'Invoicing.ScheduledinvoicesDialog.SelectReplacementLabel',
                    )}
                  </StyledLabel>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ border: '0', padding: '0rem' }}>
                <Box sx={styles.selectContainer}>
                  {PayerPaymentAccountsData.length > 1 && (
                    <Select
                      name="SelectPaymentMethod"
                      native
                      sx={styles.choosePaymentMethod}
                      IconComponent={ArrowDownIcon}
                      inputRef={selectRef}
                      value={selectedItem || ''}
                      onChange={(e) => setSelectedItem(e.target.value)}
                      aria-label={t(
                        'Invoicing.ScheduledinvoicesDialog.SelectReplacement',
                      )}>
                      <option style={{ display: 'none' }} label=" " />
                      {PayerPaymentAccountsData.filter(
                        (account) => account.id !== paymentMethod.id,
                      ).map((paymentAccount: PayerPaymentAccountViewModel) => (
                        <option
                          key={paymentAccount.id}
                          data-testid={paymentAccount.id}
                          value={paymentAccount.id.toString()}>
                          {paymentAccount.paymentMethod === 1
                            ? t(
                                'Invoicing.PaymentManagement.ConfirmDeletePaymentMethod.BankAccountEndingIn',
                                {
                                  accountNumber:
                                    paymentAccount.accountNumberSuffix,
                                },
                              )
                            : t(
                                'Invoicing.PaymentManagement.ConfirmDeletePaymentMethod.CreditCardEndingIn',
                                {
                                  cardType: paymentAccount.institutionName,
                                  cardNumber:
                                    paymentAccount.accountNumberSuffix,
                                },
                              )}
                        </option>
                      ))}
                    </Select>
                  )}
                </Box>
              </TableCell>
            </TableRow>
            {PayerPaymentAccountsData.length <= 1 && (
              <TableRow>
                <TableCell sx={styles.subText}>{Subtext2}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell sx={styles.acceptWrapper}>
                <Stack sx={styles.acceptWrapper}>
                  <Button variant="outlined" onClick={onClose}>
                    {buttonCloseText}
                  </Button>
                  {PayerPaymentAccountsData.length > 1 && (
                    <Button
                      variant="contained"
                      onClick={handleConfirmDelete}
                      disabled={!selectedItem}>
                      {t('Invoicing.ScheduledinvoicesDialog.ConfirmDelete')}
                    </Button>
                  )}
                </Stack>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ResponsiveModal>
    </Box>
  )
}

export default ConfirmPaymentMethodDeleteDialog
