import React, { useState } from 'react'
import { useGetTopicListQuery } from '../../../store/cmsService'
import AvatarMaleIcon from '../../icons/AvatarMaleIcon'
import BlockchainIcon from '../../icons/BlockchainIcon'
import CertificateIcon from '../../icons/CertificateIcon'
import ChainLinkIcon from '../../icons/ChainLinkIcon'
import ChartIcon from '../../icons/ChartIcon'
import ChartLineIcon from '../../icons/ChartLineIcon'
import CryptocurrencyIcon from '../../icons/CryptocurrencyIcon'
import DecisionIcon from '../../icons/DecisionIcon'
import DollarPieChartIcon from '../../icons/DollarPieChartIcon'
import EarthIcon from '../../icons/EarthIcon'
import FederalSealIcon from '../../icons/FederalSealIcon'
import HandCurrencyIcon from '../../icons/HandCurrencyIcon'
import HandPeopleIcon from '../../icons/HandPeopleIcon'
import HeadquartersBuildingIcon from '../../icons/HeadquartersBuildingIcon'
import HousePercentIcon from '../../icons/HousePercentIcon'
import IdeationIcon from '../../icons/IdeationIcon'
import MagnifyingGlassDollarsIcon from '../../icons/MagnifyingGlassDollarsIcon'
import MagnifyingGlassIcon from '../../icons/MagnifyingGlassIcon'
import MaskFemaleIcon from '../../icons/MaskFemaleIcon'
import MicrochipIcon from '../../icons/MicrochipIcon'
import MobileLockIcon from '../../icons/MobileLockIcon'
import NewsIcon from '../../icons/NewsIcon'
import PostItsIcon from '../../icons/PostItsIcon'
import PresentationIcon from '../../icons/PresentationIcon'
import RobotHandMobileIcon from '../../icons/RobotHandMobileIcon'
import RocketIcon from '../../icons/RocketIcon'
import TeamworkIcon from '../../icons/TeamworkIcon'
import TitleDeedIcon from '../../icons/TitleDeedIcon'
import VisionDentalMedicalIcon from '../../icons/VisionDentalMedicalIcon'
import VotePinIcon from '../../icons/VotePinIcon'
import { Interest, TagIconMap } from '../types'
import InterestTile from './InterestTile'

interface TopicsSelectorProps {
  defaultSelected?: number[]
  onChange: (value: number[]) => void
}

const TopicsSelector = ({ defaultSelected, onChange }: TopicsSelectorProps) => {
  const { data } = useGetTopicListQuery()
  const [selected, setSelected] = useState<number[]>(defaultSelected ?? [])

  const tagIconMap: TagIconMap = {
    'aicpa-matters': <AvatarMaleIcon />,
    'anti-money-laundering': <HandCurrencyIcon />,
    blockchain: <BlockchainIcon />,
    cecl: <MagnifyingGlassDollarsIcon />,
    'covid-19': <MaskFemaleIcon />,
    cryptocurrency: <CryptocurrencyIcon />,
    cybersecurity: <MobileLockIcon />,
    'digital-assets': <MicrochipIcon />,
    'digital-evolution': <RobotHandMobileIcon />,
    'distressed-real-estate': <HousePercentIcon />,
    economics: <DollarPieChartIcon />,
    election: <VotePinIcon />,
    'employee-benefit-plans': <HandPeopleIcon />,
    'employee-benefits': <VisionDentalMedicalIcon />,
    esg: <DecisionIcon />,
    'financial-reporting': <PresentationIcon />,
    'going-public': <RocketIcon />,
    inflation: <ChartLineIcon />,
    innovation: <IdeationIcon />,
    'international-standards': <EarthIcon />,
    'labor-and-workforce': <TeamworkIcon />,
    'lease-accounting': <TitleDeedIcon />,
    'opportunity-zones': <MagnifyingGlassIcon />,
    'pcaob-matters': <PostItsIcon />,
    policy: <CertificateIcon />,
    'revenue-recognition': <ChartIcon />,
    'sec-matters': <FederalSealIcon />,
    spac: <HeadquartersBuildingIcon />,
    'state-tax-nexus': <NewsIcon />,
    'supply-chain': <ChainLinkIcon />,
  }

  const interests = data?.data
    ?.filter((interest) => {
      const rsmTag = interest.tag?.split('/rsm-')?.pop()
      return !rsmTag?.match(/alliance|alumni|careers/)
    })
    ?.map((interest) => {
      const tag = interest.tag?.split('/')?.pop()
      return tag ? { ...interest, icon: tagIconMap[tag] } : interest
    }) as Interest[]

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {interests?.map((interest, index) => (
        <InterestTile
          key={interest.tag}
          testId="Topic"
          checked={selected.includes(interest.id)}
          index={index}
          interest={interest}
          onClick={(checked) => {
            const value = checked
              ? [...selected, interest.id]
              : selected.filter((i) => i !== interest.id)
            setSelected(value)
            onChange(value)
          }}
        />
      ))}
    </>
  )
}

TopicsSelector.defaultProps = {
  defaultSelected: undefined,
}

export default TopicsSelector
