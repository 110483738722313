import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Chip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useElementSize, useEventListener } from 'usehooks-ts'
import tokens from '../../styles/tokens.json'
import { Styles } from '../../types'

const styles: Styles = {
  filterChip: {
    position: 'absolute',
    left: '-100rem',
    opacity: 0,
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.5rem',
    marginRight: '0.5rem',
    '& .MuiChip-deleteIcon': {
      margin: '0rem 0.125rem 0rem -0.25rem',
      paddingTop: '0.125rem',
    },
  },
  filterChipValue: {
    marginLeft: '0.375rem',
    fontFamily: 'Prelo-Bold, sans-serif',
  },
  expandCollapseChip: {
    marginRight: '0.5rem',
    paddingRight: '0rem',
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.5rem',
  },
  expandCollapseChipLabel: {
    fontFamily: 'Prelo-Bold, sans-serif',
  },
  clearAllFiltersButton: (theme) => ({
    marginBottom: '0.4375rem',
    fontFamily: 'Prelo-Medium, sans-serif',
    fontSize: '0.9375rem',
    fontWeight: '500',
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
  }),
}

export interface FilterChipData<T> {
  property: keyof T
  value: string
}

export interface FilterChipsProps<T> {
  clearAllFocusQuerySelector?: string
  filterChipTranslationKeyPrefix: string
  filtersSelected: FilterChipData<T>[]
  onChipDeleted: (chip: FilterChipData<T>) => void
  onClearAll: () => void
  hiddenFilters?: string[]
  uniqueId?: string
}

const defaultProps = {
  clearAllFocusQuerySelector: undefined,
  hiddenFilters: undefined,
  uniqueId: 'filter_chip',
}

const FilterChips = <T,>({
  clearAllFocusQuerySelector,
  filterChipTranslationKeyPrefix,
  filtersSelected,
  onChipDeleted,
  onClearAll,
  hiddenFilters,
  uniqueId = 'filter_chip',
}: FilterChipsProps<T>) => {
  const { t } = useTranslation()

  const [divRef, { width }] = useElementSize()
  let containerRef: HTMLDivElement | null

  const [chipsExpanded, setChipsExpanded] = useState<boolean>(false)
  const [hiddenFilterCount, setHiddenFilterCount] = useState<number>(0)
  const [showNumChildren, setShowNumChildren] = useState<number>(0)
  const [showExpandCollapseChip, setShowExpandCollapseChip] =
    useState<boolean>(false)
  const [moreFiltersCount, setMoreFiltersCount] = useState<number>(0)

  const filterChipsRef = useRef<HTMLSpanElement>(null)
  const expandChipRef = useRef<HTMLDivElement>(null)
  const collapseChipRef = useRef<HTMLDivElement>(null)
  const clearAllRef = useRef<HTMLButtonElement>(null)

  const handleResize = () => {
    if (!containerRef) {
      return
    }

    const containerWidth = width
    const moreChipsMargin = 100
    const chipContainer = containerRef.children[0]
    let childWidths = 0

    for (let i = 0; i < chipContainer?.children.length; i += 1) {
      const child = chipContainer.children[i]
      const childWidth = child?.getBoundingClientRect()?.width ?? 0
      childWidths += childWidth

      // If we've reached a chip whose right border extends past the allowed space,
      // then set the number of chips we should show.
      if (childWidths > containerWidth - moreChipsMargin) {
        setShowNumChildren(i)
        break
      } else if (i === chipContainer.children.length - 1) {
        // If we reached the last child and everything fits, don't hide anything.
        setShowNumChildren(filtersSelected.length)
      }
    }
  }

  function debounce(func: () => void, timeout = 100) {
    let timer: number
    return () => {
      clearTimeout(timer)
      timer = window.setTimeout(() => func(), timeout)
    }
  }

  const debouncedResize = debounce(handleResize, 30)

  const handleChipsCleared = () => {
    setChipsExpanded(false)
    onClearAll()

    if (clearAllFocusQuerySelector) {
      const clearAllFocusTargetElement = document.querySelector(
        clearAllFocusQuerySelector,
      ) as HTMLElement
      clearAllFocusTargetElement?.focus()
    }
  }

  const handleDeleteChip = (
    index: number,
    data: FilterChipData<T>,
    { classId = '' },
  ) => {
    onChipDeleted(data)

    const filterChipElements = filterChipsRef?.current?.querySelectorAll(
      ':scope [role=button]',
    )

    if (filterChipElements?.length === 1) {
      handleChipsCleared()
    }

    // We have to wait untill chip is getting updated so added timeout to wait till chip updated
    window.setTimeout(() => {
      if (classId) {
        const nextChipElement = filterChipsRef?.current?.querySelector(
          `.${classId}`,
        ) as HTMLDivElement
        if (nextChipElement) {
          nextChipElement.focus()
          return
        }
        clearAllRef?.current?.focus()
      }
    }, 100)
  }
  useEventListener('resize', debouncedResize)
  useEffect(() => handleResize())

  useEffect(() => {
    setShowExpandCollapseChip(showNumChildren < filtersSelected.length)
    setMoreFiltersCount(filtersSelected.length - showNumChildren)
  }, [chipsExpanded, filtersSelected.length, showNumChildren])

  useEffect(() => {
    const chipRef = chipsExpanded ? collapseChipRef : expandChipRef
    chipRef.current?.focus()
  }, [chipsExpanded, collapseChipRef, expandChipRef])

  useEffect(() => {
    let count = 0

    filtersSelected.forEach(({ value }) => {
      if (hiddenFilters?.includes(value.toUpperCase())) {
        count += 1
      }
    })

    setHiddenFilterCount(count)
  }, [filtersSelected, hiddenFilters])

  // Subtracting hidden filters in this condition since they can't receive focus.
  return filtersSelected.length - hiddenFilterCount > 0 ? (
    <div
      ref={(ref) => {
        divRef(ref)
        containerRef = ref
      }}
      style={{
        overflow: chipsExpanded ? 'auto' : 'hidden',
        height: chipsExpanded ? 'auto' : '2.5rem',
      }}>
      <Box component="span" ref={filterChipsRef}>
        {filtersSelected.map((filterChipData, index) => {
          const { property, value } = filterChipData

          if (hiddenFilters?.includes(value.toUpperCase())) {
            return null
          }

          const label = property
            ? t(`${filterChipTranslationKeyPrefix}.${String(property)}`)
            : ''
          const key = label ? `${label}_${value}_${index}` : `${value}_${index}`
          const classId = `chip_list_${uniqueId}_${index + 1}`
          const showChip = chipsExpanded || index < showNumChildren
          const dataTestIdIndex = index - hiddenFilterCount

          return (
            <Chip
              key={key}
              variant="outlined"
              className={classId}
              sx={styles.filterChip}
              style={{
                position: showChip ? 'static' : 'absolute',
                opacity: showChip ? 1 : 0,
                left: showChip ? 'auto' : '-100rem',
              }}
              label={
                <Box>
                  {label ? (
                    <>
                      <span
                        data-testid={`Lbl_Filter_Chip_Category_${dataTestIdIndex}`}>
                        {label}
                      </span>
                      <span>:&nbsp;</span>
                    </>
                  ) : null}
                  <Box
                    data-testid={`Lbl_Filter_Chip_Category_Option_${dataTestIdIndex}`}
                    component="span"
                    sx={styles.filterChipValue}>
                    {t(`Invoicing.FilterCheckboxes.${value}`) !==
                    `Invoicing.FilterCheckboxes.${value}`
                      ? t(`Invoicing.FilterCheckboxes.${value}`)
                      : value}
                  </Box>
                </Box>
              }
              aria-label={`${t('FilterChips.RemoveFilter')} ${label} ${value}`}
              aria-hidden={showChip ? undefined : true}
              tabIndex={showChip ? 0 : -1}
              onClick={() =>
                handleDeleteChip(index, filterChipData, { classId })
              }
              onDelete={() =>
                handleDeleteChip(index, filterChipData, { classId })
              }
              deleteIcon={
                <Box tabIndex={-1}>
                  <FontAwesomeIcon
                    height="0.5625rem"
                    width="0.5625rem"
                    color={tokens.colors.rsmGray.copy}
                    icon={faClose}
                  />
                </Box>
              }
              data-testid={`Btn_Filter_Chip_${dataTestIdIndex}`}
            />
          )
        })}
      </Box>

      {showExpandCollapseChip && (
        <>
          <Chip
            ref={expandChipRef}
            variant="outlined"
            sx={styles.expandCollapseChip}
            style={{
              display: chipsExpanded ? 'none' : 'inline-flex',
            }}
            label={
              <Box
                component="span"
                sx={styles.expandCollapseChipLabel}
                aria-hidden="true">{`+${moreFiltersCount}`}</Box>
            }
            aria-label={`${t('FilterChips.ShowMoreAppliedFilters', {
              count: moreFiltersCount,
            })}`}
            onClick={() => setChipsExpanded(true)}
            data-testid="Btn_Filter_Chip_ShowMore"
          />
          <Chip
            ref={collapseChipRef}
            variant="outlined"
            sx={styles.expandCollapseChip}
            style={{
              display: chipsExpanded ? 'inline-flex' : 'none',
            }}
            label={
              <Box
                component="span"
                sx={styles.expandCollapseChipLabel}
                aria-hidden="true">
                {t('FilterChips.ShowLess')}
              </Box>
            }
            aria-label={t('FilterChips.ShowLessAppliedFilters')}
            onClick={() => setChipsExpanded(false)}
            data-testid="Btn_Filter_Chip_ShowLess"
          />
        </>
      )}

      <Button
        ref={clearAllRef}
        sx={styles.clearAllFiltersButton}
        aria-label={t('FilterChips.ClearAllAppliedFilters')}
        onClick={handleChipsCleared}
        data-testid="Btn_Filter_ClearAll">
        <Box component="span" aria-hidden="true">
          {t('FilterChips.ClearAll')}
        </Box>
      </Button>
    </div>
  ) : null
}

FilterChips.defaultProps = defaultProps

export default FilterChips
