import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { EntityLevelEnum } from '@rsmus/ecp-userservice'
import { NavLink } from 'react-router-dom'
import CheckboxIcon from '../../../rsmCoreComponents/components/icons/CheckboxIcon'
import CheckboxOutlinedIcon from '../../../rsmCoreComponents/components/icons/CheckboxOutlinedIcon'
import {
  InvoiceData,
  InvoicesData,
} from '../../../store/invoices/invoiceSearchService'
import tokens from '../../../styles/tokens.json'
import { Styles } from '../../../types'
import OverflowTooltip from '../../OverFlowToolTip'
import {
  setSelectedInvoices,
  getSelectedInvoices,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import {
  formatCurrency,
  formatDate,
} from '../../../rsmCoreComponents/utils/formatters'
import ActionMenu, { ActionMenuItem } from './ActionMenu'
import useActionMenu from './useActionMenu'
import { isCemFeatureEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import {
  CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT,
  CEM_FEATURE_PAYMENT_CLIENT_ADMIN,
  CEM_FEATURE_PAYMENT_PAY_INVOICES,
  CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
} from '../../../utils/constants/constants'
import { getCemFeatures } from '../../../store/userInfo/userInfoSlice'
import SortableHeader from '../../../rsmCoreComponents/components/SortableHeader/SortableHeader'

const styles: Styles = {
  invoicesTable: (theme) => ({
    // -------------------------------------------------------------------------
    // TODO: (Ideally move these styles to a RsmTable variant, if possible.)
    // -------------------------------------------------------------------------
    '&.MuiTable-root': {
      tableLayout: 'fixed',
      width: '100%',
      '& .MuiTableCell-root': {
        borderBottom: 'none',
      },
      '& th.MuiTableCell-root': {
        fontFamily: 'Prelo-Bold, sans-serif',
      },
      '& td.MuiTableCell-root': {
        fontFamily: 'Prelo-Book, sans-serif',
      },
      '& .MuiTableCell-head': {
        padding: '1rem',
        paddingRight: 'revert',
        fontSize: '0.875rem',
        lineHeight: '1rem',
        textTransform: 'uppercase',
        verticalAlign: 'top',
        alignItems: 'center',
      },
      '& tbody': {
        '& .MuiTableRow-root': {
          '&:nth-of-type(odd)': {
            backgroundColor: tokens.colors.rsmGray.accessibility,
          },
        },
      },
      '& .MuiTableCell-body': {
        padding: '1.5rem 1rem',
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
      '.MuiFormControlLabel-root': {
        margin: '0rem',
      },
      '.MuiCheckbox-root': {
        boxSizing: 'content-box',
        padding: '0.5rem',
      },
    },
    // -----------------------------------------------------------------------
    // Invoicing Table-specific Styles
    // -----------------------------------------------------------------------
    '& .MuiTableCell-root': {
      '&:nth-of-type(1), &:nth-of-type(10)': {
        width: '3.5rem',
        padding: '0.5rem',
      },
    },
    '.sortable-header': {
      cursor: 'pointer',
    },
    '& .MuiTableCell-body': {
      '&:nth-of-type(2), &:nth-of-type(3), :nth-of-type(5), :nth-of-type(6), :nth-of-type(9)':
        {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
    },
    [theme.breakpoints.down('tablet')]: {
      '& .MuiTableCell-root': {
        '&:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(5), &:nth-of-type(6), &:nth-of-type(8), &:nth-of-type(9)':
          {
            display: 'none',
          },
      },
    },
    [theme.breakpoints.only('desktop')]: {
      '& .MuiTableCell-root': {
        '&:nth-of-type(2)': {
          width: '17%',
        },
        '&:nth-of-type(3)': {
          width: '14%',
        },
        '&:nth-of-type(4)': {
          width: '12%',
        },
        '&:nth-of-type(5)': {
          width: '10%',
        },
        '&:nth-of-type(6)': {
          width: '12%',
        },
        '&:nth-of-type(7)': {
          width: '10%',
        },
        '&:nth-of-type(8)': {
          width: '6.5rem',
        },
        '&:nth-of-type(9)': {
          width: '13%',
        },
      },
    },
    [theme.breakpoints.only('tablet')]: {
      '& .MuiTableCell-root': {
        '&:nth-of-type(3), &:nth-of-type(6), &:nth-of-type(9)': {
          display: 'none',
        },
        '&:nth-of-type(2)': {
          width: '32%',
        },
        '&:nth-of-type(4)': {
          width: '16%',
        },
        '&:nth-of-type(5)': {
          width: '16%',
        },
        '&:nth-of-type(7)': {
          width: '17%',
        },
        '&:nth-of-type(8)': {
          width: '19%',
          minWidth: '6.25rem',
        },
      },
    },
    [theme.breakpoints.only('mobile')]: {
      '& .MuiTableCell-root': {
        '&:nth-of-type(4)': {
          width: '50%',
        },
        '&:nth-of-type(7)': {
          width: '50%',
        },
      },
    },
  }),
  // -----------------------------------------------------------------------
  // TODO: Possible RSM Table Utility Styles
  // -----------------------------------------------------------------------
  date: {
    textTransform: 'uppercase',
  },
}

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecoration: 'underline',
  padding: '0.125rem 0.3125rem 0.125rem 0.3125rem',
}))

interface InvoicesTableProps {
  invoicesData: InvoicesData
  onSort: (columnName: string, direction: 'asc' | 'desc') => void
  sortColumn: string
  sortDirection: 'asc' | 'desc'
  isMounted: boolean
}

const InvoicesTable = ({
  invoicesData,
  onSort,
  sortColumn,
  sortDirection,
  isMounted,
}: InvoicesTableProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const cemFeatures = useSelector(getCemFeatures)
  const selectedInvoices = useSelector(getSelectedInvoices)
  const {
    handlePayInvoiceAction,
    handleDownloadInvoiceAction,
    handleViewClientDashboard,
    handleViewClientAccountManagement,
  } = useActionMenu()

  const isClientAdmin = useMemo(
    () =>
      isCemFeatureEnabled(CEM_FEATURE_PAYMENT_CLIENT_ADMIN, cemFeatures, 'any'),
    [cemFeatures],
  )
  const isAccountManager = useMemo(
    () =>
      isCemFeatureEnabled(
        CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT,
        cemFeatures,
        'any',
      ),
    [cemFeatures],
  )
  // Hiding select all checkbox until we know how to handle it when pagingmight not be used at all
  // const selectAllInvoices = () => {
  //   dispatch(setSelectedInvoices(invoicesData.data))
  // }
  // const deselectAllInvoices = () => {
  //   dispatch(setSelectedInvoices([]))
  // }
  // const selectOrDeselectAllInvoices = () => {
  //   if (selectedInvoices.length === invoicesData.data.length) {
  //     deselectAllInvoices()
  //   } else {
  //     selectAllInvoices()
  //   }
  // }

  const toggleInvoiceSelection = (invoice: InvoiceData) => {
    if (selectedInvoices.find((row) => row?.id === invoice.id)) {
      dispatch(
        setSelectedInvoices(
          selectedInvoices.filter((row) => row.id !== invoice.id),
        ),
      )
    } else {
      dispatch(setSelectedInvoices([...selectedInvoices, invoice]))
    }
  }

  const generateMenuItems = useCallback(
    (invoice: InvoiceData): ActionMenuItem<InvoiceData>[] => {
      const menuItems: ActionMenuItem<InvoiceData>[] = []
      if (
        invoice.status.toLowerCase() !== 'closed' &&
        (isCemFeatureEnabled(
          CEM_FEATURE_PAYMENT_PAY_INVOICES,
          cemFeatures,
          'any',
          EntityLevelEnum.Application,
        ) ||
          isCemFeatureEnabled(
            CEM_FEATURE_PAYMENT_PAY_INVOICES,
            cemFeatures,
            'any',
            EntityLevelEnum.LegalEntity,
            invoice.customerNumber,
          ))
      ) {
        menuItems.push({
          label: t('Invoicing.Pay'),
          handleAction: handlePayInvoiceAction,
        })
      }

      if (
        isCemFeatureEnabled(
          [
            CEM_FEATURE_PAYMENT_PAY_INVOICES,
            CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
          ],
          cemFeatures,
          'any',
        )
      ) {
        menuItems.push({
          label: t('Invoicing.Download'),
          handleDownloadAction: handleDownloadInvoiceAction,
        })
      }

      if (isClientAdmin) {
        menuItems.push({
          label: t('Invoicing.ViewClient'),
          handleAction: handleViewClientDashboard,
        })
      }

      if (isAccountManager) {
        menuItems.push({
          label: t('Invoicing.ViewClient'),
          handleAction: handleViewClientAccountManagement,
        })
      }

      return menuItems
    },
    [cemFeatures, handlePayInvoiceAction, handleDownloadInvoiceAction],
  )

  const handleSort = (columnName: string) => {
    // Determine the new sort direction based on the current state
    const newSortDirection: 'asc' | 'desc' =
      sortColumn === columnName && sortDirection === 'asc' ? 'desc' : 'asc'
    // Pass both the column name and sort direction to the onSort callback
    onSort(columnName, newSortDirection)
  }

  const getAccountManagementNavLink = (
    columnName: string,
    columnValue: string,
    clientNumber: string,
  ) => (
    <OverflowTooltip>
      <StyledNavLink
        // eslint-disable-next-line jsx-a11y/tabindex-no-positive
        tabIndex={0}
        to={`/invoicing/${
          isClientAdmin ? 'dashboard' : 'account-management'
        }/${clientNumber}`}
        data-testid={`Lnk_Invoices_CustomerNumber${columnName}`}>
        {columnValue}
      </StyledNavLink>
    </OverflowTooltip>
  )

  const getSortableHeader = (columnName: string, displayValue: string) => (
    <TableCell>
      <SortableHeader
        columnName={columnName}
        currentSortColumn={sortColumn || ''}
        sortDirection={sortDirection || ''}
        isMounted={isMounted}
        handleSort={handleSort}>
        {displayValue}
      </SortableHeader>
    </TableCell>
  )

  return (
    <TableContainer component={Paper} sx={styles.tableContainer}>
      <Table
        // variant="rsm" // TODO: Can we create a variant of MuiTable in the MUI theme?
        id="invoicesTable"
        sx={styles.invoicesTable}>
        <Box component="caption" sx={visuallyHidden}>
          {t('Invoicing.Invoices')}
        </Box>
        <TableHead>
          <TableRow>
            <TableCell>
              {/*
               // Hiding select all checkbox until we know how to handle it when pagingmight not be used at all
               <FormControlLabel
                label=""
                aria-label={t('Invoicing.SelectAllInvoices')}
                htmlFor="select-all-invoices-checkbox"
                control={
                  <Checkbox
                    id="select-all-invoices-checkbox"
                    icon={<CheckboxOutlinedIcon />}
                    checkedIcon={<CheckboxIcon />}
                    checked={
                      selectedInvoices.length === invoicesData.data.length
                    }
                    disableRipple
                    onClick={() => selectOrDeselectAllInvoices()}
                  />
                }
              /> */}
            </TableCell>
            {getSortableHeader('customerName', t('Invoicing.CustomerName'))}
            {getSortableHeader('customerNumber', t('Invoicing.CustomerNumber'))}
            {getSortableHeader('invoiceNumber', t('Invoicing.InvoiceNumber'))}
            {getSortableHeader('invoiceDate', t('Invoicing.InvoiceDate'))}
            {getSortableHeader(
              'GrossInvoiceAmount',
              t('Invoicing.OriginalAmount'),
            )}
            {getSortableHeader('openAmount', t('Invoicing.OpenAmount'))}
            <TableCell>
              <Box
                sx={{
                  fontSize: '14px',
                  fontWeight: '700',
                  width: '100%',
                  justifyContent: 'flex-start',
                  padding: '0.6rem 0px',
                  cursor: 'default',
                  textTransform: 'none',
                  fontFamily: 'Prelo-Medium, sans-serif',
                }}>
                {t('Invoicing.Status')}
              </Box>
            </TableCell>
            {getSortableHeader('company', t('Invoicing.Company'))}
            {isCemFeatureEnabled(
              [
                CEM_FEATURE_PAYMENT_PAY_INVOICES,
                CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
              ],
              cemFeatures,
              'any',
            ) && (
              <TableCell>
                <Box component="span" sx={visuallyHidden}>
                  {t('Invoicing.Actions')}
                </Box>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {invoicesData?.data?.map((invoice) => (
            <TableRow key={`invoice-${invoice.id}`}>
              <TableCell>
                <FormControlLabel
                  label=""
                  aria-label={`${t('Invoicing.SelectInvoice')} ${
                    invoice.invoiceNumber
                  }`}
                  htmlFor={`select-invoice-checkbox-${invoice.id}`}
                  control={
                    <Checkbox
                      id={`select-invoice-checkbox-${invoice.id}`}
                      icon={<CheckboxOutlinedIcon />}
                      checkedIcon={<CheckboxIcon />}
                      checked={
                        !!selectedInvoices.find((row) => row.id === invoice.id)
                      }
                      disableRipple
                      onClick={() => toggleInvoiceSelection(invoice)}
                    />
                  }
                />
              </TableCell>
              <TableCell data-testid={`customerName-cell-${invoice.id}`}>
                <OverflowTooltip id={invoice.customerName}>
                  {invoice.customerName}
                </OverflowTooltip>
              </TableCell>
              <TableCell data-testid={`customerNumber-cell-${invoice.id}`}>
                {isClientAdmin || isAccountManager ? (
                  getAccountManagementNavLink(
                    'CustomerNumber',
                    invoice.customerNumber,
                    invoice.customerNumber,
                  )
                ) : (
                  <OverflowTooltip id={invoice.customerNumber}>
                    {invoice.customerNumber}
                  </OverflowTooltip>
                )}
              </TableCell>
              <TableCell data-testid={`invoiceNumber-cell-${invoice.id}`}>
                <OverflowTooltip id={invoice.invoiceNumber}>
                  {invoice.invoiceNumber}
                </OverflowTooltip>
              </TableCell>
              <TableCell data-testid={`date-cell-${invoice.id}`}>
                <Box component="span" sx={styles.date}>
                  <OverflowTooltip>{formatDate(invoice.date)}</OverflowTooltip>
                </Box>
              </TableCell>
              <TableCell data-testid={`originalAmount-cell-${invoice.id}`}>
                <OverflowTooltip>
                  {formatCurrency(invoice.originalAmount, invoice.currency)}{' '}
                  {invoice.currency}
                </OverflowTooltip>
              </TableCell>
              <TableCell data-testid={`openAmount-cell-${invoice.id}`}>
                <OverflowTooltip>
                  {formatCurrency(invoice.openAmount, invoice.currency)}{' '}
                  {invoice.currency}
                </OverflowTooltip>
              </TableCell>
              <TableCell
                data-testid={`invoice-status-paid-date-cell-${invoice.id}`}>
                {invoice.status}
              </TableCell>
              <TableCell
                data-testid={`company-status-paid-date-cell-${invoice.id}`}>
                <OverflowTooltip>{invoice.company}</OverflowTooltip>
              </TableCell>
              {(isCemFeatureEnabled(
                [
                  CEM_FEATURE_PAYMENT_PAY_INVOICES,
                  CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
                ],
                cemFeatures,
                'any',
                EntityLevelEnum.Application,
              ) ||
                isCemFeatureEnabled(
                  [
                    CEM_FEATURE_PAYMENT_PAY_INVOICES,
                    CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
                  ],
                  cemFeatures,
                  'any',
                  EntityLevelEnum.LegalEntity,
                  invoice.customerNumber,
                )) && (
                <TableCell>
                  <ActionMenu
                    actionButtonAriaControls={`payment-${invoice.id}-menu`}
                    actionButtonDescribedBy={invoice.id.toString()}
                    id={invoice.id}
                    data={invoice}
                    errorHeader="Invoicing.DownloadError"
                    errorMessage="Invoicing.DownloadErrorMessage"
                    errorMessageForNotFound="Invoicing.DownloadErrorMessageForNotFound"
                    menuItems={generateMenuItems(invoice)}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default InvoicesTable
