/* eslint-disable react/jsx-props-no-spreading */
import { FormControl } from '@mui/material'
import Select, { SelectProps } from '@mui/material/Select'
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormValues } from '../../../../../rsmCoreComponents/components/DynamicForm/models'
import { useGetTimeZoneListQuery } from '../../../../../store/userService'
import { Styles } from '../../../../../types'
import { ArrowDownIcon } from '../../../../icons'

const styles: Styles = {
  formControl: {
    minWidth: '7.5rem',
  },
}

interface TimeZoneSelectProps extends SelectProps {
  name: string
}

const TimeZoneSelect = ({
  label,
  name,
  required,
  ...rest
}: TimeZoneSelectProps) => {
  const { t } = useTranslation()
  const { handleChange, values } = useFormikContext<FormValues>()
  const { data } = useGetTimeZoneListQuery()

  const [selectedValue, setSelectedValue] = useState<number>(
    (values[name] as number) ?? 1,
  )

  useEffect(() => {
    setSelectedValue(values[name])
  }, [values])

  return (
    <FormControl sx={styles.formControl}>
      <Select
        {...rest}
        native
        fullWidth
        name={name}
        value={selectedValue}
        IconComponent={ArrowDownIcon}
        onChange={handleChange}>
        {data?.data.map((item) => (
          <option key={item.id} value={item.id}>
            {`${t(
              `UserProfile.ContactPreferenceForm.TimeZones.${item.timeZoneTextKey}`,
            )}`}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}

export default TimeZoneSelect
