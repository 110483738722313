import { Box, Drawer } from '@mui/material'
import React, { useMemo } from 'react'
import { Styles } from '../../../types'

const styles: Styles = {
  container: {
    display: 'flex',
    flex: 1,
  },
}

interface SearchLayoutDrawerProps {
  children?: React.ReactNode
  drawerWidth: string
  isDrawerOpen: boolean
}

const defaultProps = {
  children: undefined,
}

const SearchLayoutDrawer = ({
  children,
  drawerWidth,
  isDrawerOpen,
}: SearchLayoutDrawerProps) => {
  const computedStyles = {
    drawer: useMemo(
      () => ({
        position: 'sticky',
        top: '7.9375rem',
        height: 'calc(100vh - 7.9375rem)',
        marginTop: '0rem',
        border: 'none',
        width: drawerWidth,
        minHeight: '14rem',
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          position: 'relative',
          border: 'none',
          paddingRight: '1rem',
          width: drawerWidth,
        },
      }),
      [isDrawerOpen, drawerWidth],
    ),
  }

  return (
    <Box sx={styles.container}>
      <Drawer
        sx={computedStyles.drawer}
        variant="persistent"
        anchor="left"
        open={isDrawerOpen}>
        <Box className="Rsm-scrollBoundaries">{children}</Box>
      </Drawer>
    </Box>
  )
}

SearchLayoutDrawer.defaultProps = defaultProps

export default SearchLayoutDrawer
