/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { useFormikContext } from 'formik'
import { t } from 'i18next'
import React from 'react'
import tokens from '../../../../../styles/tokens.json'
import { Styles } from '../../../../../types'
import { FormValues } from '../../models'

const styles: Styles = {
  readOnly: (theme) => ({
    '& .MuiInputBase-root': {
      fontWeight: theme.typography.fontWeightLight,
      '&.Mui-focused': {
        color: tokens.colors.halfWhite,
      },
      '&:not(.Mui-focused)': {
        paddingRight: '0rem',
        paddingLeft: '0rem',
      },
      '&.MuiOutlinedInput-root fieldset': {
        border: 'none',
      },
    },
  }),
}

type FormTextAreaProps = TextFieldProps & {
  disabledField?: (values: FormValues) => boolean
  maxLength?: number
  name: string
  readonly?: boolean
  rows?: number
  srTriggers?: string[]
  testId?: string
}

const FormTextArea = ({
  'aria-label': ariaLabel,
  disabledField,
  label,
  maxLength,
  name,
  readonly,
  required,
  rows,
  srTriggers,
  testId,
  onChange,
  onBlur,
  ...rest
}: FormTextAreaProps) => {
  const { errors, handleChange, handleBlur, submitCount, touched, values } =
    useFormikContext<FormValues>()

  const getDisabled = (): boolean => {
    if (disabledField?.(values)) {
      return disabledField?.(values)
    }
    return false
  }

  return (
    <>
      <TextField
        {...rest}
        multiline
        rows={rows || 5}
        sx={readonly ? styles.readOnly : undefined}
        fullWidth
        inputProps={{ maxLength, readOnly: readonly }}
        name={name}
        value={values[name] || ''}
        required={required}
        disabled={getDisabled()}
        autoComplete="off"
        error={(touched[name] || submitCount > 0) && Boolean(errors[name])}
        aria-describedby={`${name}-announce`}
        helperText={
          (touched[name] || submitCount > 0) && errors[name] ? (
            errors[name]
          ) : (
            <span
              aria-hidden
              style={{
                display:
                  maxLength !== null || maxLength !== undefined
                    ? 'none'
                    : 'inline',
              }}
              className="inline float-right font-prelo-book text-[16px] text-rsm-gray-copy font-normal pt-0">
              {`${values[name] || ''}`.length}/{maxLength}
            </span>
          )
        }
        data-testid={testId ?? `${name}-test-id`}
        onChange={(e) => {
          handleChange(e)
          onChange?.(e)
        }}
        onBlur={(e) => {
          handleBlur(e)
          onBlur?.(e)
        }}
      />
      {(maxLength !== null || maxLength !== undefined) && (
        <span
          id={`${name}-announce`}
          className="sr-only"
          aria-hidden={
            srTriggers?.indexOf(`${`${values[name] || ''}`.length}`) === -1
          }
          aria-live="polite">
          {`${values[name] || ''}`.length} of {maxLength} {t('Form.Characters')}
          {`${values[name] || ''}`.length === maxLength && (
            <span>{t('Form.MaximumReached')}</span>
          )}
        </span>
      )}
    </>
  )
}

export default FormTextArea
