const minYear = new Date('50')
const maxYear = new Date((new Date().getFullYear() + 28).toString())

export const handleStartDateError = (
  startDate: string | Date,
  endDate: string | Date,
  setStartDateError: React.Dispatch<React.SetStateAction<boolean>>,
): boolean => {
  const hasError =
    startDate.toString().toLowerCase() === 'invalid date' ||
    (endDate !== 'null' && startDate === 'null') ||
    (endDate !== 'null' && startDate > endDate) ||
    startDate < minYear ||
    startDate > maxYear

  if (hasError) {
    setStartDateError(true)
  } else {
    setStartDateError(false)
  }

  return hasError
}

export const handleEndDateError = (
  startDate: string | Date,
  endDate: string | Date,
  setEndDateError: React.Dispatch<React.SetStateAction<boolean>>,
): boolean => {
  const hasError =
    endDate.toString().toLowerCase() === 'invalid date' ||
    endDate < startDate ||
    (endDate === 'null' && startDate !== 'null') ||
    endDate > maxYear

  if (hasError) {
    setEndDateError(true)
  } else {
    setEndDateError(false)
  }

  return hasError
}
