/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  Select,
  TextField,
  styled,
  Box,
  Typography,
  FormHelperText,
  Stack,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useGetUserContactPreferenceDetailsQuery } from '../../../../store/userService'
import tokens from '../../../../styles/tokens.json'
import { ArrowDownIcon } from '../../../icons'
import { setIsDashIssue } from '../../../../store/support/supportSlice'
import CustomErrorAlert from '../../../forms/Alert/CustomErrorAlert/CustomErrorAlert'
import CustomSuccessAlert from '../../../forms/Alert/CustomSuccessAlert/CustomSuccessAlert'
import { Styles } from '../../../../types'
import useApps from '../../../../api/user/hooks/useApps'
import api from '../../../../api'
import FormCard from '../../../../rsmCoreComponents/components/DynamicForm/FormControls/FormCard'
import FormAutoPopName from '../../../../rsmCoreComponents/components/DynamicForm/FormControls/FormAutoPopName'
import FormAutoPopEmail from '../../../../rsmCoreComponents/components/DynamicForm/FormControls/FormAutoPopEmail'
import FormPreferredContactRadio from '../../../../rsmCoreComponents/components/DynamicForm/FormControls/FormPreferredContactRadio'
import FormBusinessPhoneNumber from '../../../../rsmCoreComponents/components/DynamicForm/FormControls/FormBusinessPhoneNumber'

const systemPortalTextLimit = 100
const issueTextLimit = 2000

const StyledTextField = styled(TextField)({
  '.MuiInputBase-root': {
    width: '100%',
    maxWidth: '100%',
  },
  '& .MuiFormHelperText-root': { margin: '0rem' },
})

const StyledSelectField = styled(Select)({
  '.MuiOutlinedInput-root': {
    width: '100%',
    maxWidth: '100%',
  },
  '.MuiSelect-select': { fontFamily: 'Prelo-Book !important' },
})

const StyledLabel = styled('label')(({ theme }) => ({
  fontFamily: 'Prelo-Black, sans-serif',
  fontSize: '1rem',
  paddingBottom: '0.5rem',
  color: theme.palette.text.primary,
  display: 'block',
}))

const StyledLink = styled('a')(({ theme }) => ({
  color: theme.palette.common.black,
  textDecoration: 'underline',
}))

const styles: Styles = {
  dashError: {
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '0.875rem',
    color: tokens.colors.rsmRed.secondary,
  },
  dashWarning: () => ({
    fontFamily: 'Prelo-Book, sans-serif',
    backgroundColor: tokens.colors.rsmYellow.primary,
    marginTop: '.4375rem',
    marginBottom: '1.25rem',
    padding: '0.5rem',
  }),
  bpmb32: (theme) => ({
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
    [theme.breakpoints.up('mobile')]: {
      width: '50%',
    },
    [theme.breakpoints.only('tablet')]: {},
  }),
  paddingContainer: (theme) => ({
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
    [theme.breakpoints.up('mobile')]: {
      width: '50%',
    },
    marginBottom: '2.5rem',
  }),
  bppr5mb9: (theme) => ({
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
    [theme.breakpoints.up('mobile')]: {
      width: '25%',
    },
    marginBottom: '2.5rem',
  }),
  bppr10mb8: (theme) => ({
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
      marginBottom: '2.5rem',
    },
    [theme.breakpoints.up('mobile')]: {
      width: '50%',
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: '1.625rem',
    },
  }),
  error: {
    display: 'inline',
    float: 'left',
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '.875rem',
    fontWeight: 400,
    color: tokens.colors.rsmRed.secondary,
    paddingTop: '0rem',
  },
}

const SupportForm = () => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    reset,
    control,
    clearErrors,
    getValues,
    formState,
    setValue,
  } = useForm({ mode: 'onSubmit' })
  const [isSuccess, setIsSuccess] = useState(false)
  const { userApps } = useApps()
  const [open, setOpen] = useState(false)
  const [showDashWarning, setShowDashWarning] = useState(false)
  const dispatch = useDispatch()

  const { data } = useGetUserContactPreferenceDetailsQuery()

  const contactMethodTxtKey = data?.data.preferredContactMethod?.contactMedium
  const contactMethod = contactMethodTxtKey
    ? `${t(
        `UserProfile.ContactPreferenceForm.ContactMethods.${contactMethodTxtKey}`,
      )}`
    : `${t(
        `UserProfile.ContactPreferenceForm.ContactMethods.EmailContactMethod`,
      )}`

  const [preferredContact, setPreferredContact] = useState(contactMethod)

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const onSubmit = useCallback(
    async (request) => {
      try {
        const response: boolean = (
          await api.communication.issue_SubmitIssue(request)
        )?.data
        if (response) {
          setIsSuccess(true)
          reset()
        } else setIsSuccess(false)
      } catch (e) {
        setIsSuccess(false)
      }

      setOpen(true)
      setPreferredContact('Email')
    },
    [
      api.communication.issue_SubmitIssue,
      setIsSuccess,
      reset,
      setOpen,
      setPreferredContact,
    ],
  )

  const handleRadioChange = (val: string) => {
    setPreferredContact(val)
    if (val === 'Email') {
      clearErrors('businessPhone')
    }
  }

  const handleDashIssueChange = useCallback(() => {
    const val = getValues('dashIssue')
    if (val === 'Yes') {
      setShowDashWarning(true)
      dispatch(setIsDashIssue(true))
    } else {
      setShowDashWarning(false)
      dispatch(setIsDashIssue(false))
    }
  }, [setShowDashWarning, setIsDashIssue])

  const hasDash = useMemo(
    () => userApps.some((userApp) => userApp.name?.indexOf('DASH') === 0),
    [userApps],
  )

  const renderBusinessEmail = () => <FormAutoPopEmail />

  const renderPreferredContactMethod = () => (
    <FormPreferredContactRadio
      control={control}
      handleRadioChange={handleRadioChange}
      contactMethod={preferredContact}
    />
  )

  return (
    <div className="grow">
      <div data-testid="successAlert">
        {isSuccess && (
          <CustomSuccessAlert
            testId="Banner_Support_Success"
            header="SupportSuccess"
            message="SupportSuccessMessage"
            open={open}
            close={handleClose}
          />
        )}
        {!isSuccess && (
          <CustomErrorAlert
            testId="Banner_Support_Error"
            header="SupportError"
            open={open}
            close={handleClose}
            message="SupportErrorMessage"
          />
        )}
      </div>
      <FormCard
        title={t('SupportFormTitle')}
        desc={t('SupportFormDesc')}
        handleSubmit={handleSubmit(onSubmit)}
        submitBtnDisabled={showDashWarning}
        testId="SupportForm">
        <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '0 -1.4375rem' }}>
          <FormAutoPopName />
          {hasDash ? renderBusinessEmail() : renderPreferredContactMethod()}
        </Box>
        {hasDash && (
          <Box
            sx={{ display: 'flex', flexWrap: 'wrap', margin: '0 -1.4375rem' }}>
            <Box
              style={{ padding: '0 1.4375rem' }}
              sx={styles.paddingContainer}
              data-testid="Div_Support_DashIssueContainer">
              <StyledLabel id="Lbl_DashIssue" htmlFor="dashIssue">
                <span data-testid="Lbl_Support_DashIssueLabel">
                  {t('DashIssue')}&nbsp;*
                </span>
                <span className="sr-only">{t('srOnlyRequired')}</span>
              </StyledLabel>
              <Controller
                name="dashIssue"
                control={control}
                defaultValue=""
                rules={{
                  required: `${t('DashIssueShortened')} ${t(
                    'IsARequiredField',
                  )}`,
                }}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <StyledSelectField
                      native
                      id="dashIssue"
                      value={value}
                      onChange={(e) => {
                        onChange(e)
                        handleDashIssueChange()
                      }}
                      inputRef={ref}
                      fullWidth
                      IconComponent={ArrowDownIcon}
                      labelId="Lbl_DashIssue Lbl_DashWarning"
                      error={!!error}
                      displayEmpty
                      inputProps={{
                        'aria-describedby': 'dashIssueErrorText',
                        'data-testid': 'Sel_Support_DashIssueSelect',
                      }}>
                      <option
                        value=""
                        data-testid="Opt_Support_DashIssueOptionClear">
                        {getValues('dashIssue') !== ''
                          ? t('SupportFormDashDropdown.ClearSelection')
                          : t('SelectBusiness')}
                      </option>
                      <option
                        value="Yes"
                        data-testid="Opt_Support_DashIssueOptionYes">
                        {t('SupportFormDashDropdown.Yes')}
                      </option>
                      <option
                        value="No"
                        data-testid="Opt_Support_DashIssueOptionNo">
                        {t('SupportFormDashDropdown.No')}
                      </option>
                    </StyledSelectField>
                    {error && (
                      <FormHelperText
                        id="dashIssueErrorText"
                        data-testid="Lbl_Support_DashIssueError"
                        sx={styles.dashError}>
                        {error.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
              {showDashWarning && (
                <Box sx={styles.dashWarning}>
                  <Stack
                    direction="row"
                    alignContent="center"
                    alignItems="center"
                    spacing={1}>
                    <FontAwesomeIcon
                      height="3.75rem"
                      width="3.75rem"
                      color="black"
                      icon={faExclamationTriangle}
                      fontSize="1.625rem"
                    />
                    <Box
                      id="Lbl_DashWarning"
                      role="alert"
                      data-testid="Div_Support_SubmitToDashContainer">
                      <Trans i18nKey="DashIssueExplanation">
                        DASH issues cannot be submitted through the Client
                        Portal, please{' '}
                        <StyledLink
                          href="https://dash.rsmus.com/csm"
                          target="_blank"
                          rel="noreferrer"
                          data-testid="Lnk_Support_SubmitToDash">
                          submit an issue with DASH
                        </StyledLink>
                      </Trans>
                    </Box>
                  </Stack>
                </Box>
              )}
            </Box>
          </Box>
        )}
        <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '0 -1.4375rem' }}>
          {hasDash ? renderPreferredContactMethod() : renderBusinessEmail()}
          <FormBusinessPhoneNumber
            formState={formState}
            setValue={setValue}
            control={control}
            required={preferredContact === 'Phone'}
          />
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '0 -1.4375rem' }}>
          <Box style={{ padding: '0 1.4375rem' }} sx={styles.bppr10mb8}>
            <StyledLabel htmlFor="engagementName">
              <span data-testid="Lbl_Support_EngagementNameLabel">
                {t('EngagementName')}
              </span>
            </StyledLabel>
            <Controller
              name="engagementName"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <StyledTextField
                  id="engagementName"
                  variant="outlined"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  inputProps={{
                    'data-testid': 'Inp_Support_EngagementNameValue',
                  }}
                />
              )}
            />
          </Box>
          <Box style={{ padding: '0 1.4375rem' }} sx={styles.paddingContainer}>
            <StyledLabel htmlFor="clientOrBusinessName">
              <span data-testid="Lbl_Support_ClientBusinessNameLabel">
                {t('ClientBusName')}
              </span>
            </StyledLabel>
            <Controller
              name="clientOrBusinessName"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <StyledTextField
                  id="clientOrBusinessName"
                  variant="outlined"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputProps={{
                    'data-testid': 'Inp_Support_ClientBusinessNameValue',
                  }}
                />
              )}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '0 -1.4375rem' }}>
          <Box style={{ padding: '0 1.4375rem' }} sx={styles.bppr10mb8}>
            <StyledLabel htmlFor="lineOfBusiness">
              <span data-testid="Lbl_Support_LineOfServiceLabel">
                {t('LOB')}
              </span>
            </StyledLabel>
            <Controller
              name="lineOfBusiness"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <StyledSelectField
                  native
                  id="lineOfBusiness"
                  value={value}
                  onChange={onChange}
                  fullWidth
                  IconComponent={ArrowDownIcon}
                  inputProps={{
                    'aria-label': 'Line of Service',
                    'data-testid': 'Sel_Support_LineOfServiceSelect',
                  }}
                  displayEmpty>
                  <option
                    value=""
                    data-testid="Opt_Support_LineOfServiceOptionClear">
                    {getValues('lineOfBusiness') !== ''
                      ? t('SupportFormDashDropdown.ClearSelection')
                      : t('SelectBusiness')}
                  </option>
                  <option
                    value="Audit"
                    data-testid="Opt_Support_LineOfServiceOptionAudit">
                    Audit
                  </option>
                  <option
                    value="Consulting"
                    data-testid="Opt_Support_LineOfServiceOptionConsulting">
                    Consulting
                  </option>
                  <option
                    value="Tax"
                    data-testid="Opt_Support_LineOfServiceOptionTax">
                    Tax
                  </option>
                  <option
                    value="Other"
                    data-testid="Opt_Support_LineOfServiceOptionOther">
                    Other
                  </option>
                </StyledSelectField>
              )}
            />
          </Box>
          <Box style={{ padding: '0 1.4375rem' }} sx={styles.bpmb32}>
            <StyledLabel htmlFor="systemOrPortalName">
              <span data-testid="Lbl_Support_SystemOrPortalLabel">
                {t('SysPortal')}
              </span>
            </StyledLabel>
            <Controller
              name="systemOrPortalName"
              control={control}
              defaultValue=""
              rules={{ maxLength: systemPortalTextLimit }}
              render={({
                field: { onChange, value, ref },
                fieldState: { error },
              }) => (
                <StyledTextField
                  id="systemOrPortalName"
                  variant="outlined"
                  fullWidth
                  value={value}
                  inputProps={{
                    maxLength: systemPortalTextLimit,
                    'data-testid': 'Inp_Support_SystemOrPortalValue',
                  }}
                  ref={ref}
                  onChange={onChange}
                  helperText={
                    error ? (
                      error.message
                    ) : (
                      <Typography
                        component="span"
                        sx={(theme) => ({
                          float: 'right',
                          fontFamily: 'Prelo-Book, sans-serif',
                          fontSize: '1rem',
                          fontWeight: 400,
                          color: theme.palette.text.primary,
                        })}>
                        {value.length}/{systemPortalTextLimit}
                      </Typography>
                    )
                  }
                />
              )}
            />
          </Box>
        </Box>
        <Box
          sx={{ marginTop: '2.5rem' }}
          data-testid="Div_Support_IssueContainer">
          <StyledLabel htmlFor="issue">
            <span data-testid="Lbl_Support_IssueLabel">
              {t('IssueDesc')}&nbsp;*
            </span>
          </StyledLabel>
          <Controller
            name="issue"
            control={control}
            defaultValue=""
            rules={{
              maxLength: issueTextLimit,
              required: `${t('IssueDesc')} ${t('IsARequiredField')}`,
            }}
            render={({
              field: { onChange, value, ref },
              fieldState: { error },
            }) => (
              <StyledTextField
                id="issue"
                variant="outlined"
                fullWidth
                multiline
                value={value}
                rows={4}
                inputRef={ref}
                disabled={showDashWarning}
                inputProps={{
                  maxLength: issueTextLimit,
                  'data-testid': 'Inp_Support_IssueValue',
                }}
                onChange={(e) => onChange(e)}
                error={!!error}
                helperText={
                  <>
                    {!!error && (
                      <Typography
                        component="span"
                        sx={styles.error}
                        data-testid="Txt_Support_IssueError">
                        {error?.message}
                      </Typography>
                    )}
                    <Typography
                      component="span"
                      sx={(theme) => ({
                        float: 'right',
                        fontFamily: 'Prelo-Book, sans-serif',
                        fontSize: '1rem',
                        fontWeight: 400,
                        color: theme.palette.text.primary,
                        paddingTop: '0rem',
                      })}
                      data-testid="Txt_Support_IssueLength">
                      {value.length}/{issueTextLimit}
                    </Typography>
                  </>
                }
              />
            )}
          />
        </Box>
      </FormCard>
    </div>
  )
}

export default SupportForm
