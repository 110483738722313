/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useMemo, useState } from 'react'

// material ui components
import {
  TableBody,
  TableCell,
  TableRow,
  styled,
  TableHead,
  IconButton,
  Checkbox,
  Button,
  FormControlLabel,
} from '@mui/material'
import CustomTableCell from './CustomTableCell'
import sortableData from '../../../utils/helpers/sortableData'
import { CheckboxIcon, CheckboxOutlinedIcon } from '../../../components/icons'
import OverFlowToolTip from '../../../components/OverFlowToolTip/OverFlowToolTip'

export const StyledTableCell = styled(TableCell)({
  fontFamily: 'Prelo-Book, sans-serif',
  fontSize: '16px',
  lineHeight: '24px',
  borderBottomWidth: 0,
})

const StyledRow = styled(TableRow)({
  padding: 10,
  whiteSpace: 'nowrap',
})

// eslint-disable-next-line @typescript-eslint/ban-types
function objectKeys<T extends {}>(obj: T) {
  return Object.keys(obj).map((objKey) => objKey as keyof T)
}

type PrimitiveType = string | symbol | number | boolean

function isPrimitive(value: any): value is PrimitiveType {
  return (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    typeof value === 'symbol'
  )
}

export interface RSMTableHeadData {
  isSortable: boolean
  shouldDisplay: boolean
  title: string
  key: any
  hover?: boolean
  setHover?: React.Dispatch<React.SetStateAction<boolean>>
  sortIcon?: any
  styles?: any
}

type CustomRenderers<T> = Partial<Record<keyof T, (it: T) => React.ReactNode>>

interface TableBodyProps<T> {
  itemList: T[]
  tableKey: keyof T
  header: RSMTableHeadData[] | undefined
  isAscending: boolean
  // eslint-disable-next-line react/require-default-props
  customRenderers?: CustomRenderers<T>
  isCheckable?: boolean
  allSelected?: boolean
  onSelectAllChange?: (allSelected: boolean) => void
  checkboxStyles?: any
  tableSortConfig?: any
  handleTableSort?: any
}

const defaultProps = {
  isCheckable: false,
  allSelected: false,
  onSelectAllChange: () => null,
  checkboxStyles: null,
  tableSortConfig: {},
  handleTableSort: () => null,
}

// eslint-disable-next-line react/function-component-definition
export default function RSMTableBody<T>({
  itemList,
  header,
  tableKey,
  customRenderers,
  isCheckable,
  isAscending,
  allSelected,
  onSelectAllChange,
  checkboxStyles,
  tableSortConfig,
  handleTableSort,
}: TableBodyProps<T>) {
  const { items, handleSort, sortIcon, ariaSort } = sortableData(
    itemList,
    tableKey,
    isAscending,
    useState,
    useMemo,
    handleTableSort,
    tableSortConfig,
  )

  function renderRow(item: T, index: number) {
    return (
      <TableRow
        // table action menu breaking position because of dynamic key change
        key={index} // ideally - t.id ? t.id : uuidv4() - this would be a bigger change.
        style={
          index % 2
            ? { backgroundColor: 'white' }
            : { backgroundColor: '#F9F9F9' }
        }>
        {objectKeys(item).map((itemProperty, itemIndex: number) => {
          const customRenderer = customRenderers?.[itemProperty]
          if (customRenderer) {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment
                key={`${customRenderer(item) as string}${itemIndex}` as string}>
                {customRenderer(item)}
              </React.Fragment>
            )
          }
          return (
            <StyledTableCell
              key={
                `${
                  item[itemProperty] as unknown as string
                }${itemIndex}` as string
              }>
              {isPrimitive(item[itemProperty]) ? (
                <OverFlowToolTip>{item[itemProperty]}</OverFlowToolTip>
              ) : (
                ''
              )}
            </StyledTableCell>
          )
        })}
      </TableRow>
    )
  }

  return (
    <>
      <TableHead data-testid="thead">
        <StyledRow>
          {isCheckable && (
            <StyledTableCell
              align="left"
              sx={{ textOverflow: 'clip', width: '70px', ...checkboxStyles }}>
              <IconButton
                disableRipple
                onClick={() =>
                  onSelectAllChange ? onSelectAllChange(!allSelected) : null
                }>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="checkbox-select-all"
                      icon={<CheckboxOutlinedIcon />}
                      checkedIcon={<CheckboxIcon />}
                      checked={allSelected || false}
                      aria-label="select all"
                      aria-describedby="checkbox select all"
                    />
                  }
                  label=""
                  aria-label="select all"
                  htmlFor="checkbox-select-all"
                />
              </IconButton>
            </StyledTableCell>
          )}
          {header?.map(
            (item) =>
              item.shouldDisplay && (
                <CustomTableCell
                  styles={item.styles}
                  ariaSort={ariaSort(item.key)}
                  key={item.key}
                  onClick={() =>
                    item.isSortable ? handleSort(item.key) : null
                  }
                  onMouseEnter={() =>
                    item.setHover ? item.setHover(true) : null
                  }
                  onMouseOut={() =>
                    item.setHover ? item.setHover(false) : null
                  }>
                  <Button
                    disableRipple
                    sx={{
                      fontSize: '14px',
                      fontWeight: '700',
                      width: '100%',
                      justifyContent: 'flex-start',
                      padding: '6px 0px',
                    }}
                    onClick={() =>
                      item.isSortable ? handleSort(item.key) : null
                    }>
                    {item.title?.toString().toUpperCase()}&nbsp;
                    {sortIcon(item.key, item.hover)}
                  </Button>
                </CustomTableCell>
              ),
          )}
        </StyledRow>
      </TableHead>
      <TableBody data-testid="tbody">{items.map(renderRow)}</TableBody>
    </>
  )
}

RSMTableBody.defaultProps = defaultProps
