import React from 'react'
import { useTheme, Box, Theme, Card } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import OverFlowToolTip from '../../OverFlowToolTip/OverFlowToolTip'
import ReadingIcon from '../../icons/ReadingIcon'
import SecureLink from './SecureLink'

type OwnProps = {
  fullWidth?: boolean
}

export const useStyles = (theme: Theme, { fullWidth }: OwnProps) => ({
  root: {
    margin: '0.25rem',
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1rem',
    color: theme.palette.text.primary,
    width: fullWidth ? '100%' : '27rem',
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
    justifyContent: 'flex-start',
  },
  left: {
    flex: '1 1 100%',
  },
  category: {
    fontFamily: 'Prelo-Bold, sans-serif',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  title: {
    fontFamily: 'Prelo-Book,sans-serif',
    fontSize: theme.typography.pxToRem(18),
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
    marginBottom: '1rem',
  },
  pdfLink: {
    color: theme.palette.secondary.main,
    display: 'block',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: '400',
    textDecoration: 'underline',
    fontFamily: 'Prelo-Book, sans-serif',
  },
  contactLink: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: '500',
    textDecoration: 'underline',
    fontFamily: 'Prelo-Book, sans-serif',
  },
  arrowIcon: {
    marginTop: '.6rem',
    color: theme.palette.secondary.main,
    marginLeft: '.3rem',
    display: 'inline-block',
  },
  readingIcon: {
    display: 'block',
    [theme.breakpoints.down('tablet')]: {
      display: 'none',
    },
  },
  link: {
    marginTop: '1.5rem',
    display: 'block',
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
  },
})

const SupportCard = ({ fullWidth }: OwnProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles(theme, { fullWidth })

  return (
    <Card variant="outlined" sx={classes.root}>
      <Box sx={classes.left}>
        <Box component="h2" sx={classes.category}>
          <OverFlowToolTip testId="Hed_SupportCard_Name">
            {t('Projects.SupportCard.Name')}
          </OverFlowToolTip>
        </Box>
        <Box component="h3" sx={classes.title}>
          <OverFlowToolTip testId="Hed_SupportCard_Category">
            {t('Projects.SupportCard.Category')}
          </OverFlowToolTip>
        </Box>
        <Box>
          <SecureLink
            href="TeamDocs/RSM TeamDocs Security Summary.pdf"
            testId="Lnk_SupportCard_SecuritySummary">
            {t('Projects.SupportCard.SecuritySummaryLabel')}
          </SecureLink>
          <SecureLink
            href="TeamDocs/External User Login with O365 Account into TeamDocs.pdf"
            testId="Lnk_SupportCard_LoggingInWith365Label">
            {t('Projects.SupportCard.LoggingInWith365Label')}
          </SecureLink>
          <SecureLink
            href="TeamDocs/External User Login without an O365 Account into TeamDocs.pdf"
            testId="Lnk_SupportCard_LoggingInWithout365Url">
            {t('Projects.SupportCard.LoggingInWithout365Label')}
          </SecureLink>
        </Box>
        <Box sx={classes.link} component={Link} to="/Faq" color="primary">
          {t('Faq.ButtonText')}
        </Box>
      </Box>
      <Box sx={classes.readingIcon}>
        <ReadingIcon />
      </Box>
    </Card>
  )
}

SupportCard.defaultProps = {
  fullWidth: false,
}

export default SupportCard
